import React, { ChangeEvent, createRef, Fragment, useContext } from 'react';
import SkillContext from '../../../Context/Skill/SkillContext';
import { UploadStatus } from '../../../Context/Skill/SkillState';
import { MediaType } from '../../../Models/MediaViewModel';

import './UploaderFooter.scss';

type UploadFooterProps = {
    allowedExtensions: string[],
    mediaType: MediaType,
};

const UploaderFooter = ({allowedExtensions, mediaType}: UploadFooterProps) => {
    const { skill, currentSelection, hasBeenModified, uploading, openInfoEdit, openVersionComment,
        openUnsavedChanges, uploadFiles, deleteIllustration, deleteVideo } = useContext(SkillContext);

    const onFileSelectorChanged = (event: ChangeEvent) => {
        const fileSelector = event.currentTarget as HTMLInputElement;

        if (fileSelector.files && fileSelector.value?.length) {
            uploadFiles(Array.from(fileSelector.files), mediaType, allowedExtensions);
            fileSelector.value = '';
        }
    };

    const fileInputRef = createRef<HTMLInputElement>();

    const onClickBrowse = () => {
        fileInputRef.current?.click();
    };

    const onEditClicked = () => {
        currentSelection && openInfoEdit();
    };

    const onCancelClicked = () => {
        openUnsavedChanges('');
    };

    const onSaveClicked = () => {
        openVersionComment();
    };

    const onDeleteClicked = () => {
        if(currentSelection?.mediaType == MediaType.illustrations){
            deleteIllustration(currentSelection?.key);
        }
        
        if(currentSelection?.mediaType == MediaType.videos) {
            deleteVideo(currentSelection.key);
        }
    };

    return (
        <Fragment>
            {skill && (
                <div>
                    <div className="c-els-divider c-els-divider--1o2"></div>
                    <div className="o-els-flex-layout o-els-flex--space-between u-els-margin-left-2x u-els-margin-right-2x u-els-margin-top-1x u-els-margin-bottom-1x">
                        <div className="o-els-flex-layout__item--grow o-els-flex-layout__item--middle">
                            <div>Drag files to this form to upload them.</div>
                        </div>
                        <div className="o-els-flex-layout__item--right u-els-nowrap">
                            <button className="c-els-button" disabled={!currentSelection || !currentSelection.isCustom} onClick={onEditClicked}>Edit</button>
                            <button className="c-els-button u-els-margin-left-1o2" onClick={onClickBrowse}>Browse</button>
                            <button className="c-els-button u-els-margin-left-1o2" disabled={!currentSelection || !currentSelection.isCustom || currentSelection.uploadStatus === UploadStatus.inProgress}
                                onClick={onDeleteClicked}>Delete</button>
                            <button className="c-els-button u-els-margin-left-1o2" disabled={!hasBeenModified || uploading} onClick={onSaveClicked}>Save</button>
                            <button className="c-els-button u-els-margin-left-1o2" disabled={!hasBeenModified} onClick={onCancelClicked}>Cancel</button>
                        </div>
                        <input className='hidden-input' type='file' multiple={true} onChange={onFileSelectorChanged} ref={fileInputRef}
                            accept={allowedExtensions.toString()} data-testid='file_input' />
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default UploaderFooter;
