import {createContext} from 'react';
import { Illustration } from '../../Models/Illustration';
import { MediaType, MediaViewModel } from '../../Models/MediaViewModel';
import { Skill } from '../../Models/Skill';
import { ChecklistItem } from '../../Models/ChecklistItem';
import { SkillsContentInputModel } from '../../Models/InputModels/SkillsContentInputModel';
import { TestQuestion } from '../../Models/TestQuestion';
import { Video } from '../../Models/Video';
import { PreviewState } from './SkillState';
import { GeneralInfoInputModel } from '../../Models/InputModels/GeneralInfoInputModel';
import { Category } from '../../Models/Category';
import { Specialty } from '../../Models/Specialty';
import { Subtitle } from '../../Models/Subtitle';

export type SkillContextType = {
    skill: Skill,
    allSkillNames: string[],
    currentSelection?: MediaViewModel,
    currentChecklistItem?: ChecklistItem,
    hasBeenModified: boolean,
    mediaTypeModified?: MediaType,
    infoEditIsOpen: boolean,
    versionCommentIsOpen: boolean,
    unsavedChangesIsOpen: boolean,
    unsavedChangesRoute: string,
    loading: boolean,
    saving: boolean,
    uploading: boolean,
    versionComment?: string,
    previewState: PreviewState,
    getSkill: (id: any) => void,
    saveSkillMedia: (versionComment: string) => void,
    saveSkillGeneralInfo: (generalInfo: GeneralInfoInputModel, categories: Category[], specialties: Specialty[], keywords: string[], versionComment: string) => void,
    saveSkillContent: (contentInput: SkillsContentInputModel, contentType: number, versionComment: string) => void,
    saveSkillTest: (testQuestions: TestQuestion[], versionComment: string) => void,
    saveNotes: (notesInput: string, tabTitleInput: string, activeInput: boolean, versionComment: string) => void,
    uploadFiles: (files: File[], mediaType: MediaType, extensions: string[]) => void,
    editIllustration: (illustration: Illustration) => void,
    deleteIllustration: (key: string) => void,
    editVideo: (video: Video) => void,
    saveChecklist: (checklistItems: ChecklistItem[], comment: string) => void,
    deleteVideo: (key: string) => void,
    toggleActive: (mediaType: MediaType, key: string) => void,
    changeSelection: (newSelection: MediaViewModel) => void,
    clearSelection: () => void,
    openInfoEdit: () => void,
    openVersionComment: () => void,
    openUnsavedChanges: (route: string) => void,
    openVideoPreview: (previewPath: string, previewCaptionsPath?: string, previewSubtitles?: Subtitle[], previewId?: string, previewTitle?: string) => void,
    closeInfoEdit: () => void,
    closeVersionComment: () => void,
    closeUnsavedChanges: () => void,
    closeVideoPreview: () => void,
    moveItem: (key:string, itemType: MediaType, direction: number) => void,
    setHasBeenModified: (hasBeenModified: boolean) => void,
}

const SkillContext = createContext({} as SkillContextType);

export default SkillContext;
