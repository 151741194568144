import React from 'react';
import { Colors } from '../Colors';

const ExpandArrowIcon = () => {
    return (
        <svg className="o-els-icon-svg--middle" xmlns="http://www.w3.org/2000/svg" viewBox="-40 -60 184 256" height="28" width="28" fill={Colors.brandblue}><path d="M1 51l7-7 38 38 38-38 7 7-45 45z"/>
            <title>Expand</title>
        </svg>
    );
};

export default ExpandArrowIcon;