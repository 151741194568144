import {createContext} from 'react';
import { Category } from '../../Models/Category';
import { Specialty } from '../../Models/Specialty';
import { TagType } from './TagsState';

export type TagsContextType = {
    allCategories: Category[],
    allSpecialties: Specialty[],
    isLoadingTags: boolean,
    creatingTagType?: TagType,
    managingTagType?: TagType,
    latestCategory?: Category,
    latestSpecialty?: Specialty,
    latestKeyword?: string,
    latestKeywordList: string[],
    getAllTags: (newCategory?: string, newSpecialty?: string) => void,
    addCustomCategory: (categoryName: string) => Promise<boolean>,
    addCustomSpecialty: (specialtyName: string) => Promise<boolean>,
    deleteCustomCategories: (categories: string[], skillKeyId: string, isInCurrentSkill: boolean) => void,
    deleteCustomSpecialties: (specialties: string[], skillKeyId: string, isInCurrentSkill: boolean) => void,
    addKeyword: (keyword: string) => void,
    setLatestKeywordList: (keywords: string[]) => void,
    clearLatestCategory: () => void,
    clearLatestSpecialty: () => void,
    clearLatestKeyword: () => void,
    openCreateTag: (tagType: TagType) => void,
    closeCreateTag: () => void,
    openManageTags: (tagType: TagType) => void,
    closeManageTags: () => void,
}

const TagsContext = createContext({} as TagsContextType);

export default TagsContext;