export default class AlphabeticSortSerivce {

    public sort(a: string, b: string) {
        if (a.toLocaleLowerCase() < b.toLocaleLowerCase())
            return -1;
        if (a.toLocaleLowerCase() > b.toLocaleLowerCase())
            return 1;
        return 0;
    }

}