import React, { RefObject, useContext, useEffect, useState } from 'react';
import AlphabeticSortSerivce from '../../../Services/AlphabeticSortService';
import TagsContext from '../../../Context/Tags/TagsContext';
import { TagType } from '../../../Context/Tags/TagsState';
import TagCheckboxItem from '../TagCheckboxItem/TagCheckboxItem';
import TagPill from '../TagPill/TagPill';

// @ts-ignore
import { Icon } from '@els/els-react--icon';

import './KeywordsEditor.scss';

type KeywordsEditorProps = {
    keywordSearchRef: RefObject<HTMLDivElement>,
    keywordSearchPopoutRef: RefObject<HTMLDivElement>,
    keywordSearchHasFocus: boolean,
    currentSkillKeywords: string[],
    setCurrentSkillKeywords: (...props: any) => void,
    setKeywordSearchHasFocus: (...props: any) => void,
}

const KeywordsEditor = ({ keywordSearchRef, keywordSearchPopoutRef, keywordSearchHasFocus, currentSkillKeywords,
    setCurrentSkillKeywords, setKeywordSearchHasFocus }: KeywordsEditorProps) => {

    const _alphabeticSortService = new AlphabeticSortSerivce();

    const { latestKeyword, latestKeywordList, openCreateTag, clearLatestKeyword } = useContext(TagsContext);

    const [ keywordSearch, setKeywordSearch ] = useState('');
    const [ keywordSearchResults, setKeywordSearchResults ] = useState([] as string[]);

    useEffect(() => {
        let updatedResults = [] as string[];

        latestKeywordList?.forEach(keyword => {
            if (keyword.toLocaleLowerCase().startsWith(keywordSearch.toLocaleLowerCase()) || keywordSearch.length == 0) {
                updatedResults = [ ...updatedResults, keyword ];
            }
        });

        setKeywordSearchResults(updatedResults);
    }, [keywordSearch, keywordSearchHasFocus]);

    useEffect(() => {
        if (latestKeyword) {
            setCurrentSkillKeywords([ ...currentSkillKeywords, latestKeyword ].sort((a, b) => _alphabeticSortService.sort(a, b)));

            clearLatestKeyword();
        }
    }, [latestKeyword]);

    const keywordIsOnSkill = (keyword: string) => {
        return currentSkillKeywords.some(x => x == keyword);
    };

    const showKeywordResults = () => {
        return keywordSearchHasFocus;
    };

    const showNoKeywordResults = () => {
        return keywordSearchResults.length == 0;
    };

    const onClickCreateKeyword = () => {
        setKeywordSearchHasFocus(false);
        openCreateTag(TagType.keyword);
    };

    const onRemoveKeyword = (itemIndex: number) => {
        let updatedKeywords = [] as string[];

        currentSkillKeywords.forEach((keyword, arrayIndex) => {
            if (arrayIndex != itemIndex) {
                updatedKeywords = [ ...updatedKeywords, keyword ];
            }
        });

        setCurrentSkillKeywords(updatedKeywords);
    };

    const onClickKeyword = (keyword: string) => {
        const itemIndex = currentSkillKeywords.findIndex(x => x == keyword);

        if (itemIndex >= 0) {
            onRemoveKeyword(itemIndex);
        }
        else {
            setCurrentSkillKeywords([ ...currentSkillKeywords, keyword ].sort((a, b) => _alphabeticSortService.sort(a, b)));
        }
    };

    return (
        <div className="u-els-margin-top-2x">
            <span className="u-els-font-size-intro">Keywords</span>
            <div className="u-els-margin-top-1o2">
                <Icon
                    className="react-icon-fix"
                    sprite="ConfirmationSolidCircle"
                    id="CategorySpecialtyOkay"
                    isVisible={true}
                    color="positive"
                    textAlignment="bottom"
                    a11y={{ name: 'Okay', description: 'Okay to edit'}}>
                </Icon>
                <span className='u-els-margin-left-1o4'>Editing these will not effect Elsevier skill updates or CE credits.</span>
            </div>
            <div className="c-els-field keywords-input" ref={keywordSearchRef}>
                <label className="c-els-field__label">
                    <span className="c-els-field__wrap c-els-field__wrap--icon-right">
                        <span className="c-els-field__icon c-els-field__icon--right">
                            <Icon
                                sprite="Search"
                                id="Search"
                                isVisible={true}
                                a11y={{ name: 'Search', description: 'Search for keywords'}}>
                            </Icon>
                        </span>
                        <input type="text" id="search-keywords" className="c-els-field__input u-els-margin-top-1o2" autoComplete='off'
                            placeholder="Find or create a tag" name="search-keywords"
                            onFocus={() => setKeywordSearchHasFocus(true)}
                            onChange={e => setKeywordSearch(e.currentTarget.value)} />
                    </span>
                </label>
                {showKeywordResults() &&
                <div className="c-els-card u-els-display-flex o-els-flex-layout--column u-app-z-index--3 popout-menu" ref={keywordSearchPopoutRef}>
                    <div className="popout-scroll-container">
                        {!showNoKeywordResults() &&
                        <div className='c-els-card__content'>
                            {keywordSearchResults?.map((keyword, index) => (
                                <TagCheckboxItem index={index} listLength={keywordSearchResults.length} text={keyword}
                                    key={keyword} isChecked={keywordIsOnSkill(keyword)}
                                    onChange={() => onClickKeyword(keyword)}></TagCheckboxItem>
                            ))}
                        </div>
                        }
                        {showNoKeywordResults() &&
                        <div className='c-els-card__content'>
                            <div>No matching tags</div>
                        </div>
                        }
                    </div>
                    <div className="u-els-padding-left-1o2 u-els-padding-right-1o2 u-els-padding-bottom-3o4 u-els-padding-top-3o4
                        popout-button-container">
                        <button className="c-els-button c-els-button--x-small create-tag-button"
                            onClick={onClickCreateKeyword}>Create a tag</button>
                    </div>
                </div>
                }
            </div>
            <div className="o-els-flex-layout o-els-flex-layout--wrap o-els-flex-layout--left u-els-margin-top">
                {currentSkillKeywords?.map((keyword, index) => (
                    <TagPill key={`category-${keyword}`} name={keyword} isCustom={true}
                        onRemove={() => onRemoveKeyword(index)}></TagPill>
                ))}
            </div>
        </div>
    );
};

export default KeywordsEditor;