import React, { useContext } from 'react';
import AuthenticationContext from '../../../Context/Authentication/AuthenticationContext';

export const HomePage = () => {
    const { user, initiateLogout } = useContext(AuthenticationContext);

    return (
        <div>
            <div>Hi, my name is {user?.profile?.name ?? 'a mystery'} and I hail from {user?.profile?.tenant ?? 'somewhere'}</div>
            <div>
                {user && (<a href='#!' onClick={initiateLogout}>Logout</a>)}
            </div>
        </div>
    );
};
