import React, { Fragment } from 'react';
import { EditableSkillsContentType } from '../../../Context/Skill/SkillState';
import SkillContentEditor from '../../Layout/SkillContentEditor/SkillContentEditor';

const SkillSuppliesPage = () => {

    const contentName = 'Supplies';
    const placeholderText = 'Add supplies here';
    const contentType = EditableSkillsContentType.Supplies;
    
    return (
        <Fragment>
            <SkillContentEditor contentName={contentName} placeholderText={placeholderText} contentType={contentType}></SkillContentEditor>
        </Fragment>
    );
};

export default SkillSuppliesPage;