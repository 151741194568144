import React, { Fragment, useEffect, useState } from 'react';
import { TestAnswer } from '../../../Models/TestAnswer';
import { QuestionType, TestQuestion } from '../../../Models/TestQuestion';
import TextFieldInputError from '../TextFieldInputError/TextFieldInputError';
import { RegexPatterns } from '../../../Constants/Constants';

// @ts-ignore
import { Icon } from '@els/els-react--icon';

import './TestQuestionForm.scss';

export interface TestQuestionFormDirtyState {
    questionTextHasBeenTouched: boolean,
    answerAHasBeenTouched: boolean,
    answerBHasBeenTouched: boolean,
    answerCHasBeenTouched: boolean,
    answerDHasBeenTouched: boolean,
}

type TestQuestionFormProps = {
    innerElementId: string,
    question: TestQuestion,
    dirtyState: TestQuestionFormDirtyState,
    numberOfQuestions?: number,
    autoFocus?: boolean,
    setDirtyState: (state: TestQuestionFormDirtyState) => void,
    setQuestion: (question: TestQuestion) => void,
    setIsError: (isError: boolean) => void,
}

const TestQuestionForm = ({ innerElementId, question, dirtyState, autoFocus, numberOfQuestions, 
    setDirtyState, setQuestion, setIsError }: TestQuestionFormProps) => {

    const [ currentNumberString, setCurrentNumberString ] = useState(question.number.toString());

    const [ isQuestionLengthError, setIsQuestionLengthError ] = useState(false);
    const [ isQuestionZeroError, setIsQuestionZeroError ] = useState(false);
    const [ isQuestionRegexError, setIsQuestionRegexError ] = useState(false);
    const [ isAnswerALengthError, setIsAnswerALengthError ] = useState(false);
    const [ isAnswerAZeroError, setIsAnswerAZeroError ] = useState(false);
    const [ isAnswerARegexError, setIsAnswerARegexError ] = useState(false);
    const [ isAnswerBLengthError, setIsAnswerBLengthError ] = useState(false);
    const [ isAnswerBZeroError, setIsAnswerBZeroError ] = useState(false);
    const [ isAnswerBRegexError, setIsAnswerBRegexError ] = useState(false);
    const [ isAnswerCLengthError, setIsAnswerCLengthError ] = useState(false);
    const [ isAnswerCZeroError, setIsAnswerCZeroError ] = useState(false);
    const [ isAnswerCRegexError, setIsAnswerCRegexError ] = useState(false);
    const [ isAnswerDLengthError, setIsAnswerDLengthError ] = useState(false);
    const [ isAnswerDZeroError, setIsAnswerDZeroError ] = useState(false);
    const [ isAnswerDRegexError, setIsAnswerDRegexError ] = useState(false);
    const [ isRationaleLengthError, setIsRationaleLengthError ] = useState(false);
    const [ isRationaleRegexError, setIsRationaleRegexError ] = useState(false);

    useEffect(() => {
        setIsError(
            isQuestionZeroError || isQuestionLengthError || isQuestionRegexError ||
            isAnswerAZeroError || isAnswerALengthError || isAnswerARegexError ||
            isAnswerBZeroError || isAnswerBLengthError || isAnswerBRegexError ||
            (question.questionType == QuestionType.multipleChoice && isAnswerCZeroError) || isAnswerCLengthError || isAnswerCRegexError ||
            (question.questionType == QuestionType.multipleChoice && isAnswerDZeroError) || isAnswerDLengthError || isAnswerDRegexError ||
            isRationaleLengthError || isRationaleRegexError
        );
    }, [isQuestionZeroError, isQuestionLengthError, isQuestionRegexError, isAnswerAZeroError, isAnswerALengthError, isAnswerARegexError,
        isAnswerBZeroError, isAnswerBLengthError, isAnswerBRegexError, isAnswerCZeroError, isAnswerCLengthError, isAnswerCRegexError,
        isAnswerDZeroError, isAnswerDLengthError, isAnswerDRegexError, isRationaleLengthError, isRationaleRegexError]);

    useEffect(() => {
        if (numberOfQuestions != null) {
            const newNumber = (numberOfQuestions + 1).toString();

            setCurrentNumberString((numberOfQuestions + 1).toString());
            onChangeQuestionNumber(newNumber);
        }
    }, [numberOfQuestions]);

    const questionFieldLengthLimit = 2500;

    const onChangeQuestionType = (questionType: string) => {
        switch (questionType) {
        case QuestionType.trueOrFalse:
            setQuestion({...question, questionType: QuestionType.trueOrFalse, answerOptions: [
                { id: 0, label: 'A', text: 'True', rationale: question.answerOptions[0].rationale } as TestAnswer,
                { id: 1, label: 'B', text: 'False', rationale: question.answerOptions[1].rationale } as TestAnswer,
            ],
            correctAnswer: (question.correctAnswer == 'C' || question.correctAnswer == 'D') ? 'A' : question.correctAnswer,
            } as TestQuestion);
            setDirtyState({ ...dirtyState, answerAHasBeenTouched: true, answerBHasBeenTouched: true, answerCHasBeenTouched: true, answerDHasBeenTouched: true, });
            setIsAnswerCLengthError(false);
            setIsAnswerCZeroError(false);
            setIsAnswerCRegexError(false);
            setIsAnswerDLengthError(false);
            setIsAnswerDZeroError(false);
            setIsAnswerDRegexError(false);
            break;
        case QuestionType.yesOrNo:
            setQuestion({...question, questionType: QuestionType.yesOrNo, answerOptions: [
                { id: 0, label: 'A', text: 'Yes', rationale: question.answerOptions[0].rationale } as TestAnswer,
                { id: 1, label: 'B', text: 'No', rationale: question.answerOptions[1].rationale } as TestAnswer,
            ],
            correctAnswer: (question.correctAnswer == 'C' || question.correctAnswer == 'D') ? 'A' : question.correctAnswer,
            } as TestQuestion);
            setDirtyState({ ...dirtyState, answerAHasBeenTouched: true, answerBHasBeenTouched: true, answerCHasBeenTouched: true, answerDHasBeenTouched: true, });
            setIsAnswerCLengthError(false);
            setIsAnswerCZeroError(false);
            setIsAnswerCRegexError(false);
            setIsAnswerDLengthError(false);
            setIsAnswerDZeroError(false);
            setIsAnswerDRegexError(false);
            break;
        default:
            setQuestion({...question, questionType: QuestionType.multipleChoice, answerOptions: [
                { id: 0, label: 'A', text: '', rationale: question.answerOptions[0].rationale } as TestAnswer,
                { id: 1, label: 'B', text: '', rationale: question.answerOptions[1].rationale } as TestAnswer,
                { id: 2, label: 'C', text: '', rationale: question.answerOptions[0].rationale } as TestAnswer,
                { id: 3, label: 'D', text: '', rationale: question.answerOptions[0].rationale } as TestAnswer,
            ]} as TestQuestion);
            setDirtyState({ ...dirtyState, answerAHasBeenTouched: false, answerBHasBeenTouched: false, answerCHasBeenTouched: false, answerDHasBeenTouched: false, });
        }
    };

    const onChangeQuestionNumber = (questionNumber: string) => {
        setCurrentNumberString(questionNumber);

        setQuestion({
            ...question,
            number: (questionNumber.length > 0) ? Number.parseInt(questionNumber) : 1,
        } as TestQuestion);
    };

    const onChangeQuestionText = (text: string) => {
        setQuestion({...question, text} as TestQuestion);
    };

    const onChangeAnswer = (id: number, text: string) => {
        const newAnswerOptions = question.answerOptions.map(x => { return (x.id == id) ? { ...x, text} as TestAnswer : x; });
        setQuestion({...question, answerOptions: newAnswerOptions} as TestQuestion);
    };

    const onChangeCorrectAnswer = (correctAnswer: string) => {
        setQuestion({...question, correctAnswer} as TestQuestion);
    };

    const onChangeRationale = (rationale: string) => {
        const newAnswerOptions = question.answerOptions.map(x => { return { ...x, rationale } as TestAnswer; });
        setQuestion({...question, answerOptions: newAnswerOptions} as TestQuestion);
    };

    const setQuestionTextHasBeenTouched = (newValue: boolean) => {
        setDirtyState({ ...dirtyState, questionTextHasBeenTouched: newValue, });
    };

    const setAnswerAHasBeenTouched = (newValue: boolean) => {
        setDirtyState({ ...dirtyState, answerAHasBeenTouched: newValue, });
    };

    const setAnswerBHasBeenTouched = (newValue: boolean) => {
        setDirtyState({ ...dirtyState, answerBHasBeenTouched: newValue, });
    };

    const setAnswerCHasBeenTouched = (newValue: boolean) => {
        setDirtyState({ ...dirtyState, answerCHasBeenTouched: newValue, });
    };

    const setAnswerDHasBeenTouched = (newValue: boolean) => {
        setDirtyState({ ...dirtyState, answerDHasBeenTouched: newValue, });
    };

    const getCorrectAnswerATitle = () => {
        switch (question.questionType) {
        case QuestionType.trueOrFalse:
            return 'True';
        case QuestionType.yesOrNo:
            return 'Yes';
        default:
            return 'A';
        }
    };

    const getCorrectAnswerBTitle = () => {
        switch (question.questionType) {
        case QuestionType.trueOrFalse:
            return 'False';
        case QuestionType.yesOrNo:
            return 'No';
        default:
            return 'B';
        }
    };

    const isMultipleChoice = () => {
        return question.questionType == QuestionType.multipleChoice;
    };

    return (
        <Fragment>
            <label className="c-els-field__label">
                <span className="c-els-field__label-text u-els-font-size-body-large">
                    <span className="c-els-field__message--warning">*</span>Number order:
                </span>
                <input className="c-els-field__input order-number-field" type='number' min={1} value={currentNumberString}
                    onChange={e => onChangeQuestionNumber(e.currentTarget.value)} data-testid={`${innerElementId}-question-number-field`} autoFocus={autoFocus}></input>
            </label>
            <div className="c-els-field u-els-margin-top-2x question-type-field">
                <label className="c-els-field__label">
                    <span className="c-els-field__label-text u-els-font-size-body-large">
                        <span className="c-els-field__message--warning">*</span>Type:
                    </span>
                    <span className="c-els-field__wrap c-els-field__wrap--icon-right">
                        <span className="c-els-field__icon c-els-field__icon--right">
                            <Icon
                                sprite="ChevronDown"
                                id="Select"
                                isVisible={true}
                                a11y={{ name: 'Search', description: 'Select category or specialty'}}>
                            </Icon>
                        </span>
                        <select id="sample-id" className="c-els-field__input" value={question.questionType}
                            onChange={e => onChangeQuestionType(e.currentTarget.value)} data-testid={`${innerElementId}-question-type-field`}>
                            <option value={QuestionType.multipleChoice}>Multiple choice</option>
                            <option value={QuestionType.trueOrFalse}>True or false</option>
                            <option value={QuestionType.yesOrNo}>Yes or no</option>
                        </select>
                    </span>
                </label>
            </div>
            <label className="c-els-field__label u-els-margin-top-2x">
                <span className="c-els-field__label-text u-els-font-size-body-large">
                    <span className="c-els-field__message--warning">*</span>Question:
                </span>
                <input className="c-els-field__input" data-testid={`${innerElementId}-question-text-field`} value={question.text}
                    onChange={e => { onChangeQuestionText(e.currentTarget.value); setQuestionTextHasBeenTouched(true); }}></input>
                <TextFieldInputError text={question.text} hasBeenTouched={dirtyState.questionTextHasBeenTouched}
                    isLengthError={isQuestionLengthError} setIsLengthError={setIsQuestionLengthError}
                    isZeroLengthError={isQuestionZeroError} setIsTextZeroError={setIsQuestionZeroError}
                    isRegexError={isQuestionRegexError} setIsRegexError={setIsQuestionRegexError} characterRegex={RegexPatterns.testQuestionRegex}
                    lengthLimit={questionFieldLengthLimit} fieldName='question text'></TextFieldInputError>
            </label>
            <label className="c-els-field__label u-els-margin-top">
                <span className="c-els-field__label-text u-els-font-size-body-large">
                    <span className="c-els-field__message--warning">*</span>Answer A:
                </span>
                <input className="c-els-field__input" disabled={!isMultipleChoice()} value={question.answerOptions[0].text} data-testid={`${innerElementId}-answer-a-field`}
                    onChange={e => { onChangeAnswer(0, e.currentTarget.value); setAnswerAHasBeenTouched(true); }}></input>
                <TextFieldInputError text={question.answerOptions[0].text} hasBeenTouched={dirtyState.answerAHasBeenTouched}
                    isLengthError={isAnswerALengthError} setIsLengthError={setIsAnswerALengthError}
                    isZeroLengthError={isAnswerAZeroError} setIsTextZeroError={setIsAnswerAZeroError}
                    isRegexError={isAnswerARegexError} setIsRegexError={setIsAnswerARegexError} characterRegex={RegexPatterns.testAnswerRegex}
                    lengthLimit={questionFieldLengthLimit} fieldName='answer text'></TextFieldInputError>
            </label>
            <label className="c-els-field__label u-els-margin-top">
                <span className="c-els-field__label-text u-els-font-size-body-large">
                    <span className="c-els-field__message--warning">*</span>Answer B:
                </span>
                <input className="c-els-field__input" disabled={!isMultipleChoice()} value={question.answerOptions[1].text} data-testid={`${innerElementId}-answer-b-field`}
                    onChange={e => { onChangeAnswer(1, e.currentTarget.value); setAnswerBHasBeenTouched(true); }}></input>
                <TextFieldInputError text={question.answerOptions[1].text} hasBeenTouched={dirtyState.answerBHasBeenTouched}
                    isLengthError={isAnswerBLengthError} setIsLengthError={setIsAnswerBLengthError}
                    isZeroLengthError={isAnswerBZeroError} setIsTextZeroError={setIsAnswerBZeroError}
                    isRegexError={isAnswerBRegexError} setIsRegexError={setIsAnswerBRegexError} characterRegex={RegexPatterns.testAnswerRegex}
                    lengthLimit={questionFieldLengthLimit} fieldName='answer text'></TextFieldInputError>
            </label>
            {isMultipleChoice() &&
            <Fragment>
                <label className="c-els-field__label u-els-margin-top">
                    <span className="c-els-field__label-text u-els-font-size-body-large">
                        <span className="c-els-field__message--warning">*</span>Answer C:
                    </span>
                    <input className="c-els-field__input" value={question.answerOptions[2].text} data-testid={`${innerElementId}-answer-c-field`}
                        onChange={e => { onChangeAnswer(2, e.currentTarget.value); setAnswerCHasBeenTouched(true); }}></input>
                    <TextFieldInputError text={question.answerOptions[2].text} hasBeenTouched={dirtyState.answerCHasBeenTouched}
                        isLengthError={isAnswerCLengthError} setIsLengthError={setIsAnswerCLengthError}
                        isZeroLengthError={isAnswerCZeroError} setIsTextZeroError={setIsAnswerCZeroError}
                        isRegexError={isAnswerCRegexError} setIsRegexError={setIsAnswerCRegexError} characterRegex={RegexPatterns.testAnswerRegex}
                        lengthLimit={questionFieldLengthLimit} fieldName='answer text'></TextFieldInputError>
                </label>
                <label className="c-els-field__label u-els-margin-top">
                    <span className="c-els-field__label-text u-els-font-size-body-large">
                        <span className="c-els-field__message--warning">*</span>Answer D:
                    </span>
                    <input className="c-els-field__input" value={question.answerOptions[3].text} data-testid={`${innerElementId}-answer-d-field`}
                        onChange={e => { onChangeAnswer(3, e.currentTarget.value); setAnswerDHasBeenTouched(true); }}></input>
                    <TextFieldInputError text={question.answerOptions[3].text} hasBeenTouched={dirtyState.answerDHasBeenTouched}
                        isLengthError={isAnswerDLengthError} setIsLengthError={setIsAnswerDLengthError}
                        isZeroLengthError={isAnswerDZeroError} setIsTextZeroError={setIsAnswerDZeroError}
                        isRegexError={isAnswerDRegexError} setIsRegexError={setIsAnswerDRegexError} characterRegex={RegexPatterns.testAnswerRegex}
                        lengthLimit={questionFieldLengthLimit} fieldName='answer text'></TextFieldInputError>
                </label>
            </Fragment>
            }
            <div className="u-els-margin-top">
                <span className="c-els-field__label-text u-els-font-size-body-large">
                    <span className="c-els-field__message--warning">*</span>Correct answer:
                </span>
                <fieldset className="u-els-display-flex u-els-margin-top" id={`${innerElementId}-correct-answer`}>
                    <div className="c-els-field c-els-field--radio">
                        <label className="c-els-field__label u-els-margin-right-2x">
                            <input type="radio" id="correct-answer-a" className="c-els-field__input" name={`${innerElementId}-correct-answer`} data-testid={`${innerElementId}-correct-a-field`}
                                checked={question.correctAnswer == 'A'} onChange={() => onChangeCorrectAnswer('A')} />
                            <span className="c-els-field__label-text">
                                <span className="c-els-field__switch"></span>{getCorrectAnswerATitle()}
                            </span>
                        </label>
                    </div>
                    <div className="c-els-field c-els-field--radio">
                        <label className="c-els-field__label u-els-margin-right-2x">
                            <input type="radio" id="correct-answer-b" className="c-els-field__input" name={`${innerElementId}-correct-answer`} data-testid={`${innerElementId}-correct-b-field`}
                                checked={question.correctAnswer == 'B'} onChange={() => onChangeCorrectAnswer('B')} />
                            <span className="c-els-field__label-text">
                                <span className="c-els-field__switch"></span>{getCorrectAnswerBTitle()}
                            </span>
                        </label>
                    </div>
                    {isMultipleChoice() &&
                    <Fragment>
                        <div className="c-els-field c-els-field--radio">
                            <label className="c-els-field__label u-els-margin-right-2x">
                                <input type="radio" id="correct-answer-c" className="c-els-field__input" name={`${innerElementId}-correct-answer`} data-testid={`${innerElementId}-correct-c-field`}
                                    checked={question.correctAnswer == 'C'} onChange={() => onChangeCorrectAnswer('C')} />
                                <span className="c-els-field__label-text">
                                    <span className="c-els-field__switch"></span>C
                                </span>
                            </label>
                        </div>
                        <div className="c-els-field c-els-field--radio">
                            <label className="c-els-field__label">
                                <input type="radio" id="correct-answer-d" className="c-els-field__input" name={`${innerElementId}-correct-answer`} data-testid={`${innerElementId}-correct-d-field`}
                                    checked={question.correctAnswer == 'D'} onChange={() => onChangeCorrectAnswer('D')} />
                                <span className="c-els-field__label-text">
                                    <span className="c-els-field__switch"></span>D
                                </span>
                            </label>
                        </div>
                    </Fragment>
                    }
                </fieldset>
            </div>
            <label className="c-els-field__label u-els-margin-top-2x">
                <span className="c-els-field__label-text u-els-font-size-body-large">Rationale:</span>
                <input className="c-els-field__input" value={question.answerOptions[0].rationale} data-testid={`${innerElementId}-rationale-field`}
                    onChange={e => onChangeRationale(e.currentTarget.value)}></input>
                <TextFieldInputError text={question.answerOptions[0].rationale} hasBeenTouched={false} fieldName={'rationale'}
                    isLengthError={isRationaleLengthError} setIsLengthError={setIsRationaleLengthError}
                    isRegexError={isRationaleRegexError} setIsRegexError={setIsRationaleRegexError} characterRegex={RegexPatterns.testRationaleRegex}
                    lengthLimit={questionFieldLengthLimit}></TextFieldInputError>
            </label>
        </Fragment>
    );
};

export default TestQuestionForm;