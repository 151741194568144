export enum Colors {
    backgroundgray = '#F2F2F2',
    bordergainsboro = '#DCDCDC',
    brandblue = '#007398',
    brandorange = '#e9711c',
    hmdsn3 = '#cecece',
    hmdsn4 = '#bababa',
    hmdsn7 = '#737373',
    hmdsn85 = '#616161',
    hmdsn9 = '#505050',
    hmdsn10 = '#494949',
    hmdsconfirm = '#29A61B',
    hmdswarn = '#C83727',
    hmdsinfo = '#009ECE',
}