import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import SkillContext from '../../../Context/Skill/SkillContext';
import CKEditorComponent from '../../Layout/CKEditorComponent/CKEditorComponent';
import VersionCommentField from '../../Layout/VersionCommentField/VersionCommentField';
import TextFieldInputError from '../../Layout/TextFieldInputError/TextFieldInputError';
import { RegexPatterns } from '../../../Constants/Constants';

// @ts-ignore
import { Card } from '@els/els-react--card';
// @ts-ignore
import { Icon } from '@els/els-react--icon';

interface NotesTabMessage {
    notesTabName: string,
}

const SkillNotesPage = () => {
    const { skill, saveNotes, openUnsavedChanges } = useContext(SkillContext);

    const [ notesText, setNotesText ] = useState(skill.notes?.description ?? '');
    const [ tabTitleText, setTabTitleText ] = useState(skill.notes?.sliceTitle ?? 'Notes');
    const [ notesActive, setNotesActive ] = useState(skill.notes?.status ?? false);
    const [ commentText, setCommentText ] = useState('');

    const [ isCommentError, setIsCommentError ] = useState(false);
    const [ isTabTitleZeroError, setIsTabTitleZeroError ] = useState(false);
    const [ isTabTitleLengthError, setIsTabTitleLengthError ] = useState(false);
    const [ isTabTitleRegexError, setIsTabTitleRegexError ] = useState(false);

    const [ hasBeenModified, setHasBeenModified ] = useState(false);

    const tabTitleRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        updateHasBeenModified();
    }, [notesText, tabTitleText, notesActive, commentText]);

    useEffect(() => {
        if (tabTitleRef.current && tabTitleText != 'Notes') {
            tabTitleRef.current.value = tabTitleText;
        }
    }, [tabTitleRef]);

    useEffect(() => {
        // This message is for use in the NursingSkills iFrame and can be deleted when NursingSkills is no longer in use
        const messageChannel = new MessageChannel();
        window.parent.postMessage({ notesTabName: skill.notes?.sliceTitle ?? 'Notes' } as NotesTabMessage, '*', [messageChannel.port2]);
    }, [skill]);

    const tabTitleCharacterLimit = 15;
    const commentCharacterLimit = 512;

    const onChangeNotesText = (newNotesText: string) => {
        setNotesText(newNotesText);
    };

    const onChangeTabActive = (newValue: boolean) => {
        setNotesActive(newValue);
    };

    const onChangeTabTitleRadio = (newValue: boolean) => {
        setTabTitleText(newValue ? 'Notes' : tabTitleRef.current ? tabTitleRef.current.value : '');
    };

    const onChangeTabTitleText = (newTabTitle: string) => {
        setTabTitleText(newTabTitle);
    };

    const onSaveClicked = () => {   
        saveNotes(notesText, tabTitleText, notesActive, commentText);
    };

    const onCancelClicked = () => {
        openUnsavedChanges('');
    };

    const isSaveDisabled = () => {
        return isCommentError || isTabTitleLengthError || isTabTitleZeroError || isTabTitleRegexError || !hasBeenModified;
    };

    const isCancelDisabled = () => {
        return !hasBeenModified;
    };

    const updateHasBeenModified = () => {
        setHasBeenModified(notesText !== skill.notes?.description || notesActive != skill.notes?.status
                || tabTitleText !== skill.notes?.sliceTitle || commentText.length != 0);
    };

    return (
        <Fragment>
            <div className="u-els-margin-top-1x u-els-padding-left-2x u-els-padding-right-2x scroll-container o-els-flex-layout__item--grow">
                <span className="u-els-font-size-intro">Notes</span>
                <Card borderWeight="none" className="alert-box u-els-margin-top-1x1o2 u-els-margin-bottom-2x">
                    <Icon
                        sprite="ConfirmationSolidCircle"
                        textAlignment="offset"
                        id="NotesAlert"
                        isVisible={true}
                        color="positive"
                        a11y={{ name: 'Alert',description: 'Notes Alert'}}
                    ></Icon>
                    <span className="u-els-margin-left">We recommend making skill edits in the notes area. It will not affect Elsevier skill updates or CE credits.</span>
                </Card>
                <div>
                    <label className="c-els-field__label u-els-margin-bottom-2x">
                        <span className="c-els-field__label-text skill-content-editor__title u-els-font-size-body-large">Tab title:</span>
                        <fieldset className="u-els-display-flex o-els-flex-layout--middle u-els-display-flex@mobile" id="tab-title">
                            <div className="c-els-field c-els-field--radio">
                                <label className="c-els-field__label u-els-margin-bottom-1o4@mobile u-els-margin-right-2x">
                                    <input type="radio" id="active-yes" className="c-els-field__input" name="tab-title" onChange={() => onChangeTabTitleRadio(true)}
                                        checked={tabTitleText == 'Notes'} data-testid='title_notes' />
                                    <span className="c-els-field__label-text">
                                        <span className="c-els-field__switch"></span>
                                        <span>Notes</span>
                                    </span>
                                </label>
                            </div>
                            <div className="u-els-display-flex o-els-flex-layout--middle u-els-margin-bottom-1o4@mobile">
                                <div className="c-els-field c-els-field--radio">
                                    <label className="c-els-field__label">
                                        <input type="radio" id="active-no" className="c-els-field__input" name="tab-title" onChange={() => onChangeTabTitleRadio(false)}
                                            checked={tabTitleText != 'Notes'} data-testid='title_other' />
                                        <div className="c-els-field__label-text u-els-display-flex o-els-flex-layout--wrap">
                                            <span className="c-els-field__switch"></span>
                                            <span className="u-els-margin-right o-els-flex-layout--wrap@mobile">Other</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                        <div className='u-els-margin-top'>
                            <label className="c-els-field__label">
                                <input type="text" id="other-input" ref={tabTitleRef} className="c-els-field__input" name="other-input"
                                    autoComplete='off' onChange={(event) => onChangeTabTitleText(event.currentTarget.value)} data-testid='other_input' />
                                <TextFieldInputError text={tabTitleText} hasBeenTouched={true} 
                                    isZeroLengthError={isTabTitleZeroError} setIsTextZeroError={setIsTabTitleZeroError}
                                    isLengthError={isTabTitleLengthError} setIsLengthError={setIsTabTitleLengthError}
                                    isRegexError={isTabTitleRegexError} setIsRegexError={setIsTabTitleRegexError} 
                                    characterRegex={RegexPatterns.notesRegex}
                                    lengthLimit={tabTitleCharacterLimit} fieldName='notes tab title'></TextFieldInputError>
                            </label>
                        </div>
                    </label>
                </div>
                <label className="c-els-field__label u-els-margin-bottom-2x">
                    <span className="c-els-field__label-text skill-content-editor__title u-els-font-size-body-large">Active:</span>
                    <fieldset className="u-els-display-flex" id="active">
                        <div className="c-els-field c-els-field--radio">
                            <label className="c-els-field__label u-els-margin-right-2x">
                                <input type="radio" id="active-yes" className="c-els-field__input" name="active" onChange={() => onChangeTabActive(true)}
                                    checked={notesActive} data-testid='active_yes' />
                                <span className="c-els-field__label-text">
                                    <span className="c-els-field__switch"></span>
                                    <span>Yes</span>
                                </span>
                            </label>
                        </div>
                        <div className="c-els-field c-els-field--radio">
                            <label className="c-els-field__label">
                                <input type="radio" id="active-no" className="c-els-field__input" name="active" onChange={() => onChangeTabActive(false)}
                                    checked={!notesActive} data-testid='active_no' />
                                <span className="c-els-field__label-text">
                                    <span className="c-els-field__switch"></span>
                                    <span>No</span>
                                </span>
                            </label>
                        </div>
                    </fieldset>
                </label>
                <label className="c-els-field__label u-els-margin-bottom-2x">
                    <span className="c-els-field__label-text skill-content-editor__title u-els-font-size-body-large">Notes:</span>
                    <CKEditorComponent initData={notesText} editorplaceholder={'Add notes here'} onChange={onChangeNotesText}></CKEditorComponent>
                </label>
                <VersionCommentField commentText={commentText} setCommentText={setCommentText} setIsCommentError={setIsCommentError}
                    commentCharacterLimit={commentCharacterLimit}></VersionCommentField>
                <div className="u-els-margin-top-1x1o2 u-els-margin-bottom">
                    <button className="c-els-button" onClick={onSaveClicked} disabled={isSaveDisabled()}>Save</button>
                    <button className="c-els-button c-els-button--secondary u-els-margin-left" onClick={onCancelClicked} disabled={isCancelDisabled()}>Cancel</button>
                </div>
            </div>
        </Fragment>
    );
};

export default SkillNotesPage;
