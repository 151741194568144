import { createAction, createReducer } from '@reduxjs/toolkit';
import { Category } from '../../Models/Category';
import { Specialty } from '../../Models/Specialty';
import { TagType } from './TagsState';

export enum ActionType {
    getAllCategories = 'GET_ALL_CATEGORIES',
    getAllSpecialties = 'GET_ALL_SPECIALTIES',
    setIsLoadingTags = 'SET_IS_LOADING_TAGS',
    setCreatingTagType = 'SET_CREATING_TAG_TYPE',
    clearCreatingTagType = 'CLEAR_CREATING_TAG_TYPE',
    setManagingTagType = 'SET_MANAGING_TAG_TYPE',
    clearManagingTagType = 'CLEAR_MANAGING_TAG_TYPE',
    setLatestCategory = 'SET_LATEST_CATEGORY',
    setLatestSpecialty = 'SET_LATEST_SPECIALTY',
    addNewKeyword = 'ADD_NEW_KEYWORD',
    setLatestKeywordList = 'SET_LATEST_KEYWORD_LIST',
    clearLatestCategory = 'CLEAR_LATEST_CATEGORY',
    clearLatestSpecialty = 'CLEAR_LATEST_SPECIALTY',
    clearLatestKeyword = 'CLEAR_LATEST_KEYWORD',
}

export const getAllCategoriesAction = createAction<Category[]>(ActionType.getAllCategories);
export const getAllSpecialtiesAction = createAction<Specialty[]>(ActionType.getAllSpecialties);
export const setIsLoadingTagsAction = createAction<boolean>(ActionType.setIsLoadingTags);
export const setCreatingTagTypeAction = createAction<TagType>(ActionType.setCreatingTagType);
export const clearCreatingTagTypeAction = createAction(ActionType.clearCreatingTagType);
export const setManagingTagTypeAction = createAction<TagType>(ActionType.setManagingTagType);
export const clearManagingTagTypeAction = createAction(ActionType.clearManagingTagType);
export const setLatestCategoryAction = createAction<Category>(ActionType.setLatestCategory);
export const setLatestSpecialtyAction = createAction<Specialty>(ActionType.setLatestSpecialty);
export const addNewKeywordAction = createAction<string>(ActionType.addNewKeyword);
export const setLatestKeywordListAction = createAction<string[]>(ActionType.setLatestKeywordList);
export const clearLatestCategoryAction = createAction(ActionType.clearLatestCategory);
export const clearLatestSpecialtyAction = createAction(ActionType.clearLatestSpecialty);
export const clearLatestKeywordAction = createAction(ActionType.clearLatestKeyword);

export type TagsReducerState = {
    allCategories: Category[],
    allSpecialties: Specialty[],
    isLoadingTags: boolean,
    creatingTagType?: TagType,
    latestCategory?: Category,
    latestSpecialty?: Specialty,
    latestKeyword?: string,
    latestKeywordList: string[],
}

const TagsReducer = createReducer({} as TagsReducerState, (builder) => {
    builder
        .addCase(getAllCategoriesAction, (state, {payload}) => {
            return { ...state, allCategories: payload };
        })
        .addCase(getAllSpecialtiesAction, (state, {payload}) => {
            return { ...state, allSpecialties: payload };
        })
        .addCase(setIsLoadingTagsAction, (state, {payload}) => {
            return { ...state, isLoadingTags: payload };
        })
        .addCase(setCreatingTagTypeAction, (state, {payload}) => {
            return { ...state, creatingTagType: payload };
        })
        .addCase(clearCreatingTagTypeAction, (state) => {
            return { ...state, creatingTagType: undefined };
        })
        .addCase(setManagingTagTypeAction, (state, {payload}) => {
            return { ...state, managingTagType: payload };
        })
        .addCase(clearManagingTagTypeAction, (state) => {
            return { ...state, managingTagType: undefined };
        })
        .addCase(setLatestCategoryAction, (state, {payload}) => {
            return { ...state, latestCategory: payload };
        })
        .addCase(setLatestSpecialtyAction, (state, {payload}) => {
            return { ...state, latestSpecialty: payload };
        })
        .addCase(addNewKeywordAction, (state, {payload}) => {
            const newKeywordsState = [ ...state.latestKeywordList, payload ].sort();
            return { ...state, latestKeyword: payload, latestKeywordList: newKeywordsState };
        })
        .addCase(setLatestKeywordListAction, (state, {payload}) => {
            return { ...state, latestKeywordList: payload };
        })
        .addCase(clearLatestCategoryAction, (state) => {
            return { ...state, latestCategory: undefined };
        })
        .addCase(clearLatestSpecialtyAction, (state) => {
            return { ...state, latestSpecialty: undefined };
        })
        .addCase(clearLatestKeywordAction, (state) => {
            return { ...state, latestKeyword: undefined };
        });
});

export default TagsReducer;