import React, { DragEvent, Fragment, useContext, useEffect } from 'react';
import SkillContext from '../../../Context/Skill/SkillContext';
import MediaList from '../../Layout/MediaList/MediaList';
import { MediaType, MediaViewModel } from '../../../Models/MediaViewModel';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import UploaderFooter from '../../Layout/UploaderFooter/UploaderFooter';
import { UploadStatus } from '../../../Context/Skill/SkillState';
import { VideoType } from '../../../Models/Video';

const SkillVideosPage = () => {
    const { skill, clearSelection, uploadFiles } = useContext(SkillContext);

    const allowedVideoExtensions = ['.swf', '.mov', '.wmv', '.avi', '.mpeg', '.mpg', '.ppt', '.pptx', '.pdf'];

    const onDropHandler = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer?.files && uploadFiles(Array.from(event.dataTransfer.files), MediaType.videos, allowedVideoExtensions);
    };
    
    const onDragOverHandler = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    useEffect(() => {
        clearSelection();
    }, []);

    if(skill) {
        const viewModels = skill.videos?.map(video => {
            return {
                key: video.key,
                isActive: video.isActive,
                commentText: video.commentText,
                description: video.animationName,
                fileName: video.fileName,
                itemOrder: video.itemOrder,
                previewUrl: video.videoUrl,
                mediaType: MediaType.videos,
                uploadStatus: video.uploadStatus ?? UploadStatus.successful,
                isCustom: video.isCustom,
                canPreviewInUploader: video.mediaType !== VideoType.animation && video.mediaType !== VideoType.videos && !video.isCustom,
                closedCaptionsUrl: video.closedCaptionsUrl,
                subtitles: video.subtitles,
                animationId: video.animationId,
            } as MediaViewModel;
        });
        
        return (
            <Fragment>
                <div className="scroll-container o-els-flex-layout__item--grow u-els-margin-top-1x"
                    data-testid='videos_page'
                    onDrop={onDropHandler}
                    onDragOver={onDragOverHandler}>
                    <MediaList mediaFiles={viewModels}></MediaList>
                </div>
                <UploaderFooter  allowedExtensions={allowedVideoExtensions} mediaType={MediaType.videos} />
            </Fragment>
        );
    }

    return (
        <ErrorPage />
    );
    
};

export default SkillVideosPage;