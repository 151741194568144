import { createContext } from 'react';
import { AuthenticationReducerState } from './AuthenticationReducer';

interface ContextType extends AuthenticationReducerState {
    processLoginCallback: (url: string) => Promise<string>,
    initiateLogin: (url:string) => Promise<void>,
    initiateLogout: () => Promise<void>,
}

const AuthenticationContext = createContext({} as ContextType);

export default AuthenticationContext;