import React, { KeyboardEvent, createRef } from 'react';
import CloseModalIcon from '../../Icons/CloseModalIcon';

type ConfirmModalProps = {
    confirmModalHeading: string,
    confirmModalMessage: string,
    confirmModalFunction: () => void,
    setConfirmModalIsOpen: (isOpen: boolean) => void,
}

const ConfirmModal = ({ confirmModalHeading, confirmModalMessage, confirmModalFunction, setConfirmModalIsOpen }: ConfirmModalProps) => {
    const closeXRef = createRef<HTMLButtonElement>();
    const confirmButtonRef = createRef<HTMLButtonElement>();
    
    const onConfirmClicked = () => {
        confirmModalFunction();
        setConfirmModalIsOpen(false);
    };

    const onTabLastElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            closeXRef.current?.focus();
        }
    };

    const onShiftTabFirstElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            confirmButtonRef.current?.focus();
        }
    };

    return (
        <div className='c-els-modal c-els-modal--primary u-els-padding-2x'>
            <div className='c-els-modal__window u-els-width-1o2 u-els-padding-2x'>
                <button className='c-els-modal__close u-els-anchorize' onClick={() => setConfirmModalIsOpen(false)} onKeyDown={event => onShiftTabFirstElement(event)}
                    ref={closeXRef} data-testid='close_x'>
                    <CloseModalIcon />
                </button>
                <h3 className='u-els-margin-bottom'>{confirmModalHeading}</h3>
                <span>{confirmModalMessage}</span>
                <div className="o-els-flex-layout u-els-margin-top o-els-flex--space-between">
                    <div className='o-els-flex-layout__item--grow'></div>
                    <div className="o-els-flex-layout__item--right u-els-nowrap">
                        <button className="c-els-button c-els-button--secondary" onClick={() => setConfirmModalIsOpen(false)} autoFocus={true}>Cancel</button>
                        <button className="c-els-button u-els-margin-left-1o2" onClick={onConfirmClicked} onKeyDown={event => onTabLastElement(event)}
                            ref={confirmButtonRef}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;