import axios from 'axios';
import React, {useContext, useReducer} from 'react';
import { Category } from '../../Models/Category';
import { Specialty } from '../../Models/Specialty';
import AlertContext from '../Alert/AlertContext';
import ConfigContext from '../Config/ConfigContext';
import TagsContext from './TagsContext';
import TagsReducer, { addNewKeywordAction, clearCreatingTagTypeAction, clearLatestCategoryAction, clearLatestKeywordAction, clearLatestSpecialtyAction, clearManagingTagTypeAction, getAllCategoriesAction, getAllSpecialtiesAction, setCreatingTagTypeAction, setIsLoadingTagsAction, setLatestCategoryAction, setLatestKeywordListAction, setLatestSpecialtyAction, setManagingTagTypeAction, TagsReducerState } from './TagsReducer';
import SkillContext from '../Skill/SkillContext';
import { ToastMessage, ToastType } from '../Alert/AlertState';

export enum TagType {
    category = 'Category',
    specialty = 'Specialty',
    keyword = 'Keyword'
}

const TagsState = (props: any) => {
    const { config } = useContext(ConfigContext);
    const { setAlert, addToastMessage } = useContext(AlertContext);
    const { getSkill } = useContext(SkillContext);

    const initialState = {
        isLoadingTags: false,
        latestKeywordList: [] as string[],
    } as TagsReducerState;

    const [ state, dispatch ] = useReducer(TagsReducer, initialState);

    const getAllCategories = async (newCategory?: string) => {
        try {
            axios.defaults.headers['Pragma'] = 'no-cache';
            const categoriesResponse = await axios.get(`${config?.SkillsContentServicesBaseUrl}/api/Categories`);

            dispatch(getAllCategoriesAction(categoriesResponse.data));

            const newCategoryResponse = categoriesResponse.data.find((x: Category) => x.itemData == newCategory);
            
            if (newCategoryResponse) {
                dispatch(setLatestCategoryAction(newCategoryResponse));
            }
        }
        catch {
            setAlert('There was an error loading categories.');
        }
    };

    const getAllSpecialties = async (newSpecialty?: string) => {
        try {
            axios.defaults.headers['Pragma'] = 'no-cache';
            const specialtiesResponse = await axios.get(`${config?.SkillsContentServicesBaseUrl}/api/Specialties`);

            dispatch(getAllSpecialtiesAction(specialtiesResponse.data));

            const newSpecialtyResponse = specialtiesResponse.data.find((x: Specialty) => x.itemData == newSpecialty);
            
            if (newSpecialtyResponse) {
                dispatch(setLatestSpecialtyAction(newSpecialtyResponse));
            }
        }
        catch {
            setAlert('There was an error loading specialties.');
        }
    };

    const getAllTags = async (newCategory?: string, newSpecialty?: string) => {
        dispatch(setIsLoadingTagsAction(true));

        await getAllCategories(newCategory);
        await getAllSpecialties(newSpecialty);

        dispatch(setIsLoadingTagsAction(false));
    };

    const addCustomCategory = async (categoryName: string) => {
        const path = `${config?.SkillsContentServicesBaseUrl}/api/Categories/addCustomCategory`;
        
        const data = new FormData();
        data.append('category', categoryName);

        dispatch(setIsLoadingTagsAction(true));

        if (await postTag(path, data)) {
            await getAllTags(categoryName, undefined);

            return true;
        }
        else {
            dispatch(setIsLoadingTagsAction(false));

            return false;
        }
    };

    const addCustomSpecialty = async (specialtyName: string) => {
        const path = `${config?.SkillsContentServicesBaseUrl}/api/Specialties/addCustomSpecialty`;
        
        const data = new FormData();
        data.append('specialty', specialtyName);

        dispatch(setIsLoadingTagsAction(true));

        if (await postTag(path, data)) {
            await getAllTags(undefined, specialtyName);

            return true;
        }
        else {
            dispatch(setIsLoadingTagsAction(false));

            return false;
        }
    };

    const deleteCustomCategories = async (categoryIds: string[], skillKeyId: string, isInCurrentSkill: boolean) => {
        const path = `${config?.SkillsContentServicesBaseUrl}/api/Categories/deleteCustomCategories`;

        const body = categoryIds;

        dispatch(setIsLoadingTagsAction(true));

        if (await postTags(path, body)) {
            const messageOne = categoryIds.length == 1 ? 'Custom category deleted.' : 'Custom categories deleted.';
            
            addToastMessage({
                messageOne,
                type: ToastType.success,
            } as ToastMessage);

            await getAllTags();
            
            if (isInCurrentSkill) {
                getSkill(skillKeyId);
            }
        }
        else {
            dispatch(setIsLoadingTagsAction(false));
        }
    };

    const deleteCustomSpecialties = async (specialtyIds: string[], skillKeyId: string, isInCurrentSkill: boolean) => {
        const path = `${config?.SkillsContentServicesBaseUrl}/api/Specialties/deleteCustomSpecialties`;
        
        const body = specialtyIds;

        dispatch(setIsLoadingTagsAction(true));

        if (await postTags(path, body)) {
            const messageOne = specialtyIds.length == 1 ? 'Custom specialty deleted.' : 'Custom specialties deleted.';
            
            addToastMessage({
                messageOne,
                type: ToastType.success,
            } as ToastMessage);

            await getAllTags();

            if (isInCurrentSkill) {
                getSkill(skillKeyId);
            }
        }
        else {
            dispatch(setIsLoadingTagsAction(false));
        }
    };

    const addKeyword = (keyword: string) => {
        dispatch(addNewKeywordAction(keyword));
    };

    const postTag = async (path: string, data: FormData) => {
        try {
            await axios.post(path, data);

            return true;
        }
        catch (error: any) {
            if (error.response?.data) {
                setAlert(error.response.data);
            }
            else {
                setAlert(error.message);
            }

            return false;
        }
    };

    const postTags = async <T,>(path: string, body: T) => {
        try {
            await axios.post(path, body);

            return true;
        }
        catch (error: any) {
            if (error.response?.data) {
                setAlert(error.response.data);
            }
            else {
                setAlert(error.message);
            }

            return false;
        }
    };

    const setLatestKeywordList = (keywords: string[]) => {
        dispatch(setLatestKeywordListAction(keywords));
    };

    const clearLatestCategory = () => {
        dispatch(clearLatestCategoryAction());
    };

    const clearLatestSpecialty = () => {
        dispatch(clearLatestSpecialtyAction());
    };

    const clearLatestKeyword = () => {
        dispatch(clearLatestKeywordAction);
    };

    const openCreateTag = (tagType: TagType) => {
        dispatch(setCreatingTagTypeAction(tagType));
    };

    const closeCreateTag = () => {
        dispatch(clearCreatingTagTypeAction());
    };

    const openManageTags = (tagType: TagType) => {
        dispatch(setManagingTagTypeAction(tagType));
    };

    const closeManageTags = () => {
        dispatch(clearManagingTagTypeAction());
    };

    return (
        <TagsContext.Provider value = {{
            ...state,
            getAllTags,
            addCustomCategory,
            addCustomSpecialty,
            deleteCustomCategories,
            deleteCustomSpecialties,
            addKeyword,
            setLatestKeywordList,
            clearLatestCategory,
            clearLatestSpecialty,
            clearLatestKeyword,
            openCreateTag,
            closeCreateTag,
            openManageTags,
            closeManageTags,
        }}>
            {props.children}
        </TagsContext.Provider>
    );
};

export default TagsState;