import React, { useContext, createRef, KeyboardEvent } from 'react';
import SkillContext from '../../../../Context/Skill/SkillContext';
import CloseModalIcon from '../../Icons/CloseModalIcon';
import { useHistory } from 'react-router-dom';

const UnsavedChangesModal = () => {
    const { skill, unsavedChangesRoute, closeUnsavedChanges, getSkill } = useContext(SkillContext);

    const history = useHistory();

    const closeButtonRef = createRef<HTMLButtonElement>();
    const discardButtonRef = createRef<HTMLButtonElement>();

    const onClickClose = () => {
        closeUnsavedChanges();
    };

    const oncClickDiscard = () => {
        closeUnsavedChanges();

        getSkill(skill.skillKeyId);
        
        if (unsavedChangesRoute?.length > 0) {
            history.replace(unsavedChangesRoute);
        }
    };

    const onTabLastElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            closeButtonRef.current?.focus();
        }
    };

    const onShiftTabFirstElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            discardButtonRef.current?.focus();
        }
    };

    return (
        <div className='c-els-modal c-els-modal--primary u-els-padding-2x u-app-z-index--modal'>
            <div className='c-els-modal__window u-els-width-1o2 u-els-padding-2x'>
                <button className='c-els-modal__close u-els-anchorize' onClick={onClickClose} onKeyDown={event => onShiftTabFirstElement(event)}
                    ref={closeButtonRef} data-testid='close_x'>
                    <CloseModalIcon />
                </button>
                <h3 className='u-els-margin-bottom'>Unsaved changes</h3>
                <span>You currently have un-saved changes.  Please save your changes before switching tabs or closing the application.</span>
                <div className="o-els-flex-layout u-els-margin-top o-els-flex--space-between">
                    <div className='o-els-flex-layout__item--grow'></div>
                    <div className="o-els-flex-layout__item--right u-els-nowrap">
                        <button className="c-els-button" onClick={onClickClose} autoFocus={true}>Cancel</button>
                        <button className="c-els-button u-els-margin-left-1o2" onClick={oncClickDiscard}
                            onKeyDown={event => onTabLastElement(event)} ref={discardButtonRef}>Discard</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnsavedChangesModal;
