import React, { useContext, useEffect, useReducer } from 'react';
import AuthenticationContext from './AuthenticationContext';
import AuthenticationReducer, { AuthenticationReducerState, loadUserAction, loadUserManagerAction, logoutUserAction } from './AuthenticationReducer';
import ConfigContext from '../Config/ConfigContext';
import { createUserManager } from '../../Utilities/Authentication/user-manager';
import setAuthToken from '../../Utilities/Authentication/setAuthToken';
import Oidc, { User } from 'oidc-client';
Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.ERROR;

const AuthenticationState = (props: any) => {
    const initialState = {loading: true} as AuthenticationReducerState;

    const [state, dispatch] = useReducer(AuthenticationReducer, initialState);

    const { config } = useContext(ConfigContext);

    useEffect(() => {
        if (!config) {
            return;
        }

        const userManager = createUserManager(config);
        dispatch(loadUserManagerAction(userManager));
    }, [config]);


    const initiateLogin = async (href: string) => {

        const {userManager} = state;
        if(!userManager){
            return;
        }

        const url = new URL(href);
        const clientId = url.searchParams.get('clientId');
        const login = url.searchParams.get('login');

        if(clientId && login) {
            const queryParams = url.searchParams;
            queryParams.delete('clientId');
            queryParams.delete('login');

            const args = {
                prompt: 'login',
                login_hint: encodeURIComponent(login),
                acr_values: `tenant:${encodeURIComponent(clientId)}`,
                data: `${url.pathname}?${queryParams}`,

            };

            await userManager.signinRedirect({ ...args });
            return;
        }

        const user = await userManager.getUser();
        if(user){
            initializePendo(user);

            setAuthToken(user.access_token);
            dispatch(loadUserAction(user));
            return;
        }
        
        await userManager.signinRedirect({
            data: url.pathname,
            redirectMethod: 'replace',
        });
    };

    const processLoginCallback = async (url: string) => {
        if (!state.userManager) {
            return;
        }

        const appUserManager = state.userManager;
        const user = await appUserManager.signinCallback(url);

        setAuthToken(user.access_token);
        initializePendo(user);

        user && dispatch(loadUserAction(user));

        return user.state;
    };

    const initiateLogout = async () => {

        const appUserManager = await state.userManager;
        await appUserManager?.signoutRedirect();

        dispatch(logoutUserAction());
    };

    const initializePendo = (user: User) => {
        const windowObject = window as any;
        const pendo = windowObject.pendo;

        pendo.initialize({
            visitor: {
                id: `${user.profile.tenant}-${user.profile.name}`.toLowerCase(),
                userRole: 'admin',
                studentId: user.profile.name,
            },
            account: {
                id: user.profile.tenant,
            }
        });
    };

    return (
        <AuthenticationContext.Provider
            value={{
                ...state,
                processLoginCallback,
                initiateLogin,
                initiateLogout,
            }}>
            { props.children}
        </AuthenticationContext.Provider>
    );

};

export default AuthenticationState;