import React, { createRef, useContext, useEffect, useRef, KeyboardEvent } from 'react';
import SkillContext from '../../../../Context/Skill/SkillContext';
import CloseModalIcon from '../../Icons/CloseModalIcon';
import { instantiateAblePlayer } from '@ecl/ecl-libraries-ableplayer/dist';

const VideoPreviewModal = () => {
    const { previewState, closeVideoPreview } = useContext(SkillContext);
    const { previewPath, previewCaptionsPath, previewSubtitles, previewTitle, previewId } = previewState;

    const closeXButtonRef = createRef<HTMLButtonElement>();
    const endOfDivRef = createRef<HTMLDivElement>();
    const videoWrapper = useRef<HTMLVideoElement>(null);

    let ablePlayerCleanupFunction: { (): void; (): void; };

    const onClickClose = () => {
        closeVideoPreview();
    };

    const onFocusLastElement = () => {
        closeXButtonRef.current?.focus();
    };

    const onShiftTabFirstElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();

            const fullscreenButton = document.querySelector('.' + 'able-button-handler-fullscreen') as HTMLDivElement;
            fullscreenButton?.focus();
        }
    };

    useEffect(() => {
        if (videoWrapper.current != null) ablePlayerCleanupFunction = instantiateAblePlayer(videoWrapper.current);
        return () => ablePlayerCleanupFunction();
    }, [videoWrapper.current]);

    return (
        <div className='c-els-modal u-els-padding-2x u-app-z-index--modal'>
            <div className='c-els-modal__window u-els-padding-2x u-els-width-1o2'>
                <button className='c-els-modal__close u-els-anchorize' onClick={onClickClose}
                    onKeyDown={event => onShiftTabFirstElement(event)} ref={closeXButtonRef} autoFocus={true} data-testid='close_x'>
                    <CloseModalIcon />
                </button>
                <h3 className='u-els-margin-bottom'>{ previewTitle }</h3>
                <div>
                    <video id="video1" data-able-player preload="auto" autoPlay={true} ref={videoWrapper}
                        data-animation-id={previewId} data-animation-title={previewTitle}>
                        <source type="video/mp4" src={previewPath} />
                        { (previewCaptionsPath !== '') && <track kind='captions' data-testid='captions' label='English CC' srcLang='en' src={previewCaptionsPath} /> }
                        { previewSubtitles?.map((subtitle, index) => (
                            <track kind='subtitles' key={`subtitle-${index}`} data-testid={`subtitle-${index}`}
                                src={subtitle.subtitleUrl} srcLang={subtitle.languageCode} label={subtitle.languageName}></track>
                        ))}
                    </video>
                </div>
                <div tabIndex={0} onFocus={() => onFocusLastElement()} ref={endOfDivRef} data-testid='last_div'></div>
            </div>
        </div>
    );
};

export default VideoPreviewModal;