import React, { useReducer } from 'react';
import { ButtonType } from '../Skill/SkillState';
import AlertContext from './AlertContext';
import AlertReducer, { addToastMessageAction, clearAlertAction, clearToastMessageAction, AlertReducerState, setAlertAction } from './AlertReducer';

export interface ToastMessage {
    messageOne: string,
    messageTwo?: string,
    type: ToastType,
    uniqueId: number,
}

export enum ToastType {
    success = 'Success',
    error = 'Error',
    information = 'Information',
    none = 'None',
}


const AlertState = (props: any) => {
    const initialState = {
        toastMessages: [] as ToastMessage[],
        nextToastId: 0,
    } as AlertReducerState;

    const [state, dispatch] = useReducer(AlertReducer, initialState);

    const setAlert = (message: string, buttonType = ButtonType.closeButton) => {
        dispatch(setAlertAction({message, buttonType}));
    };

    const clearAlert = () => {
        dispatch(clearAlertAction());
    };

    const addToastMessage = (toastMessage: ToastMessage) => {
        dispatch(addToastMessageAction(toastMessage));
    };

    const clearToastMessage = (index: number) => {
        dispatch(clearToastMessageAction(index));
    };

    return (
        <AlertContext.Provider
            value={{
                ...state,
                setAlert,
                clearAlert,
                addToastMessage,
                clearToastMessage,
            }}>
            { props.children}
        </AlertContext.Provider>
    );
};

export default AlertState;