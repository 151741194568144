import React, { createRef, Fragment } from 'react';
import { ChecklistItem } from '../../../Models/ChecklistItem';
import EditIcon from '../Icons/EditIcon';

import './ChecklistItemList.scss';

type ChecklistItemListProps = {
    itemList: ChecklistItem[],
    onEditClicked: (question: ChecklistItem, index: number) => void,
    openConfirmModal: (index: number) => void,
}

const ChecklistItemList = ({ itemList, onEditClicked, openConfirmModal }: ChecklistItemListProps) => {
    const bottomElement = createRef<HTMLDivElement>();

    const onClickDelete = (index: number) => {
        openConfirmModal(index);
    };

    const onclickEdit = (checklistItem: ChecklistItem, index: number) => {
        onEditClicked(checklistItem, index);
    };

    return (
        <Fragment>
            <table className='c-els-table'>
                <thead className='c-els-table__head'>
                    <tr className='c-els-table__row c-els-table__row--head'>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Number
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Checklist item
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Edit
                        </th>
                    </tr>
                </thead>
                <tbody className='c-els-table__body'>
                    {itemList.map((element, index) => (
                        <tr key={index} className='c-els-table__row'>
                            <td className='c-els-table__cell' key={`${element.index}-index`} data-testid='checklist-item-index'>
                                {element.index}
                            </td>
                            <td className='c-els-table__cell item-text-cell s-els-non-authored-content' key={`${element.index}-text`} data-testid='checklist-item-text'
                                dangerouslySetInnerHTML={{ __html: element.text }}></td>
                            <td className='c-els-table__cell'>
                                <div className='u-els-display-flex'>
                                    <button className='u-els-anchorize c-els-link' data-testid='edit-btn' onClick={() => onclickEdit(element, index)}>
                                        <EditIcon />
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <button className='u-els-anchorize c-els-link' data-testid='delete-btn' onClick={() => onClickDelete(index)}>Remove</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {itemList.length == 0 ? <div className='u-els-text-center'>No data available</div> : null}
            <div ref={bottomElement}></div>
        </Fragment>
    );
};
export default ChecklistItemList;
