import React, { Fragment, useContext, useEffect, useState } from 'react';
import SkillContext from '../../../Context/Skill/SkillContext';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import { ChecklistItem } from '../../../Models/ChecklistItem';
import CustomSkillCard from '../../Layout/CustomSkillCard/CustomSkillCard';
import ChecklistItemList from '../../Layout/ChecklistItemList/ChecklistItemList';
import ChecklistItemForm from '../../Layout/ChecklistItemForm/ChecklistItemForm';
import EditChecklistItemModal from '../../Layout/Modals/EditChecklistItemModal/EditChecklistItemModal';
import VersionCommentField from '../../Layout/VersionCommentField/VersionCommentField';
import AlertContext from '../../../Context/Alert/AlertContext';
import { ToastMessage, ToastType } from '../../../Context/Alert/AlertState';
import ConfirmModal from '../../Layout/Modals/ConfirmModal/ConfirmModal';

const SkillChecklistPage = () => {
    const { skill, hasBeenModified, saveChecklist, openUnsavedChanges, setHasBeenModified } = useContext(SkillContext);
    const { addToastMessage } = useContext(AlertContext);

    const [ commentText, setCommentText ] = useState('');
    const [ isCommentError, setIsCommentError ] = useState(false);
    const [ checklistItems, setChecklistItems ] = useState(skill.checklistItems);
    const [ currentlyEdittingIndex, setCurrentlyEdittingIndex ] = useState(0);
    const [ checklistItemTextHasBeenTouched, setChecklistItemTextHasBeenTouched ] = useState(false);
    const [ currentEditItem, setCurrentEditItem ] = useState({} as ChecklistItem);
    const [ showEditChecklistItemModal, setShowEditChecklistItemModal ] = useState(false);
    const [ isError, setIsError ] = useState(false);
    const [ shouldTriggerClear, setShouldTriggerClear ] = useState(false);
    const [ confirmModalIsOpen, setConfirmModalIsOpen ] = useState(false);
    const [ indexToRemove, setIndexToRemove ] = useState(0);

    const [ currentChecklistItem, setCurrentChecklistItem ] = useState({
        index: checklistItems.length + 1,
        text: '',
    } as ChecklistItem);

    useEffect(() => {
        setChecklistItems(skill.checklistItems);
    }, [skill]);

    useEffect(() => {
        updateHasBeenModified();
    }, [checklistItems, currentChecklistItem, commentText]);

    const commentCharacterLimit = 512;

    const onSaveClicked = () => {
        saveChecklist(checklistItems, commentText);
    };

    const onSaveChecklistItemClicked = (newChecklistItem: ChecklistItem, isEdit: boolean) => {
        let updatedChecklistItems = [] as ChecklistItem[];

        const updatingChecklistItem = { ...newChecklistItem, index: newChecklistItem.index <= 0 ? 1 : newChecklistItem.index } as ChecklistItem;
        
        checklistItems.forEach((checklistItem, index) => {
            if (updatingChecklistItem.index == updatedChecklistItems.length + 1) {
                updatedChecklistItems = [...updatedChecklistItems, updatingChecklistItem];
            }
            
            if (index != currentlyEdittingIndex || !isEdit) {
                updatedChecklistItems = [...updatedChecklistItems, {...checklistItem, index: updatedChecklistItems.length + 1} as ChecklistItem];
            }
        });

        if (updatingChecklistItem.index >= updatedChecklistItems.length + 1) {
            updatedChecklistItems = [...updatedChecklistItems, { ...updatingChecklistItem, index: updatedChecklistItems.length + 1 }];
        }

        setChecklistItems(updatedChecklistItems);

        if (!isEdit) {
            setCurrentChecklistItem({
                index: updatedChecklistItems.length + 1,
                text: '',
            } as ChecklistItem);
    
            setShouldTriggerClear(true);
            setChecklistItemTextHasBeenTouched(false);

            addToastMessage({
                type: ToastType.success,
                messageOne: 'Checklist item has been added successfully.',
            } as ToastMessage);
        }
        else {
            addToastMessage({
                type: ToastType.success,
                messageOne: 'Checklist item has been edited successfully.',
            } as ToastMessage);
        }
    };

    const openConfirmModal = (index: number) => {
        setIndexToRemove(index);
        setConfirmModalIsOpen(true);
    };

    const onConfirmRemove = () => {
        let updatedChecklistItems = [] as ChecklistItem[];

        checklistItems.forEach((question, arrayIndex) => {
            if (arrayIndex != indexToRemove) {
                updatedChecklistItems = [...updatedChecklistItems, {...question, index: updatedChecklistItems.length + 1} as ChecklistItem];
            }
        });

        setChecklistItems(updatedChecklistItems);

        const currentChecklistItemIndex = currentChecklistItem.index > updatedChecklistItems.length + 1 ? updatedChecklistItems.length + 1 : currentChecklistItem.index;

        setCurrentChecklistItem({
            id: currentChecklistItem.id,
            index: currentChecklistItemIndex,
            text: currentChecklistItem.text,
        } as ChecklistItem);

        addToastMessage({
            type: ToastType.success,
            messageOne: 'Checklist item has been removed successfully.',
        } as ToastMessage);
    };

    const onEditClicked = (checklistItem: ChecklistItem, index: number) => {
        setCurrentEditItem(checklistItem);
        setCurrentlyEdittingIndex(index);
        setShowEditChecklistItemModal(true);
    };

    const onCancelClicked = () => {
        openUnsavedChanges('');
    };

    const isAddDisabled = () => {
        return isError || !checklistItemTextHasBeenTouched;
    };

    const isSaveDisabled = () => {
        return isCommentError || !hasBeenModified;
    };

    const isCancelDisabled = () => {
        return !hasBeenModified && !checklistItemTextHasBeenTouched;
    };

    const updateHasBeenModified = () => {
        setHasBeenModified(skill.checklistItems != checklistItems || commentText.length > 0 || currentChecklistItem.text.length > 0);
    };

    if (skill) {

        return (
            <Fragment>
                {confirmModalIsOpen &&
                <ConfirmModal confirmModalHeading='Remove item' confirmModalMessage='Are you sure you really want to remove this item?'
                    confirmModalFunction={onConfirmRemove} setConfirmModalIsOpen={setConfirmModalIsOpen}/>
                }
                {showEditChecklistItemModal &&
                <EditChecklistItemModal checklistItem={currentEditItem} setChecklistItem={setCurrentEditItem} setShowModal={setShowEditChecklistItemModal}
                    saveChecklistItem={onSaveChecklistItemClicked}></EditChecklistItemModal>
                }
                <div className="u-els-margin-top-1x u-els-padding-left-2x u-els-padding-right-2x scroll-container o-els-flex-layout__item--grow">
                    <span className="u-els-font-size-intro">Checklist</span>
                    <CustomSkillCard></CustomSkillCard>
                    <ChecklistItemList itemList={checklistItems} onEditClicked={onEditClicked} openConfirmModal={openConfirmModal}></ChecklistItemList>
                    <div className='u-els-margin-top-2x'>
                        <span className="c-els-field__label-text skill-content-editor__title u-els-font-size-body-large">Add checklist item</span>
                        <ChecklistItemForm checklistItem={currentChecklistItem} setChecklistItem={setCurrentChecklistItem} numberOfItems={checklistItems.length}
                            checklistItemTextHasBeenTouched={checklistItemTextHasBeenTouched} setIsError={setIsError}
                            setChecklistItemTextHasBeenTouched={setChecklistItemTextHasBeenTouched}
                            shouldTriggerClear={shouldTriggerClear} setShouldTriggerClear={setShouldTriggerClear}></ChecklistItemForm>
                        <button type='button' data-testid='save-checklist-item-button' className="c-els-button u-els-margin-top"
                            onClick={() => onSaveChecklistItemClicked(currentChecklistItem, false)} disabled={isAddDisabled()}>Add checklist item</button>
                        <div className="u-els-margin-top-2x">
                            <VersionCommentField commentText={commentText} setCommentText={setCommentText} setIsCommentError={setIsCommentError}
                                commentCharacterLimit={commentCharacterLimit}></VersionCommentField>
                        </div>
                        <div className="u-els-margin-top-1x1o2 u-els-margin-bottom">
                            <button type='button' className="c-els-button" onClick={onSaveClicked} disabled={isSaveDisabled()} > Save</button>
                            <button type='button' className="c-els-button c-els-button--secondary u-els-margin-left" disabled={isCancelDisabled()} onClick={onCancelClicked} >Cancel</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
    return (
        <ErrorPage />
    );
};

export default SkillChecklistPage;