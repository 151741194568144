import React, { Fragment, useState } from 'react';
import AlertIcon from '../Icons/AlertIcon';
import ExpandArrowIcon from '../Icons/ExpandArrowIcon';
import CollapseArrowIcon from '../Icons/CollapseArrowIcon';

// @ts-ignore
import { Card } from '@els/els-react--card';

import './CustomSkillCard.scss';

const CustomSkillCard = () => {
    const [ isExpanded, setIsExpanded ] = useState(false);

    return (
        <Fragment>
            <Card borderWeight="none" className="alert-box u-els-margin-top-1x1o2 u-els-margin-bottom-2x">
                <div className="u-els-display-flex">
                    <AlertIcon></AlertIcon>
                    <div>
                        <div className="u-els-margin-left">Directly editing an Elsevier skill will turn it into a customized skill that your institution will have to maintain.
                            {!isExpanded && (
                                <Fragment>
                                    <span className="expand-collapse-button">
                                        <ExpandArrowIcon></ExpandArrowIcon>
                                        <a className="link-with-pointer c-els-link--with-icon" onClick={() => setIsExpanded(true)}>
                                            More details
                                        </a>
                                    </span>
                                </Fragment>
                            )}
                            {isExpanded && (
                                <Fragment>
                                    <span className="expand-collapse-button">
                                        <CollapseArrowIcon></CollapseArrowIcon>
                                        <a className="link-with-pointer c-els-link--with-icon" onClick={() => setIsExpanded(false)}>
                                            Less details
                                        </a>
                                    </span>
                                </Fragment>
                            )}
                        </div>
                        {isExpanded && (
                            <div className="custom-card-additional-text u-els-margin-top u-els-margin-left">
                            Editing any areas other than the notes tab, categories, specialties and keywords will prevent you from receiving monthly Elsevier updates on this skill. It will also discontinue CE credits.
                            </div>
                        )}
                    </div>
                </div>
            </Card>
        </Fragment>
    );
};

export default CustomSkillCard;