import React, { createRef, useContext, useEffect } from 'react';
import AlertContext from '../../../Context/Alert/AlertContext';
import { ToastType } from '../../../Context/Alert/AlertState';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

// @ts-ignore
import { Icon } from '@els/els-react--icon';

import './ToastComponent.scss';

export type ToastComponentProps = {
    toastDuration: number;
}

const ToastComponent = ({ toastDuration }: ToastComponentProps) => {

    const { toastMessages, clearToastMessage } = useContext(AlertContext);

    useEffect(() => {
        const interval = setInterval(() => {
            if (toastMessages.length > 0) {
                clearToastMessage(0);
            }
        }, toastDuration);

        return () => clearInterval(interval);
    }, [toastMessages]);

    const onClickCloseToast = (index: number) => {
        clearToastMessage(index);
    };

    const getToastStyleClass = (toastType: ToastType) => {
        switch (toastType) {
        case ToastType.success:
            return 'c-els-toast__item--confirm';
        case ToastType.error:
            return 'c-els-toast__item--warn';
        case ToastType.information:
            return 'c-els-toast__item--primary';
        default:
            return 'c-els-toast__item--secondary';
        }
    };

    return (
        <div className='toast-container'>
            <TransitionGroup className='c-els-toast toast u-els-margin-right u-els-margin-right-none@mobile' component='div'>
                {toastMessages?.map((toastMessage, index) => {
                    const nodeRef = createRef<HTMLDivElement>();
                    return (
                        <CSSTransition key={`toast${toastMessage.uniqueId}`} timeout={500} classNames='toast-transition' nodeRef={nodeRef}>
                            <div className="u-els-margin u-els-padding c-els-toast__item-wrapper" role="status" ref={nodeRef}>
                                <div className={`c-els-toast__item ${getToastStyleClass(toastMessage.type)}`}>
                                    <button className='c-els-modal__close u-els-anchorize' data-testid={`close-x-${toastMessage.uniqueId}`}
                                        onClick={() => onClickCloseToast(index)}>
                                        <Icon
                                            className="react-icon-fix"
                                            sprite="Close"
                                            id="CloseToast"
                                            isVisible={true}
                                            size="s"
                                            a11y={{ name: 'Close', description: 'Close toast'}}>
                                        </Icon>
                                    </button>
                                    <div className='u-els-display-flex'>
                                        {toastMessage.type == ToastType.success &&
                                        <div className='u-els-margin-right' data-testid={`toast-success-icon-${toastMessage.uniqueId}`}>
                                            <Icon
                                                className="react-icon-fix"
                                                sprite="ConfirmationOutlineCircle"
                                                id="SuccessToast"
                                                isVisible={true}
                                                color="positive"
                                                textAlignment="offset"
                                                size="m"
                                                a11y={{ name: 'Success', description: 'Success message'}}>
                                            </Icon>
                                        </div>
                                        }
                                        <div>
                                            <div>{ toastMessage.messageOne }</div>
                                            {toastMessage.messageTwo &&
                                            <div className='u-els-margin-top-1o4'>{ toastMessage.messageTwo }</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                    );
                })}
            </TransitionGroup>
        </div>
    );

};

export default ToastComponent;