import React from 'react';
import { Colors } from '../Colors';

const AlertIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 128" height="24" width="24" fill={Colors.hmdswarn} data-testid='error_icon'><path d="M63 8c-14.692 0-28.502 5.722-38.892 16.11C13.72 34.5 8 48.31 8 63c0 30.326 24.672 55 55 55h.008c14.688 0 28.498-5.72 38.884-16.108C112.28 91.506 118 77.692 118 63c0-30.328-24.672-55-55-55zm6 84H57V80h12v12zm0-41.858L66.398 72h-7.2L57 50.142V34h12v16.142z"/>
            <title>Alert</title>
        </svg>
    );
};

export default AlertIcon;