import React from 'react';
import { Colors } from '../Colors';

const CheckmarkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 128" height="24" width="17" fill={Colors.hmdsconfirm} data-testid='checkmark_icon'><path d="M0 58.08l7.86-6.18 29.18 37.14 42.24-67.7 8.48 5.3-49.78 79.8z"/>
            <title>Uploaded successfully</title>
        </svg>
    );
};

export default CheckmarkIcon;