import React from 'react';
import { Colors } from '../Colors';

const CollapseArrowIcon = () => {
    return (
        <svg className="o-els-icon-svg--middle" xmlns="http://www.w3.org/2000/svg" viewBox="-40 -60 184 256" height="28" width="28" fill={Colors.brandblue}><path d="M46 46L8 84l-7-7 45-45 45 45-7 7z"/>
            <title>Collapse</title>
        </svg>
    );
};

export default CollapseArrowIcon;