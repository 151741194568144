import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '@els/els-styleguide-accessibility/elsStyleguideAccessibility.min.js';

import AuthenticationState from './Context/Authentication/AuthenticationState';
import ConfigState from './Context/Config/ConfigState';
import AlertState from './Context/Alert/AlertState';

import { Callback } from './Components/Authentication/Callback';
import { Logout } from './Components/Authentication/Logout';
import { HomePage } from './Components/Pages/HomePage/HomePage';
import { ErrorPage } from './Components/Pages/ErrorPage/ErrorPage';

import './App.scss';
import PrivateRoute from './Components/Routing/PrivateRoute/PrivateRoute';
import SkillState from './Context/Skill/SkillState';
import SkillRoute from './Components/Routing/SkillRoute/SkillRoute';
import AlertModal from './Components/Layout/Modals/AlertModal/AlertModal';
import TagsState from './Context/Tags/TagsState';
import ToastComponent from './Components/Layout/ToastComponent/ToastComponent';

function App() {
    const toastDurationMilliseconds = 6000;

    return (
        <AlertState>
            <ConfigState>
                <AuthenticationState>
                    <SkillState>
                        <TagsState>
                            <AlertModal />
                            <ToastComponent toastDuration={toastDurationMilliseconds} />
                            <Router>
                                <Switch>
                                    <Route exact path='/authentication/callback' component={Callback} />
                                    <Route exact path='/authentication/logout-callback' component={Logout} />
                                    <Route exact path='/error' component={ErrorPage} />
                                    <PrivateRoute exact path='/' component={HomePage} />
                                    <PrivateRoute path='/skill/:id' component={SkillRoute} />
                                </Switch>
                            </Router>
                        </TagsState>
                    </SkillState>
                </AuthenticationState>
            </ConfigState>
        </AlertState>
    );
}

export default App;
