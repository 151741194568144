import React, { createRef, useState, KeyboardEvent } from 'react';
import { TestQuestion } from '../../../../Models/TestQuestion';
import CloseModalIcon from '../../Icons/CloseModalIcon';
import TestQuestionForm, { TestQuestionFormDirtyState } from '../../TestQuestionForm/TestQuestionForm';

import './EditTestModal.scss';

type EditTestModalProps = {
    question: TestQuestion,
    setQuestion: (question: TestQuestion) => void,
    setShowModal: (showModal: boolean) => void,
    saveQuestion: (question: TestQuestion, isEdit: boolean) => void,
}

const EditTestModal = ({ question, setQuestion, setShowModal, saveQuestion }: EditTestModalProps) => {
    const closeXRef = createRef<HTMLButtonElement>();
    const closeButtonRef = createRef<HTMLButtonElement>();

    const [ isError, setIsError ] = useState(false);

    const [ testQuestionFormDirtyState, setTestQuestionFormDirtyState ] = useState({
        questionTextHasBeenTouched: false,
        answerAHasBeenTouched: false,
        answerBHasBeenTouched: false,
        answerCHasBeenTouched: false,
        answerDHasBeenTouched: false,
    } as TestQuestionFormDirtyState);

    const onSaveClicked = () => {
        saveQuestion(question, true);
        setShowModal(false);
    };

    const onCancelClicked = () => {
        setShowModal(false);
    };

    const isSaveDisabled = () => {
        return isError;
    };

    const onTabLastElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            closeXRef.current?.focus();
        }
    };

    const onShiftTabFirstElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            closeButtonRef.current?.focus();
        }
    };

    return (
        <div className='c-els-modal c-els-modal--secondary u-els-padding-2x u-app-z-index--modal'>
            <div className='c-els-modal__window u-els-width-1o1 u-els-padding-2x u-els-display-flex o-els-flex-layout--column modal-window'>
                <div className='u-els-margin-bottom u-els-padding-top u-els-padding-right u-els-padding-left'>
                    <button className='c-els-modal__close u-els-anchorize' onClick={onCancelClicked} onKeyDown={event => onShiftTabFirstElement(event)}
                        ref={closeXRef} data-testid='close_x'>
                        <CloseModalIcon />
                    </button>
                    <div className="u-els-font-size-intro">Edit test question</div>
                </div>
                <div className='u-els-padding-left u-els-padding-right modal-scroll-container'>
                    <TestQuestionForm innerElementId='edit' question={question} setQuestion={setQuestion} autoFocus={true}
                        dirtyState={testQuestionFormDirtyState} setDirtyState={setTestQuestionFormDirtyState}
                        setIsError={setIsError}></TestQuestionForm>
                </div>
                <div className='u-els-margin-top-1x u-els-padding-bottom u-els-padding-left u-els-padding-right'>
                    <button className='c-els-button u-els-margin-right' onClick={onSaveClicked} disabled={isSaveDisabled()}>Save test question</button>
                    <button className='c-els-button c-els-button--secondary' onClick={onCancelClicked} ref={closeButtonRef}
                        onKeyDown={event => onTabLastElement(event)}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default EditTestModal;