import React from 'react';

// @ts-ignore
import { Icon } from '@els/els-react--icon';
// @ts-ignore
import { Flyout } from '@els/els-react--flyout';

import './InfoTooltipIcon.scss';

type InfoTooltipIconProps = {
    tooltipText: string,
}

const InfoTooltipIcon = ({tooltipText}: InfoTooltipIconProps) => {
    return (
        <Flyout
            flyout={tooltipText}
            trigger='hover'
            placement='top-start'
            className='tooltip-span u-els-font-size-base'
            id='tooltip'>
            <button className='u-els-anchorize c-els-link c-els-link--with-icon' data-testid='toast-icon-button'>
                <Icon
                    sprite='Information'
                    id='TooltipIcon'
                    isVisible={true}
                    color='secondary'
                    a11y={{ name: 'Tooltip', description: 'Displays information on hover'}}>
                </Icon>
            </button>
        </Flyout>
    );
};

export default InfoTooltipIcon;