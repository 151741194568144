import React, { Fragment, useContext } from 'react';
import SkillContext from '../../../Context/Skill/SkillContext';
import HeaderNavLink from '../HeaderNavLink/HeaderNavLink';

import './UploaderHeader.scss';

const UploaderHeader = () => {
    const { skill } = useContext(SkillContext);

    return (
        <Fragment>
            {skill && (
                <div>
                    <div className='header-skill-title u-els-padding-left-2x u-els-padding-left-2x u-els-padding-top-1x1o2 u-els-padding-bottom-1x1o2'>
                        <h3 className='header-skill-title-text u-els-margin-left-1x u-els-margin-right-1x'>{skill.skillName}</h3>
                    </div>
                    <div className='u-els-margin-left-2x u-els-margin-right-2x'>
                        <nav role='navigation'>
                            <ul className="c-els-nav-group u-els-padding-top-1x1o2">
                                <li className={'c-els-nav-group__item u-els-margin-bottom-1o2'}>
                                    <HeaderNavLink linkText='General' linkRoute='general'></HeaderNavLink>
                                </li>
                                <li className={'c-els-nav-group__item u-els-margin-bottom-1o2'}>
                                    <HeaderNavLink linkText='Notes' linkRoute='notes'></HeaderNavLink>
                                </li>
                                <li className={'c-els-nav-group__item u-els-margin-bottom-1o2'}>
                                    <HeaderNavLink linkText='Quick sheet' linkRoute='quicksheet'></HeaderNavLink>
                                </li>
                                <li className={'c-els-nav-group__item u-els-margin-bottom-1o2'}>
                                    <HeaderNavLink linkText='Extended text' linkRoute='extendedtext'></HeaderNavLink>
                                </li>
                                <li className={'c-els-nav-group__item u-els-margin-bottom-1o2'}>
                                    <HeaderNavLink linkText='Supplies' linkRoute='supplies'></HeaderNavLink>
                                </li>
                                <li className={'c-els-nav-group__item u-els-margin-bottom-1o2'}>
                                    <HeaderNavLink linkText='Videos' linkRoute='videos'></HeaderNavLink>
                                </li>
                                <li className={'c-els-nav-group__item u-els-margin-bottom-1o2'}>
                                    <HeaderNavLink linkText='Illustrations' linkRoute='illustrations'></HeaderNavLink>
                                </li>
                                <li className={'c-els-nav-group__item u-els-margin-bottom-1o2'}>
                                    <HeaderNavLink linkText='Test' linkRoute='test'></HeaderNavLink>
                                </li>
                                <li className={'c-els-nav-group__item u-els-margin-bottom-1o2'}>
                                    <HeaderNavLink linkText='Checklist' linkRoute='checklist'></HeaderNavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default UploaderHeader;
