import React, { Fragment } from 'react';
import { EditableSkillsContentType } from '../../../Context/Skill/SkillState';
import SkillContentEditor from '../../Layout/SkillContentEditor/SkillContentEditor';

const SkillExtendedTextPage = () => {

    const contentName = 'Extended text';
    const placeholderText = 'Add extended text here';
    const contentType = EditableSkillsContentType.ExtendedText;
    
    return (
        <Fragment>
            <SkillContentEditor contentName={contentName} placeholderText={placeholderText} contentType={contentType}></SkillContentEditor>
        </Fragment>
    );
};

export default SkillExtendedTextPage;