import { createContext } from 'react';
import { ButtonType } from '../Skill/SkillState';
import { ToastMessage } from './AlertState';

export type AlertContextType = {
    alertMessage?: string,
    buttonType?: ButtonType,
    toastMessages: ToastMessage[],
    nextToastId: number,
    setAlert: (message: string, buttonType?: ButtonType) => void,
    clearAlert: () => void
    addToastMessage: (toastMessage: ToastMessage) => void,
    clearToastMessage: (index: number) => void,
}

const AlertContext = createContext({} as AlertContextType);

export default AlertContext;