import React, { Fragment, useContext, useEffect, useState } from 'react';
import SkillContext from '../../../Context/Skill/SkillContext';
import CustomSkillCard from '../../Layout/CustomSkillCard/CustomSkillCard';
import QuestionList from '../../Layout/QuestionList/QuestionList';
import { QuestionType, TestQuestion } from '../../../Models/TestQuestion';
import { TestAnswer } from '../../../Models/TestAnswer';
import EditTestModal from '../../Layout/Modals/EditTestModal/EditTestModal';
import TestQuestionForm, { TestQuestionFormDirtyState } from '../../Layout/TestQuestionForm/TestQuestionForm';
import VersionCommentField from '../../Layout/VersionCommentField/VersionCommentField';
import AlertContext from '../../../Context/Alert/AlertContext';
import { ToastMessage, ToastType } from '../../../Context/Alert/AlertState';
import ConfirmModal from '../../Layout/Modals/ConfirmModal/ConfirmModal';

import './SkillTestPage.scss';

const SkillTestPage = () => {
    const { skill, hasBeenModified, saveSkillTest, openUnsavedChanges, setHasBeenModified } = useContext(SkillContext);
    const { addToastMessage } = useContext(AlertContext);

    const [ commentText, setCommentText ] = useState('');
    const [ isCommentError, setIsCommentError ] = useState(false);
    const [ testQuestions, setTestQuestions ] = useState(skill.test.questions);
    const [ currentlyEdittingIndex, setCurrentlyEdittingIndex ] = useState(0);
    const [ showEditQuestionModal, setShowEditQuestionModal ] = useState(false);
    const [ isError, setIsError ] = useState(false);
    const [ currentEditQuestion, setCurrentEditQuestion ] = useState({} as TestQuestion);
    const [ confirmModalIsOpen, setConfirmModalIsOpen ] = useState(false);
    const [ indexToRemove, setIndexToRemove ] = useState(0);

    const [ currentQuestion, setCurrentQuestion ] = useState({
        questionType: QuestionType.multipleChoice,
        number: testQuestions.length + 1,
        correctAnswer: 'A',
        text: '',
        answerOptions: [
            { id: 0, label: 'A', text: '', rationale: '' } as TestAnswer,
            { id: 1, label: 'B', text: '', rationale: '' } as TestAnswer,
            { id: 2, label: 'C', text: '', rationale: '' } as TestAnswer,
            { id: 3, label: 'D', text: '', rationale: '' } as TestAnswer,
        ] } as TestQuestion);

    const [ testQuestionFormDirtyState, setTestQuestionFormDirtyState ] = useState({
        questionTextHasBeenTouched: false,
        answerAHasBeenTouched: false,
        answerBHasBeenTouched: false,
        answerCHasBeenTouched: false,
        answerDHasBeenTouched: false,
    } as TestQuestionFormDirtyState);

    useEffect(() => {
        setTestQuestions(skill.test.questions);
        setCommentText('');
    }, [skill]);

    useEffect(() => {
        updateHasBeenModified();
    }, [testQuestions, commentText]);

    const commentCharacterLimit = 512;

    const updateHasBeenModified = () => {
        setHasBeenModified(testQuestions != skill.test.questions || commentText.length > 0);
    };

    const onSaveClicked = () => {
        saveSkillTest(testQuestions, commentText);
    };

    const onCancelClicked = () => {
        openUnsavedChanges('');
    };

    const onEditClicked = (question: TestQuestion, index: number) => {
        setCurrentEditQuestion(question);
        setCurrentlyEdittingIndex(index);
        setShowEditQuestionModal(true);
    };

    const onSaveQuestionClicked = (newQuestion: TestQuestion, isEdit: boolean) => {
        let updatedQuestions = [] as TestQuestion[];

        const updatingQuestion = { ...newQuestion, number: newQuestion.number <= 0 ? 1 : newQuestion.number } as TestQuestion;
        
        testQuestions.forEach((question, index) => {
            if (updatingQuestion.number == updatedQuestions.length + 1) {
                updatedQuestions = [...updatedQuestions, updatingQuestion];
            }
            
            if (index != currentlyEdittingIndex || !isEdit) {
                updatedQuestions = [...updatedQuestions, {...question, number: updatedQuestions.length + 1} as TestQuestion];
            }
        });

        if (updatingQuestion.number >= updatedQuestions.length + 1) {
            updatedQuestions = [...updatedQuestions, { ...updatingQuestion, number: updatedQuestions.length + 1 }];
        }

        setTestQuestions(updatedQuestions);

        if (!isEdit) {
            setCurrentQuestion({
                questionType: QuestionType.multipleChoice,
                number: updatedQuestions.length + 1,
                correctAnswer: 'A',
                text: '',
                answerOptions: [
                    { id: 0, label: 'A', text: '', rationale: '' } as TestAnswer,
                    { id: 1, label: 'B', text: '', rationale: '' } as TestAnswer,
                    { id: 2, label: 'C', text: '', rationale: '' } as TestAnswer,
                    { id: 3, label: 'D', text: '', rationale: '' } as TestAnswer,
                ] } as TestQuestion);
    
            setTestQuestionFormDirtyState({
                questionTextHasBeenTouched: false,
                answerAHasBeenTouched: false,
                answerBHasBeenTouched: false,
                answerCHasBeenTouched: false,
                answerDHasBeenTouched: false
            } as TestQuestionFormDirtyState);

            addToastMessage({
                type: ToastType.success,
                messageOne: 'Test question has been added successfully.',
            } as ToastMessage);
        }
        else {
            addToastMessage({
                type: ToastType.success,
                messageOne: 'Test question has been edited successfully.',
            } as ToastMessage);
        }
    };

    const openConfirmModal = (index: number) => {
        setIndexToRemove(index);
        setConfirmModalIsOpen(true);
    };

    const onConfirmRemove = () => {
        let updatedQuestions = [] as TestQuestion[];

        testQuestions.forEach((question, arrayIndex) => {
            if (arrayIndex != indexToRemove) {
                updatedQuestions = [...updatedQuestions, {...question, number: updatedQuestions.length + 1} as TestQuestion];
            }
        });

        setTestQuestions(updatedQuestions);

        const currentQuestionNumber = currentQuestion.number > updatedQuestions.length + 1 ? updatedQuestions.length + 1 : currentQuestion.number;

        setCurrentQuestion({
            id: currentQuestion.id,
            questionType: currentQuestion.questionType,
            number: currentQuestionNumber,
            correctAnswer: currentQuestion.correctAnswer,
            text: currentQuestion.text,
            answerOptions: currentQuestion.answerOptions
        } as TestQuestion);

        addToastMessage({
            type: ToastType.success,
            messageOne: 'Test question has been removed successfully.',
        } as ToastMessage);
    };

    const isSaveDisabled = () => {
        return isCommentError || !hasBeenModified;
    };

    const formIsDirty = () => {
        return testQuestionFormDirtyState.questionTextHasBeenTouched || testQuestionFormDirtyState.answerAHasBeenTouched ||
                testQuestionFormDirtyState.answerBHasBeenTouched || testQuestionFormDirtyState.answerCHasBeenTouched ||
                testQuestionFormDirtyState.answerDHasBeenTouched || testQuestionFormDirtyState.questionTextHasBeenTouched;
    };

    const formHasBeenPopulated = () => {
        return testQuestionFormDirtyState.questionTextHasBeenTouched && testQuestionFormDirtyState.answerAHasBeenTouched &&
                testQuestionFormDirtyState.answerBHasBeenTouched && testQuestionFormDirtyState.answerCHasBeenTouched &&
                testQuestionFormDirtyState.answerDHasBeenTouched && testQuestionFormDirtyState.questionTextHasBeenTouched;
    };

    const isCancelDisabled = () => {
        return !hasBeenModified && !formIsDirty();
    };

    const isSaveQuestionDisabled = () => {
        return isError || !formHasBeenPopulated();
    };
    
    return (
        <Fragment>
            {confirmModalIsOpen &&
            <ConfirmModal confirmModalHeading='Remove Question' confirmModalMessage='Are you sure you want to remove this question from the test?'
                confirmModalFunction={onConfirmRemove} setConfirmModalIsOpen={setConfirmModalIsOpen}/>
            }
            {showEditQuestionModal &&
            <EditTestModal question={currentEditQuestion} setShowModal={setShowEditQuestionModal} saveQuestion={onSaveQuestionClicked}
                setQuestion={setCurrentEditQuestion}></EditTestModal>
            }
            <div className={`u-els-margin-top-1x u-els-padding-left-2x u-els-padding-right-2x scroll-container o-els-flex-layout__item--grow
                ${ showEditQuestionModal ? ' scroll-lock' : '' }`}>
                <span className="u-els-font-size-intro">Test</span>
                <CustomSkillCard></CustomSkillCard>
                <QuestionList questions={testQuestions} onEditClicked={onEditClicked} openConfirmModal={openConfirmModal}></QuestionList>
                <div className='u-els-margin-top-4x'>
                    <div className="u-els-font-size-intro u-els-margin-bottom">Add test questions</div>
                    <TestQuestionForm innerElementId='add' question={currentQuestion} setQuestion={setCurrentQuestion}
                        dirtyState={testQuestionFormDirtyState} setDirtyState={setTestQuestionFormDirtyState} numberOfQuestions={testQuestions.length}
                        setIsError={setIsError}></TestQuestionForm>
                    <button className="c-els-button u-els-margin-top-2x" data-testid='save-question-button'
                        onClick={() => onSaveQuestionClicked(currentQuestion, false)} disabled={isSaveQuestionDisabled()}>Add test question
                    </button>
                </div>
                <div className="u-els-margin-top-4x">
                    <VersionCommentField commentText={commentText} setCommentText={setCommentText} setIsCommentError={setIsCommentError}
                        commentCharacterLimit={commentCharacterLimit}></VersionCommentField>
                </div>
                <div className="u-els-margin-top-1x1o2 u-els-margin-bottom">
                    <button className="c-els-button" onClick={onSaveClicked} disabled={isSaveDisabled()}>Save</button>
                    <button className="c-els-button c-els-button--secondary u-els-margin-left" onClick={onCancelClicked} disabled={isCancelDisabled()}>Cancel</button>
                </div>
            </div>
        </Fragment>
    );
};

export default SkillTestPage;