import { UserManager, UserManagerSettings } from 'oidc-client';
import { AppConfig } from '../../Utilities/config';

const BASE_APP_URL = window.location.origin;
const IDENTITY_PROVIDER_CALLBACK = `${BASE_APP_URL}/authentication/callback`;
export const LOGOUT_CALLBACK = `${BASE_APP_URL}/authentication/logout-callback`;

export const createUserManager = (config: AppConfig) => {
    const oidcConfig = getOidcConfig(config);
    const appUserManager = new UserManager(oidcConfig);

    appUserManager.events.addAccessTokenExpiring(() => {
        console.log('token expiring...');
    });
    appUserManager.events.addAccessTokenExpired(() => {
        console.log('token expired...');
    });
    appUserManager.events.addSilentRenewError((e) => {
        console.log('silent renew error', e.message);
    });
    appUserManager.events.addUserLoaded(() => {
        console.log('user loaded');
    });
    appUserManager.events.addUserUnloaded(() => {
        console.log('user cleared');
    });

    return appUserManager;
};

const getOidcConfig = (config: AppConfig): UserManagerSettings => {
    const { appName, authority } = config;

    return {
        authority: authority,
        client_id: appName,
        redirect_uri: IDENTITY_PROVIDER_CALLBACK,
        post_logout_redirect_uri: LOGOUT_CALLBACK,
        response_type: 'code',
        scope: 'openid profile tenant skills-content-services.client_write',
        filterProtocolClaims: false,
        loadUserInfo: false,
        accessTokenExpiringNotificationTime: 5,
        metadata: {
            issuer: authority,
            authorization_endpoint: authority + '/connect/authorize',
            token_endpoint: authority + '/connect/token',
            userinfo_endpoint: authority + '/connect/userinfo',
            end_session_endpoint: authority + '/connect/endsession',
            jwks_uri: authority + '/.well-known/openid-configuration/jwks'
        },
    };
};
