import React, { useContext } from 'react';
import AlertContext from '../../../Context/Alert/AlertContext';

export const ErrorPage = () => {
    const { alertMessage } = useContext(AlertContext);

    const displayMessage = alertMessage ?? 'There has been an error.';

    return (
        <div>
            <span>{displayMessage}</span>
        </div>
    );
};
