import React, { useEffect, ChangeEvent, useState } from 'react';
import TextFieldInputError from '../TextFieldInputError/TextFieldInputError';
import { RegexPatterns } from '../../../Constants/Constants';

type VersionCommentFieldProps = {
    commentText: string,
    commentCharacterLimit: number,
    setCommentText: (commentText: string) => void,
    setIsCommentError: (isCommentError: boolean) => void,
}

const VersionCommentField = ({ commentText, commentCharacterLimit, setCommentText, setIsCommentError }: VersionCommentFieldProps) => {

    const [ isCommentLengthError, setIsCommentLengthError ] = useState(false);
    const [ isCommentRegexError, setIsCommentRegexError ] = useState(false);
    
    useEffect(() => {
        setIsCommentError(isCommentLengthError || isCommentRegexError);
    }, [isCommentLengthError, isCommentRegexError]);

    const onChangeCommentText = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setCommentText(event?.currentTarget.value);
    };

    return (
        <div className="c-els-field--large">
            <label className="c-els-field__label" data-testid='version_comments'>
                <span className="c-els-field__label-text skill-content-editor__title u-els-font-size-body-large">Version history comments:</span>
                <div className="u-els-font-size-caption u-els-margin-bottom-1o2">These appear on the version history table to explain what has changed.</div>
                <div>
                    <textarea id="commentstext" className="c-els-field__input" name="input-type-text" placeholder="Add comments here" data-testid='comment-field'
                        spellCheck={false} onChange={onChangeCommentText}/>
                    <TextFieldInputError text={commentText} hasBeenTouched={true} characterRegex={RegexPatterns.versionCommentRegex}
                        isLengthError={isCommentLengthError} setIsLengthError={setIsCommentLengthError}
                        isRegexError={isCommentRegexError} setIsRegexError={setIsCommentRegexError}
                        lengthLimit={commentCharacterLimit} fieldName='version comment'></TextFieldInputError>
                </div>
            </label>
        </div>
    );
};

export default VersionCommentField;