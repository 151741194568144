import React from 'react';

const EditIcon = () => {
    return (
        <svg className='o-els-icon-svg o-els-icon-svg--1x1o2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 128" height="24" width="21"><path d="M33.28 87.69c.98.98 2.26 1.47 3.54 1.47s2.56-.49 3.54-1.47l36.4-36.4-7.08-7.07-36.4 36.4c-.98.98-1.46 2.26-1.46 3.54s.48 2.56 1.46 3.53m10.88 7.52l-20.58 6.93-4.74-4.73 6.94-20.58 38.24-38.26-7.06-7.07-39.92 39.91L.52 120.46l49.06-16.52 39.9-39.92-7.06-7.07-38.26 38.26m55.94-55.94l-4.96 4.95-18.38-18.38 4.94-4.95c.56-.56 1.32-.87 2.12-.87a3 3 0 0 1 2.14.87l14.14 14.14c.56.56.86 1.32.86 2.12 0 .81-.3 1.56-.86 2.12zm7.06-11.31L93.02 13.82c-2.44-2.45-5.72-3.8-9.2-3.8-3.46 0-6.74 1.35-9.18 3.8L62.62 25.84l32.52 32.53 12.02-12.02c2.46-2.45 3.8-5.72 3.8-9.2s-1.34-6.74-3.8-9.19"/>
            <title>Edit</title>
        </svg>
    );
};

export default EditIcon;