import React from 'react';

import './TagCheckboxItem.scss';

type TagCheckboxItemProps = {
    index: number,
    listLength: number,
    text: string,
    isChecked: boolean,
    onChange: (...args: any[]) => void,
}

const TagCheckboxItem = ({index, listLength, text, isChecked, onChange}: TagCheckboxItemProps) => {
    return (
        <div className={`c-els-field c-els-field--checkbox ${index != listLength - 1 ? 'u-els-margin-bottom' : ''}`}
            key={text}>
            <label className="c-els-field__label" htmlFor={text}>
                <input type="checkbox" className="c-els-field__input" checked={isChecked}
                    onChange={() => onChange()} id={text}/>
                <span className="c-els-field__label-text checkbox-text">
                    <span className="c-els-field__switch">
                    </span>{text}</span>
            </label>
        </div>
    );
};

export default TagCheckboxItem;