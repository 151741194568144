import { UploadStatus } from '../Context/Skill/SkillState';
import { Subtitle } from './Subtitle';

export enum MediaType {
    illustrations = 'illustrations',
    videos = 'videos',
}

export interface MediaViewModel {
    key: string,
    description: string,
    fileName: string,
    commentText: string,
    isActive: boolean,
    itemOrder: number,
    previewUrl: string,
    mediaType: MediaType,
    uploadStatus: UploadStatus,
    isCustom: boolean,
    canPreviewInUploader: boolean,
    closedCaptionsUrl?: string,
    subtitles?: Subtitle[],
    animationId?: string,
}