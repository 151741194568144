import React, { createRef, KeyboardEvent, useContext, useEffect, useState } from 'react';
import CloseModalIcon from '../../Icons/CloseModalIcon';
import TagsContext from '../../../../Context/Tags/TagsContext';
import { TagType } from '../../../../Context/Tags/TagsState';
import TagCheckboxItem from '../../TagCheckboxItem/TagCheckboxItem';
import { Category } from '../../../../Models/Category';
import { Specialty } from '../../../../Models/Specialty';

// @ts-ignore
import { Icon } from '@els/els-react--icon';

import './ManageTagsModal.scss';

type ManageTagsModalProps = {
    customCategories: Category[],
    customSpecialties: Specialty[],
    skillCategories: Category[],
    skillSpecialties: Specialty[],
    skillKeyId: string,
}

const ManageTagsModal = ({ customCategories, customSpecialties, skillCategories, skillSpecialties, skillKeyId }: ManageTagsModalProps) => {
    const { isLoadingTags, managingTagType, closeManageTags, deleteCustomCategories, deleteCustomSpecialties } = useContext(TagsContext);

    const [ isAnyCheckedTagOnCurrentSkill, setIsAnyCheckedTagOnCurrentSkill ] = useState(false);

    const [tagsCheckedState, setTagsCheckedState] = useState(() => {
        if (managingTagType == TagType.category) {
            return customCategories?.map(() => { return false; });
        }
        else {
            return customSpecialties?.map(() => { return false; });
        }
    });

    useEffect(() => {
        let isOnSkill = false;

        if (managingTagType == TagType.category) {
            customCategories?.filter((category, index) => tagsCheckedState[index])
                .forEach(category => {
                    isOnSkill = skillCategories.find(skillCategory => skillCategory.itemId == category.itemId) ? true : isOnSkill;
                });

            setIsAnyCheckedTagOnCurrentSkill(isOnSkill);
        }

        if (managingTagType == TagType.specialty) {
            customSpecialties?.filter((specialty, index) => tagsCheckedState[index])
                .forEach(specialty => {
                    isOnSkill = skillSpecialties.find(skillSpecialty => skillSpecialty.itemId == specialty.itemId) ? true : isOnSkill;
                });

            setIsAnyCheckedTagOnCurrentSkill(isOnSkill);
        }
    }, [tagsCheckedState]);

    useEffect(() => {
        if (!isLoadingTags) {
            if (managingTagType == TagType.category && customCategories.length == 0 ||
                managingTagType == TagType.specialty && customSpecialties.length == 0) {
                closeManageTags();
            }
    
            onClickReset();
        }
    }, [customCategories, customCategories]);

    const closeXRef = createRef<HTMLButtonElement>();
    const cancelButtonRef = createRef<HTMLButtonElement>();

    const onShiftTabFirstElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            cancelButtonRef.current?.focus();
        }
    };

    const onTabLastElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            closeXRef.current?.focus();
        }
    };

    const getManageTagsHeadingText = () => {
        return `Manage custom ${managingTagType == TagType.category ? 'categories' : 'specialties'}`;
    };

    const getManageTagsMessageText = () => {
        const tagTypeText = managingTagType == TagType.category ? 'categories' : 'specialties';

        return `Deleting custom ${tagTypeText} here will remove them from all skills they have been tagged in throughout Clinical Skills.  In order to delete the custom ${tagTypeText}, click on the checkboxes and choose Delete.`;
    };

    const getWarningText = () => {
        return ` a ${managingTagType?.toLocaleLowerCase()} selected is used on the current skill.  If deleted now, any unsaved changes to the general tab will be lost.`;
    };

    const onClickTag = (changingIndex: number) => {
        setTagsCheckedState(tagsCheckedState?.map((tag, index) => { return index == changingIndex ? !tag : tag; }));
    };

    const resetAndDeleteDisabled = () => {
        return !tagsCheckedState?.includes(true);
    };

    const onClickDelete = () => {
        if (managingTagType ==  TagType.category) {
            const categoryIdsToDelete = customCategories
                .filter((category, index) => tagsCheckedState[index])
                .map(category => { return category.itemId; });

            deleteCustomCategories(categoryIdsToDelete, skillKeyId, isAnyCheckedTagOnCurrentSkill);
            
        }
        else {
            const specialtyIdsToDelete = customSpecialties
                .filter((specialty, index) => tagsCheckedState[index])
                .map(specialty => { return specialty.itemId; });
            
            deleteCustomSpecialties(specialtyIdsToDelete, skillKeyId, isAnyCheckedTagOnCurrentSkill);
        }
    };

    const onClickReset = () => {
        setTagsCheckedState(tagsCheckedState?.map(() => { return false; }));
    };

    if (isLoadingTags) {
        return (
            <div className='c-els-modal c-els-modal--secondary u-els-padding-2x'>
                <div className='c-els-modal__window u-els-width-1o2 u-els-padding-2x saving-tags-modal'>
                    <div className="c-els-loader__wrapper">
                        <div className="c-els-loader__loading-bar">
                        </div>
                        <div className="c-els-loader__loading-bar">
                        </div>
                        <div className="c-els-loader__loading-bar">
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    else {
        return (
            <div className='c-els-modal c-els-modal--secondary u-els-padding-2x manage-tags-modal u-app-z-index--modal'>
                <div className='c-els-modal__window u-els-width-1o2 u-els-padding-2x manage-tags-modal-window'>
                    <button className='c-els-modal__close u-els-anchorize' onClick={closeManageTags} onKeyDown={event => onShiftTabFirstElement(event)}
                        ref={closeXRef} data-testid='close_x'>
                        <CloseModalIcon />
                    </button>
                    <h3 className='u-els-margin-bottom-1x1o2' data-testid='manage-modal-heading'>{getManageTagsHeadingText()}</h3>
                    <div className='u-els-margin-bottom-1x1o2'>{getManageTagsMessageText()}</div>
                    <div className='c-els-card u-els-display-flex o-els-flex-layout--column tags-scroll-box'>
                        <div className='c-els-card__content'>
                            {(managingTagType == TagType.category) &&
                            customCategories?.map((category, index) => (
                                <TagCheckboxItem index={index} listLength={customCategories.length} text={category.itemData}
                                    key={category.itemData} isChecked={tagsCheckedState[index] ?? false}
                                    onChange={() => onClickTag(index)}></TagCheckboxItem>
                            ))}
                            {(managingTagType == TagType.specialty) &&
                            customSpecialties?.map((specialty, index) => (
                                <TagCheckboxItem index={index} listLength={customSpecialties.length} text={specialty.itemData}
                                    key={specialty.itemData} isChecked={tagsCheckedState[index] ?? false}
                                    onChange={() => onClickTag(index)}></TagCheckboxItem>
                            ))}
                        </div>
                    </div>
                    { isAnyCheckedTagOnCurrentSkill &&
                    <div className='o-els-flex-layout o-els-flex-layout--left u-els-margin-top'>
                        <Icon
                            className="react-icon-fix"
                            sprite="AlertSolidCircle"
                            size='m'
                            id="CategorySpecialtyOkay"
                            isVisible={true}
                            color="negative"
                            textAlignment="bottom"
                            a11y={{ name: 'Okay', description: 'Okay to edit'}}>
                        </Icon>
                        <div className='u-els-margin-left-1o4'>
                            <strong>Warning,</strong>
                            <span data-testid='warning_span'>{getWarningText()}</span>
                        </div>
                    </div>
                    }
                    <div className='o-els-flex-layout o-els-flex-layout--left o-els-flex-layout--wrap u-els-margin-top'>
                        <button className='c-els-button c-els-button--warn u-els-margin-right-1x'
                            onClick={onClickDelete} disabled={resetAndDeleteDisabled()}>Delete</button>
                        <button className='c-els-button c-els-button--secondary u-els-margin-right-1x'
                            onClick={onClickReset} disabled={resetAndDeleteDisabled()}>Reset</button>
                        <div className='o-els-flex-layout__item--grow'></div>
                        <button className='c-els-button c-els-button--secondary' onClick={closeManageTags} ref={cancelButtonRef} onKeyDown={event => onTabLastElement(event)}
                            autoFocus={true} data-testid='close_button'>Close</button>
                    </div>
                </div>
            </div>
        );
    }
};

export default ManageTagsModal;