import React, { useContext, useEffect } from 'react';
import AlertContext from '../../Context/Alert/AlertContext';
import AuthenticationContext from '../../Context/Authentication/AuthenticationContext';

// @ts-ignore
import { Loader } from '@els/els-react--loader';


export const Callback = (props: any) => {
    const { processLoginCallback, user, userManager } = useContext(AuthenticationContext);

    const { setAlert } = useContext(AlertContext);

    useEffect(() => {
        if (userManager && !user) {
            processLoginCallback(window.location.href)
                .then(path => props.history.replace(path))
                .catch((error) => {
                    console.error(error);
                    setAlert('Login authentication failed.');
                    props.history.replace('/error');
                });
        }
    }, [userManager, user]);

    return (
        <Loader />
    );
};
