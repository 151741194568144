import React, { Fragment, useContext, useEffect, useState } from 'react';
import SkillContext from '../../../Context/Skill/SkillContext';
import { SkillsContentInputModel } from '../../../Models/InputModels/SkillsContentInputModel';
import { EditableSkillsContentType } from '../../../Context/Skill/SkillState';
import CustomSkillCard from '../CustomSkillCard/CustomSkillCard';
import CKEditorComponent from '../CKEditorComponent/CKEditorComponent';
import VersionCommentField from '../VersionCommentField/VersionCommentField';

type SkillContentEditorProps = {
    contentName: string,
    placeholderText: string,
    contentType: number,
};

const SkillContentEditor = ({contentName, placeholderText, contentType}: SkillContentEditorProps) => {
    const { skill, hasBeenModified, saveSkillContent, openUnsavedChanges, setHasBeenModified } = useContext(SkillContext);

    const [ contentText, setContentText ] = useState(() => {
        switch(contentType) {
        case EditableSkillsContentType.Supplies:
            return skill.supplies.itemData;
        case EditableSkillsContentType.QuickSheet:
            return skill.quickSheet.itemData;
        case EditableSkillsContentType.ExtendedText:
            return skill.extendedText.itemData;
        default:
            return '';
        }
    });

    const [ commentText, setCommentText ] = useState('');
    const [ isCommentError, setIsCommentError ] = useState(false);

    useEffect(() => {
        updateHasBeenModified();
    }, [contentText, commentText]);

    const commentCharacterLimit = 512;

    const onSaveClicked = () => {
        const input = { itemData: contentText } as SkillsContentInputModel;
        saveSkillContent(input, contentType, commentText);
    };

    const onCancelClicked = () => {
        openUnsavedChanges('');
    };

    const onChangeContentText = (newContentText: string) => {
        setContentText(newContentText);
    };

    const isOkDisabled = () => {
        return isCommentError || !hasBeenModified;
    };

    const isCancelDisabled = () => {
        return !hasBeenModified;
    };

    const updateHasBeenModified = () => {
        switch(contentType) {
        case EditableSkillsContentType.Supplies:
            setHasBeenModified(contentText !== skill.supplies.itemData || commentText.length != 0);
            break;
        case EditableSkillsContentType.QuickSheet:
            setHasBeenModified(contentText !== skill.quickSheet.itemData || commentText.length != 0);
            break;
        case EditableSkillsContentType.ExtendedText:
            setHasBeenModified(contentText !== skill.extendedText.itemData || commentText.length != 0);
            break;
        }
    };
    
    return (
        <Fragment>
            <div className="u-els-margin-top-1x u-els-padding-left-2x u-els-padding-right-2x scroll-container o-els-flex-layout__item--grow">
                <span className="u-els-font-size-intro">{contentName}</span>
                <CustomSkillCard></CustomSkillCard>
                <label className="c-els-field__label u-els-margin-bottom-2x">
                    <span className="c-els-field__label-text skill-content-editor__title u-els-font-size-body-large">{contentName}:</span>
                    <CKEditorComponent initData={contentText} editorplaceholder={placeholderText} onChange={onChangeContentText}></CKEditorComponent>
                </label>
                <VersionCommentField commentText={commentText} setCommentText={setCommentText} setIsCommentError={setIsCommentError}
                    commentCharacterLimit={commentCharacterLimit}></VersionCommentField>
                <div className="u-els-margin-top-1x1o2 u-els-margin-bottom">
                    <button className="c-els-button" onClick={onSaveClicked} disabled={isOkDisabled()}>Save</button>
                    <button className="c-els-button c-els-button--secondary u-els-margin-left" onClick={onCancelClicked} disabled={isCancelDisabled()}>Cancel</button>
                </div>
            </div>
        </Fragment>
    );
};

export default SkillContentEditor;