import { CKEditor, CKEditorEventPayload, CKEditorInstance } from 'ckeditor4-react';
import React, { Fragment, useEffect, useState } from 'react';

import './CKEditorComponent.scss';

export type CKEditorComponentProps = {
    initData: string,
    editorplaceholder: string,
    shouldTriggerClear?: boolean,
    onChange: (newContentText: string) => void,
    setShouldTriggerClear?: (newValue: boolean) => void,
};

const CKEditorComponent = ({ initData, editorplaceholder, shouldTriggerClear, onChange, setShouldTriggerClear }: CKEditorComponentProps) => {
    const [ ckEditorIsLoading, setCkEditorIsLoading ] = useState(true);

    const [ editor, setEditor ] = useState({} as CKEditorInstance);
    const [ ckEditorText, setCkEditorText ] = useState(initData);
    const [ ckEditorTextHasBeenInitialized, setCkEditorTextHasBeenInitialized ] = useState(false);

    useEffect(() => {
        if (shouldTriggerClear && setShouldTriggerClear != null) {
            setShouldTriggerClear(false);
        }
        else if (ckEditorTextHasBeenInitialized) {
            onChange(ckEditorText);
        }
        else {
            setCkEditorTextHasBeenInitialized(true);
        }
    }, [ckEditorText]);

    useEffect(() => {
        if(shouldTriggerClear && setShouldTriggerClear != null) {
            editor.setData('');
        }
    }, [shouldTriggerClear]);
    
    const onAfterSetData = (event: CKEditorEventPayload<'afterSetData'>) => {
        setCkEditorIsLoading(false);
        setEditor(event.editor as CKEditorInstance);
    };

    const ckEditorOnChange = (event: CKEditorEventPayload<'change'>) => {
        setCkEditorText(event.editor.getData());
    };

    return (
        <Fragment>
            {ckEditorIsLoading &&
            <div className='ckeditor-skeleton'></div>
            }
            <CKEditor initData={ckEditorText} onChange={ckEditorOnChange} id="contenttext" onAfterSetData={onAfterSetData}
                config={{
                    extraPlugins: ['editorplaceholder', 'font', 'colorbutton', 'colordialog'],
                    font_names: 'Arial; Courier New; Garamond; Georgia; MS Sans Serif; Segoe UI; Tahoma; Times New Roman; Verdana;',
                    editorplaceholder: editorplaceholder,
                    toolbarGroups: [{
                        'name': 'basicstyles',
                        'groups': ['basicstyles']
                    },
                    {
                        'name': 'history',
                        'groups': ['undo', 'redo']
                    },
                    {
                        'name': 'links',
                        'groups': ['links']
                    },
                    {
                        'name': 'paragraph',
                        'groups': ['list', 'indent']
                    },
                    {
                        'name': 'document',
                        'groups': ['mode']
                    },
                    {
                        'name': 'insert',
                        'groups': ['insert']
                    },
                    {
                        'name': 'colors',
                        'groups': [ 'colors' ],
                    },
                    {
                        'name': 'styles',
                    }
                    ],
                    removeButtons: 'Anchor,Image,Blockquote,BGColor,Strike',
                    width: '100%',
                    height: 203,
                    resize_enabled: false,
                    colorButton_enableAutomatic: false,
                    allowedContent: true,
                    autoParagraph: false,
                    disableNativeSpellChecker: false
                }}>
            </CKEditor>
        </Fragment>
    );
};

export default CKEditorComponent;