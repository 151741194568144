import React, { Fragment, useContext, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import SkillContext from '../../../Context/Skill/SkillContext';

type HeaderNavLinkProps = {
    linkText: string,
    linkRoute: string,
}

const HeaderNavLink = ({ linkText, linkRoute }: HeaderNavLinkProps) => {
    const { skill, hasBeenModified, openUnsavedChanges } = useContext(SkillContext);

    const onClickNavigationTab = (event: MouseEvent, route: string) => {
        if (hasBeenModified) {
            event.nativeEvent.stopImmediatePropagation();
            event.preventDefault();
            openUnsavedChanges(route);
        }
    };

    const pathname = `/skill/${skill?.skillKeyId}/${linkRoute}`;

    return (
        <Fragment>
            <NavLink className="c-els-nav-group__link" activeClassName='c-els-nav-group__link--active'
                onClick={event => onClickNavigationTab(event, pathname)}
                to={{
                    pathname: pathname,
                    state: { skill: skill },
                }}>{linkText}</NavLink>
        </Fragment>
    );
};

export default HeaderNavLink;