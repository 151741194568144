import React, { useContext, useEffect } from 'react';
import { Route } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import SkillContext from '../../../Context/Skill/SkillContext';
import SkillVideosPage from '../../Pages/SkillVideosPage/SkillVideosPage';
import SkillIllustrationsPage from '../../Pages/SkillIllustrationsPage/SkillIllustrationsPage';
import UploaderHeader from '../../Layout/UploaderHeader/UploaderHeader';
import InfoEditModal from '../../Layout/Modals/InfoEditModal/InfoEditModal';
import VersionCommentModal from '../../Layout/Modals/VersionCommentModal/VersionCommentModal';
import UnsavedChangesModal from '../../Layout/Modals/UnsavedChangesModal/UnsavedChangesModal';
import VideoPreviewModal from '../../Layout/Modals/VideoPreviewModal/VideoPreviewModal';
import SkillSuppliesPage from '../../Pages/SkillSuppliesPage/SkillSuppliesPage';
import SkillExtendedTextPage from '../../Pages/SkillExtendedTextPage/SkillExtendedTextPage';
import SkillQuickSheetPage from '../../Pages/SkillQuickSheetPage/SkillQuickSheetPage';
import SkillNotesPage from '../../Pages/SkillNotesPage/SkillNotesPage';
import SkillTestPage from '../../Pages/SkillTestPage/SkillTestPage';
import SkillChecklistPage from '../../Pages/SkillChecklistPage/SkillChecklistPage';
import SkillGeneralPage from '../../Pages/SkillGeneralPage/SkillGeneralPage';
import TagsContext from '../../../Context/Tags/TagsContext';
import CreateTagModal from '../../Layout/Modals/CreateTagModal/CreateTagModal';

// @ts-ignore
import { Loader } from '@els/els-react--loader';

import './SkillRoute.scss';


const onUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = 'You have un-saved changes.';
};

const isEmbedded = (search: string) => {
    const queryParams = new URLSearchParams(search);

    return queryParams.get('isEmbedded') === 'true';
};

const SkillRoute = (props: any) => {
    const skillKeyId = props.match.params.id;
    const pathName = props.location.pathname;
    const { search } = useLocation();
    const { skill, loading, saving, hasBeenModified, infoEditIsOpen, versionCommentIsOpen, unsavedChangesIsOpen, previewState, getSkill } = useContext(SkillContext);
    const { creatingTagType } = useContext(TagsContext);

    useEffect(() => {
        if(!skill?.skillKeyId) {
            getSkill(skillKeyId);
        }
    }, [skill]);

    useEffect(() => {
        hasBeenModified
            ? window.addEventListener('beforeunload', onUnload)
            : window.removeEventListener('beforeunload', onUnload);
    }, [hasBeenModified]);

    if(!skill?.skillKeyId || loading) {
        return (
            <Loader></Loader>
        );
    }

    isEmbedded(search);

    return (
        <div className="app-container u-els-display-flex">
            {saving && <div className='saving-loader'><Loader isBlocking={true}></Loader></div>}
            {infoEditIsOpen && <InfoEditModal />}
            {versionCommentIsOpen && <VersionCommentModal />}
            {unsavedChangesIsOpen && <UnsavedChangesModal />}
            {previewState.previewIsOpen && <VideoPreviewModal />}
            {creatingTagType != undefined && <CreateTagModal />}
            {!isEmbedded(search) &&  <UploaderHeader />}
            <Route path={`/skill/${skillKeyId}/general`} component={SkillGeneralPage}></Route>
            <Route path={`/skill/${skillKeyId}/illustrations`} component={SkillIllustrationsPage}></Route>
            <Route path={`/skill/${skillKeyId}/videos`} component={SkillVideosPage}></Route>
            <Route path={`/skill/${skillKeyId}/supplies`} component={SkillSuppliesPage}></Route>
            <Route path={`/skill/${skillKeyId}/quickSheet`} component={SkillQuickSheetPage}></Route>
            <Route path={`/skill/${skillKeyId}/extendedText`} component={SkillExtendedTextPage}></Route>
            <Route path={`/skill/${skillKeyId}/notes`} component={SkillNotesPage}></Route>
            <Route path={`/skill/${skillKeyId}/test`} component={SkillTestPage}></Route>
            <Route path={`/skill/${skillKeyId}/checkList`} component={SkillChecklistPage}></Route>
            { pathName === `/skill/${skillKeyId}` && <Redirect to={`/skill/${skillKeyId}/general`} /> }
        </div>
    );
};

export default SkillRoute;
