import React from 'react';
import DeletePillIcon from '../Icons/DeletePillIcon';

import './TagPill.scss';

type TagPillProps = {
    name: string,
    isCustom: boolean,
    onRemove: () => void,
}

const TagPill = ({name, isCustom, onRemove}: TagPillProps) => {
    return (
        <div className={`c-els-pill u-els-margin-right-1o2 u-els-padding-left u-els-margin-bottom-1o4 u-els-color-n9
        ${isCustom ? 'u-els-background-color-white custom-tag-pill' : 'u-els-background-color-n2'}`}>
            <span className="u-els-margin-right-1o4">{name}</span>
            <button className="u-els-anchorize u-els-color-primary" onClick={() => onRemove()} data-testid={`${name}-x`}>
                <DeletePillIcon />
            </button>
        </div>
    );
};

export default TagPill;