import React, { Fragment, useContext, createRef, KeyboardEvent } from 'react';
import AlertContext from '../../../../Context/Alert/AlertContext';
import SkillContext from '../../../../Context/Skill/SkillContext';
import { ButtonType } from '../../../../Context/Skill/SkillState';
import CloseModalIcon from '../../Icons/CloseModalIcon';

const AlertModal = () => {
    const { alertMessage, buttonType, clearAlert } = useContext(AlertContext);
    const {skill, getSkill } = useContext(SkillContext);

    const closeXRef = createRef<HTMLButtonElement>();
    const closeButtonRef = createRef<HTMLButtonElement>();
    const refreshButtonRef = createRef<HTMLButtonElement>();

    const onClickClose = () => {
        clearAlert();
    };

    const onClickRefresh = () => {
        clearAlert();
        getSkill(skill.skillKeyId);
    };

    const onTabLastElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            closeXRef.current?.focus();
        }
    };

    const onShiftTabFirstElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            closeButtonRef.current?.focus();
            refreshButtonRef.current?.focus();
        }
    };

    const renderButton = () => {
        if(buttonType === ButtonType.refreshButton) {
            return <button className="c-els-button" onClick={onClickRefresh}
                onKeyDown={event => onTabLastElement(event)}ref={refreshButtonRef} autoFocus={true}>Refresh</button>;
        }
        else{
            return <button className="c-els-button" onClick={onClickClose}
                onKeyDown={event => onTabLastElement(event)}ref={closeButtonRef} autoFocus={true}>Close</button>;
        }
    };

    const alerts = alertMessage?.split('\n');

    if(alertMessage){
        return (
            <div className='c-els-modal c-els-modal--primary u-els-padding-2x u-app-z-index--modal'>
                <div className='c-els-modal__window u-els-width-1o2 u-els-padding-2x'>
                    <button className='c-els-modal__close u-els-anchorize' onClick={onClickClose} onKeyDown={event => onShiftTabFirstElement(event)}
                        ref={closeXRef} data-testid='close_x'>
                        <CloseModalIcon />
                    </button>
                    <h3 className='u-els-margin-bottom'>Alert</h3>
                    <span>
                        {alerts?.map((alert, index) => (
                            <p key={index}>{alert}</p>
                        ))}
                    </span>
                    <div className="o-els-flex-layout u-els-margin-top o-els-flex--space-between">
                        <div className='o-els-flex-layout__item--grow'></div>
                        <div className="o-els-flex-layout__item--right u-els-nowrap">
                            {renderButton()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    else {
        return (
            <Fragment />
        );
    }
};

export default AlertModal;
