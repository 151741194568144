import React, { useEffect, useReducer } from 'react';
import ConfigContext from './ConfigContext';
import ConfigReducer, { ConfigReducerState, loadConfigAction } from './ConfigReducer';

export interface AppConfig {
    appName: string,
    authority: string,
    SkillsContentServicesBaseUrl: string,
    NursingSkillsBaseUrl: string,
}

export async function getAppConfig() {
    const response = await fetch('/config.json');
    return (await response.json()) as AppConfig;
}

const ConfigState = (props: any) => {
    const initialState = {} as ConfigReducerState;

    const [state, dispatch] = useReducer(ConfigReducer, initialState);

    useEffect(() => {
        getAppConfig()
            .then(config => dispatch(loadConfigAction(config)))
            .catch(() => console.error('Failed to load config'));
    }, []);

    return (
        <ConfigContext.Provider
            value={state}>
            { props.children}
        </ConfigContext.Provider>
    );
};

export default ConfigState;