import React, { RefObject, useContext, useEffect, useState } from 'react';
import { TagType } from '../../../Context/Tags/TagsState';
import TagCheckboxItem from '../TagCheckboxItem/TagCheckboxItem';
import { Category } from '../../../Models/Category';
import { Specialty } from '../../../Models/Specialty';
import AlphabeticSortSerivce from '../../../Services/AlphabeticSortService';
import TagsContext from '../../../Context/Tags/TagsContext';
import TagPill from '../TagPill/TagPill';

// @ts-ignore
import { Icon } from '@els/els-react--icon';

import './CategoriesSpecialtiesEditor.scss';

type CategoriesSpecialtiesEditorProps = {
    tagSearchRef: RefObject<HTMLDivElement>,
    tagSearchPopoutRef: RefObject<HTMLDivElement>,
    specialtyCategorySearchHasFocus: boolean,
    currentSkillCategories: Category[],
    currentSkillSpecialties: Specialty[],
    customCategories: Category[],
    customSpecialties: Specialty[],
    toolTipRef: RefObject<HTMLDivElement>,
    showingToolTip: boolean,
    setCurrentSkillCategories: (...props: any) => void,
    setCurrentSkillSpecialties: (...props: any) => void,
    setSpecialtyCategorySearchHasFocus: (...props: any) => void,
    setShowingToolTip: (...props: any) => void,
}

const CategoriesSpecialtiesEditor = ({
    tagSearchRef, tagSearchPopoutRef, specialtyCategorySearchHasFocus, currentSkillCategories, currentSkillSpecialties, customCategories, customSpecialties,
    toolTipRef, showingToolTip, setCurrentSkillCategories, setCurrentSkillSpecialties, setSpecialtyCategorySearchHasFocus, setShowingToolTip}: CategoriesSpecialtiesEditorProps) => {

    const _alphabeticSortService = new AlphabeticSortSerivce();

    const { allCategories, allSpecialties, latestCategory, latestSpecialty,
        clearLatestSpecialty, clearLatestCategory, openCreateTag, openManageTags } = useContext(TagsContext);

    const [ elsevierCategoryResults, setElsevierCategoryResults ] = useState([] as Category[]);
    const [ customCategoryResults, setCustomCategoryResults ] = useState([] as Category[]);
    const [ elsevierSpecialtyResults, setElsevierSpecialtyResults ] = useState([] as Specialty[]);
    const [ customSpecialtyResults, setCustomSpecialtyResults ] = useState([] as Specialty[]);
    const [ specialtyCategorySearch, setSpecialtyCategorySearch ] = useState(''); 
    const [ currentlyAddingType, setCurrentlyAddingType ] = useState(TagType.category);

    useEffect(() => {
        if (latestCategory) {
            setCurrentSkillCategories([ ...currentSkillCategories, latestCategory ].sort((a, b) => _alphabeticSortService.sort(a.itemData, b.itemData)));

            clearLatestCategory();
        }
    }, [latestCategory]);

    useEffect(() => {
        if (latestSpecialty) {
            setCurrentSkillSpecialties([ ...currentSkillSpecialties, latestSpecialty ].sort((a, b) => _alphabeticSortService.sort(a.itemData, b.itemData)));

            clearLatestSpecialty();
        }
        
    }, [latestSpecialty]);

    useEffect(() => {
        if (currentlyAddingType == TagType.category) {
            let elsevierResults = [] as Category[];
            let customResults = [] as Category[];

            allCategories?.forEach(category => {
                if (category.itemData.toLocaleLowerCase().startsWith(specialtyCategorySearch.toLocaleLowerCase()) || specialtyCategorySearch.length == 0) {
                    if (category.isCustom) {
                        customResults = [ ...customResults, category ];
                    }
                    else {
                        elsevierResults = [ ...elsevierResults, category ];
                    }
                }
            });

            setElsevierCategoryResults(elsevierResults);
            setCustomCategoryResults(customResults);
            setElsevierSpecialtyResults([] as Specialty[]);
            setCustomSpecialtyResults([] as Specialty[]);
        }
    
        if (currentlyAddingType == TagType.specialty) {
            let elsevierResults = [] as Specialty[];
            let customResults = [] as Specialty[];

            allSpecialties?.forEach(specialty => {
                if (specialty.itemData.toLocaleLowerCase().startsWith(specialtyCategorySearch.toLocaleLowerCase()) || specialtyCategorySearch.length == 0) {
                    if (specialty.isCustom) {
                        customResults = [ ...customResults, specialty ];
                    }
                    else {
                        elsevierResults = [ ...elsevierResults, specialty ];
                    }
                }
            });

            setElsevierSpecialtyResults(elsevierResults);
            setCustomSpecialtyResults(customResults);
            setElsevierCategoryResults([] as Category[]);
            setCustomCategoryResults([] as Category[]);
        }
    }, [specialtyCategorySearch, currentlyAddingType, specialtyCategorySearchHasFocus]);

    const onChangeCategoryOrSpecialty = (newValue: string) => {
        setCurrentlyAddingType(newValue == TagType.category ? TagType.category : TagType.specialty);
    };

    const onClickCreateTag = () => {
        setSpecialtyCategorySearchHasFocus(false);
        openCreateTag(currentlyAddingType);
    };

    const onClickCategoryTag = (category: Category) => {
        const itemIndex = currentSkillCategories.findIndex(x => x.itemData == category.itemData && x.itemId == category.itemId);

        if (itemIndex >= 0) {
            onRemoveCategory(itemIndex);
        }
        else {
            setCurrentSkillCategories([ ...currentSkillCategories, category ].sort((a, b) => _alphabeticSortService.sort(a.itemData, b.itemData)));
        }
    };

    const onClickSpecialtyTag = (specialty: Specialty) => {
        const itemIndex = currentSkillSpecialties.findIndex(x => x.itemData == specialty.itemData && x.itemId == specialty.itemId);

        if (itemIndex >= 0) {
            onRemoveSpecialty(itemIndex);
        }
        else {
            setCurrentSkillSpecialties([ ...currentSkillSpecialties, specialty ].sort((a, b) => _alphabeticSortService.sort(a.itemData, b.itemData)));
        }
    };

    const onClickManageTags = () => {
        if (currentlyAddingType == TagType.category && customCategories?.length == 0 ||
            currentlyAddingType == TagType.specialty && customSpecialties?.length == 0) {
            setShowingToolTip(true);
        }
        else {
            openManageTags(currentlyAddingType);
        }
    };

    const onRemoveCategory = (itemIndex: number) => {
        let updatedCategories = [] as Category[];
        
        currentSkillCategories.forEach((category, arrayIndex) => {
            if (arrayIndex != itemIndex) {
                updatedCategories = [ ...updatedCategories, category ];
            }
        });

        setCurrentSkillCategories(updatedCategories);
    };

    const onRemoveSpecialty = (itemIndex: number) => {
        let updatedSpecialties = [] as Specialty[];

        currentSkillSpecialties.forEach((specialty, arrayIndex) => {
            if (arrayIndex != itemIndex) {
                updatedSpecialties = [ ...updatedSpecialties, specialty as Specialty ];
            }
        });

        setCurrentSkillSpecialties(updatedSpecialties);
    };

    const categoryIsOnSkill = (category: Category) => {
        return currentSkillCategories.some(x => x.itemData == category.itemData && x.itemId == category.itemId);
    };

    const specialtyIsOnSkill = (specialty: Specialty) => {
        return currentSkillSpecialties.some(x => x.itemData == specialty.itemData && x.itemId == specialty.itemId);
    };

    const showCategorySpecialtyResults = () => {
        return specialtyCategorySearchHasFocus;
    };

    const showElsevierTagResults = () => {
        return (elsevierCategoryResults.length > 0 || elsevierSpecialtyResults.length > 0);
    };

    const showCustomTagResults = () => {
        return (customCategoryResults.length > 0 || customSpecialtyResults.length > 0);
    };
    
    const showCategoryPills = () => {
        return currentlyAddingType == TagType.category;
    };
    
    const showSpecialtyPills = () => {
        return currentlyAddingType == TagType.specialty;
    };

    const showNoTagResults = () => {
        return !showElsevierTagResults() && !showCustomTagResults();
    };

    const showTagResultDivider = () => {
        return showElsevierTagResults() && showCustomTagResults();
    };

    const getManageTagsButtonText = () => {
        return `Manage custom ${currentlyAddingType == TagType.category ? 'categories' : 'specialties'}`;
    };

    const getToolTipText = () => {
        return `There are no custom ${currentlyAddingType == TagType.category ? 'categories' : 'specialties'} yet. Click "Find or create a tag" to get started!`;
    };

    return (
        <div className="u-els-margin-top-1o2">
            <span className="u-els-font-size-intro">Category & Specialty</span>
            <div className="u-els-margin-top-1o2">
                <Icon
                    className="react-icon-fix"
                    sprite="ConfirmationSolidCircle"
                    id="CategorySpecialtyOkay"
                    isVisible={true}
                    color="positive"
                    textAlignment="bottom"
                    a11y={{ name: 'Okay', description: 'Okay to edit'}}>
                </Icon>
                <span className="u-els-margin-left-1o4">Editing these will not effect Elsevier skill updates or CE credits.</span>
                <div className="o-els-flex-layout o-els-flex-layout--wrap o-els-flex-layout--left u-els-margin-top-1o2">
                    <div className="c-els-field">
                        <label className="c-els-field__label categories-specialties-select">
                            <span className="c-els-field__wrap c-els-field__wrap--icon-right">
                                <span className="c-els-field__icon c-els-field__icon--right">
                                    <Icon
                                        sprite="ChevronDown"
                                        id="Select"
                                        isVisible={true}
                                        a11y={{ name: 'Search', description: 'Select category or specialty'}}>
                                    </Icon>
                                </span>
                                <select id="category-or-specialty" className="c-els-field__input"
                                    onChange={e => onChangeCategoryOrSpecialty(e.currentTarget.value)} data-testid='tagtype-select'>
                                    <option value={TagType.category}>Category</option>
                                    <option value={TagType.specialty}>Specialty</option>
                                </select>
                            </span>
                        </label>
                    </div>
                    <div className="c-els-field u-els-margin-right-3x" ref={tagSearchRef}>
                        <label className="c-els-field__label categories-specialties-input">
                            <span className="c-els-field__wrap c-els-field__wrap--icon-right">
                                <span className="c-els-field__icon c-els-field__icon--right">
                                    <Icon
                                        sprite="Search"
                                        id="Search"
                                        isVisible={true}
                                        a11y={{ name: 'Search', description: 'Search for categories and specialties'}}>
                                    </Icon>
                                </span>
                                <input type="text" id="search-category-specialty" className="c-els-field__input" autoComplete='off'
                                    placeholder="Find or create a tag" name="search-category-specialty"
                                    onFocus={() => setSpecialtyCategorySearchHasFocus(true)}
                                    onChange={e => setSpecialtyCategorySearch(e.currentTarget.value)} />
                            </span>
                        </label>
                        {showCategorySpecialtyResults() &&
                                <div className="c-els-card u-els-display-flex o-els-flex-layout--column u-app-z-index--3 popout-menu" ref={tagSearchPopoutRef}>
                                    <div className="popout-scroll-container">
                                        {showElsevierTagResults() &&
                                        <div className='c-els-card__content'>
                                            {elsevierCategoryResults?.map((category, index) => (
                                                <TagCheckboxItem index={index} listLength={elsevierCategoryResults.length} text={category.itemData}
                                                    key={category.itemData} isChecked={categoryIsOnSkill(category)}
                                                    onChange={() => onClickCategoryTag(category)}></TagCheckboxItem>
                                            ))}
                                            {elsevierSpecialtyResults?.map((specialty, index) => (
                                                <TagCheckboxItem index={index} listLength={elsevierSpecialtyResults.length} text={specialty.itemData}
                                                    key={specialty.itemData} isChecked={specialtyIsOnSkill(specialty)}
                                                    onChange={() => onClickSpecialtyTag(specialty)}></TagCheckboxItem>
                                            ))}
                                        </div>
                                        }
                                        {showTagResultDivider() &&
                                        <div className='c-els-divider c-els-divider--1o2'></div>
                                        }
                                        {showCustomTagResults() &&
                                        <div className='c-els-card__content'>
                                            <div className='u-els-margin-bottom'>CUSTOM TAGS</div>
                                            {customCategoryResults.map((category, index) => (
                                                <TagCheckboxItem index={index} listLength={customCategoryResults.length} text={category.itemData}
                                                    key={category.itemData} isChecked={categoryIsOnSkill(category)}
                                                    onChange={() => onClickCategoryTag(category)}></TagCheckboxItem>
                                            ))}
                                            {customSpecialtyResults.map((specialty, index) => (
                                                <TagCheckboxItem index={index} listLength={customSpecialtyResults.length} text={specialty.itemData}
                                                    key={specialty.itemData} isChecked={specialtyIsOnSkill(specialty)}
                                                    onChange={() => onClickSpecialtyTag(specialty)}></TagCheckboxItem>
                                            ))}
                                        </div>
                                        }
                                        {showNoTagResults() &&
                                        <div className='c-els-card__content'>
                                            <div>NO MATCHING TAGS</div>
                                        </div>
                                        }
                                    </div>
                                    <div className="u-els-padding-left-1o2 u-els-padding-right-1o2 u-els-padding-bottom-3o4 u-els-padding-top-3o4
                                        popout-button-container">
                                        <button className="c-els-button c-els-button--x-small create-tag-button"
                                            onClick={onClickCreateTag}>Create a tag</button>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className='manage-tool-tip-parent'>
                        <button className='c-els-button' onClick={onClickManageTags}>{getManageTagsButtonText()}</button>
                        { showingToolTip &&
                        <div className="c-els-tooltip c-els-tooltip--top-left u-app-z-index--3 manage-tool-tip" ref={toolTipRef}>
                            <div className="c-els-tooltip__pointer"></div>
                            <div className="c-els-tooltip__window">
                                {getToolTipText()}
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <div className="o-els-flex-layout o-els-flex-layout--wrap o-els-flex-layout--left u-els-margin-top">
                    {showCategoryPills() &&
                    currentSkillCategories?.map((category, index) => (
                        <TagPill key={`category-${category.itemId}`} name={category.itemData} isCustom={category.isCustom}
                            onRemove={() => onRemoveCategory(index)}></TagPill>
                    ))
                    }
                    {showSpecialtyPills() &&
                    currentSkillSpecialties?.map((specialty, index) => (
                        <TagPill key={`specialty-${specialty.itemId}`} name={specialty.itemData} isCustom={specialty.isCustom}
                            onRemove={() => onRemoveSpecialty(index)}></TagPill>
                    ))
                    }
                </div>
            </div>
        </div>
    );
};

export default CategoriesSpecialtiesEditor;