import { createAction, createReducer } from '@reduxjs/toolkit';
import { User, UserManager } from 'oidc-client';

export enum ActionType {
    loadUser = 'LOAD_USER',
    loadUserManager = 'LOAD_USER_MANAGER',
    logoutUser = 'LOGOUT_USER',
}

export const loadUserAction = createAction<User>(ActionType.loadUser);
export const loadUserManagerAction = createAction<UserManager>(ActionType.loadUserManager);
export const logoutUserAction = createAction(ActionType.logoutUser);

export type AuthenticationReducerState = {
    user?: User,
    userManager?: UserManager,
    isAuthenticated: boolean,
    loading: boolean
}

const AuthenticationReducer = createReducer({} as AuthenticationReducerState, (builder) => {
    builder
        .addCase(loadUserAction, (state, {payload}) => {
            return { ...state, user: payload, isAuthenticated: true, loading: false };
        })
        .addCase(loadUserManagerAction, (state, {payload}) => {
            return { ...state, userManager: payload, isAuthenticated: false };
        })
        .addCase(logoutUserAction, (state) => {
            return { ...state, user: {} as User, userManager: {} as UserManager, isAuthenticated: false };
        });
});

export default AuthenticationReducer;