import { TestAnswer } from './TestAnswer';

export enum QuestionType {
    multipleChoice = 'MC',
    trueOrFalse = 'TF',
    yesOrNo = 'YN',
}

export interface TestQuestion {
    id: string,
    number: number,
    text: string,
    correctAnswer: string,
    answerOptions: TestAnswer[],
    questionType: QuestionType,
}