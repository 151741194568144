import React from 'react';

const DeletePillIcon = () => {
    return (
        <svg className='o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--bottom' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 128" height="24" width="14">
            <path d="M68.94 36.12L62 29 35 56 8 29l-7 7 27 27L1 90l7 7 27-27 27 27 7-7-27-27 26.94-26.88"/>
            <title>Close</title>
        </svg>
    );
};

export default DeletePillIcon;