import React, { useContext, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import AlertContext from '../../../Context/Alert/AlertContext';
import AuthenticationContext from '../../../Context/Authentication/AuthenticationContext';

// @ts-ignore
import { Loader } from '@els/els-react--loader';


const PrivateRoute = ({ component: WrappedComponent, ...props }: any) => {
    
    const { userManager, isAuthenticated, loading, initiateLogin } = useContext(AuthenticationContext);
    const { setAlert } = useContext(AlertContext);

    const history = useHistory();

    useEffect(() => {
        if (userManager && !isAuthenticated) {
            initiateLogin(window.location.href)
                .catch((error) => {
                    console.error(error);
                    setAlert('Login authentication failed.');
                    history.replace('/error');
                });
        }
    }, [userManager]);

    return (
        <Route
            {...props}
            render={props =>
                !isAuthenticated && loading ? (<Loader></Loader>) : (
                    <WrappedComponent {...props} />
                )
            }
        />
    );
};

export default PrivateRoute;
