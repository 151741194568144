import { createAction, createReducer } from '@reduxjs/toolkit';
import { AppConfig } from './ConfigState';

export enum ActionType {
    loadConfig = 'LOAD_CONFIG'
}

export const loadConfigAction = createAction<AppConfig>(ActionType.loadConfig);

export type ConfigReducerState = { config?: AppConfig };

const ConfigReducer = createReducer({} as ConfigReducerState, (builder) => {
    builder
        .addCase(loadConfigAction, (state, {payload}) => {
            return { ...state, config: payload };
        });
});

export default ConfigReducer;