export class RegexPatterns {
    public static readonly versionCommentRegex = new RegExp(/^[\w\s-,.?!']*$/);
    public static readonly mediaCommentRegex = new RegExp(/^[\w\s-,.?!'"#%&()*+-/:;<>=@[\]~…†‡‘’“”–—™©©°¼½]*$/);
    public static readonly mediaDescriptionsRegex = new RegExp(/^[\w\s.&'"(),/:;’“”–—™®°#*<>[\]-]*$/);
    public static readonly categoriesSpecialtiesRegex = new RegExp(/^[\w\s(),/-]*$/);
    public static readonly keywordsRegex = new RegExp(/^[\w\s+.()/;’:&%“–™®×"'-]*$/);
    public static readonly notesRegex = new RegExp(/^[\w\s:.-]*$/);
    public static readonly skillNameRegex = new RegExp(/^[\w\s'(),-./:’–—®]*$/);
    public static readonly skillSummaryRegex = new RegExp(/^[\w\s#&'(),-.?!/:;<>’[\]—™¬®°–]*$/);
    public static readonly alertTextRegex = new RegExp(/^[\w\s.!?,+]*$/);
    public static readonly testQuestionRegex = new RegExp(/^[\w\s.!?,'"–—#%&()/:;[\]‘’“”+<>=×®°-]*$/);
    public static readonly testAnswerRegex = new RegExp(/^[\w\s.!?,'–"#%&()/\\:;[\]‘’“”+<>=×°…¼½¾-]*$/);
    public static readonly testRationaleRegex = new RegExp(/^[\w\s.!?,'–—"#%&()/:;[\]´‘’“”+<>=×¬®°¼½¾-]*$/);
}