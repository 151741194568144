import React, { Fragment } from 'react';
import { TestQuestion } from '../../../Models/TestQuestion';

import './QuestionList.scss';
import EditIcon from '../Icons/EditIcon';

type TestListProps = {
    questions: TestQuestion[],
    onEditClicked: (question: TestQuestion, index: number) => void,
    openConfirmModal: (index: number) => void,
}

const QuestionList = ({ questions, onEditClicked, openConfirmModal }: TestListProps) => {
    const onRemoveClicked = (index: number) => {
        openConfirmModal(index);
    };

    return (
        <Fragment>
            <table className='c-els-table'>
                <thead className="c-els-table__head">
                    <tr className='c-els-table__row c-els-table__row--head'>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Number
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Type
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Question
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Answer
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Edit
                        </th>
                    </tr>
                </thead>
                <tbody className='c-els-table__body'>
                    {questions?.map((question, index) => (
                        <tr className='c-els-table__row' key={`${question.number}-row`}>
                            <td className='c-els-table__cell' key={`${question.number}-number`} data-testid='list-question-number'>{question.number}</td>
                            <td className='c-els-table__cell' key={`${question.number}-type`} data-testid='list-question-type'>{question.questionType}</td>
                            <td className='c-els-table__cell question-text-cell' key={`${question.number}-text`} data-testid='list-question-text'>{question.text}</td>
                            <td className='c-els-table__cell' key={`${question.number}-correct`} data-testid='list-question-correct'>{question.correctAnswer}</td>
                            <td className='c-els-table__cell'>
                                <div className="u-els-display-flex">
                                    <button className='u-els-anchorize c-els-link u-els-margin-right'
                                        onClick={() => onEditClicked(question, index)} key={`${question.number}-edit`} data-testid='list-edit'>
                                        <EditIcon />
                                    </button>
                                    <button className='u-els-anchorize c-els-link' data-testid='list-delete' onClick={() => onRemoveClicked(index)}>Remove</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='c-els-divider u-els-color-n1'></div>
        </Fragment>
    );
};

export default QuestionList;