import React, { useContext, useState, createRef, KeyboardEvent } from 'react';
import SkillContext from '../../../../Context/Skill/SkillContext';
import { Illustration } from '../../../../Models/Illustration';
import { MediaType } from '../../../../Models/MediaViewModel';
import { Video } from '../../../../Models/Video';
import CloseModalIcon from '../../Icons/CloseModalIcon';
import TextFieldInputError from '../../TextFieldInputError/TextFieldInputError';
import { RegexPatterns } from '../../../../Constants/Constants';

const InfoEditModal = () => {
    const { currentSelection, closeInfoEdit, editIllustration, editVideo } = useContext(SkillContext);

    const [ skillHasBeenEdited, setSkillHasBeenEdited ] = useState(false);

    const [ descriptionText, setDescriptionText ] = useState(currentSelection?.description ?? '');
    const [ commentText, setCommentText ] = useState(currentSelection?.commentText ?? '');
    const [ enabledChecked, setEnabledChecked ] = useState(currentSelection?.isActive ?? true);

    const [ isCommentLengthError, setIsCommentLengthError ] = useState(false);
    const [ isCommentRegexError, setIsCommentRegexError ] = useState(false);
    const [ isDescriptionLengthError, setIsDescriptionLengthError ] = useState(false);
    const [ isDescriptionRegexError, setIsDescriptionRegexError ] = useState(false);

    const commentCharacterLimit = currentSelection?.mediaType == MediaType.illustrations ? 2048 : 1024;
    const descriptionCharacterLimit = 128;

    const closeButtonRef = createRef<HTMLButtonElement>();
    const cancelButtonRef = createRef<HTMLButtonElement>();
    const okButtonRef = createRef<HTMLButtonElement>();

    const onChangeCheckbox = () => {
        setEnabledChecked(!enabledChecked);
        setSkillHasBeenEdited(true);
    };

    const onChangeDescription = (text: string) => {
        setDescriptionText(text);
        setSkillHasBeenEdited(true);
    };

    const onChangeComment = (text: string) => {
        setCommentText(text);
        setSkillHasBeenEdited(true);
    };

    const isOkDisabled = () => {
        return !skillHasBeenEdited || isCommentLengthError || isCommentRegexError || isDescriptionLengthError || isDescriptionRegexError;
    };

    const headingText = () => {
        return currentSelection?.mediaType === MediaType.illustrations ? 'Illustration information' : 'Video information';
    };

    const descriptionFieldName = () => {
        return currentSelection?.mediaType === MediaType.illustrations ? 'Illustration description' : 'Video description';
    };

    const onClickClose = () => {
        setSkillHasBeenEdited(false);
        closeInfoEdit();
    };

    const onClickOk = () => {
        if (currentSelection?.mediaType === MediaType.illustrations) {
            const editedIllustration = {
                imageDescription: descriptionText,
                commentText: commentText,
                active: enabledChecked,
                key: currentSelection.key,
            } as Illustration;
            editIllustration(editedIllustration);
        }

        if (currentSelection?.mediaType === MediaType.videos) {
            const editedVideo = {
                animationName: descriptionText,
                commentText: commentText,
                isActive: enabledChecked,
                key: currentSelection.key,
            } as Video;
            editVideo(editedVideo);
        }
            
        onClickClose();
        
    };

    const onTabLastElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            closeButtonRef.current?.focus();
        }
    };

    const onShiftTabFirstElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            isOkDisabled() ? cancelButtonRef.current?.focus() : okButtonRef.current?.focus();
        }
    };

    return (
        <div className='c-els-modal u-els-padding-2x u-app-z-index--modal'>
            <div className='c-els-modal__window o-els-flex-layout__item--grow u-els-padding-2x'>
                <button className='c-els-modal__close u-els-anchorize' onClick={onClickClose} onKeyDown={event => onShiftTabFirstElement(event)}
                    ref={closeButtonRef} data-testid='close_x'>
                    <CloseModalIcon />
                </button>
                <h3 className='u-els-margin-bottom'>{headingText()}</h3>
                <div className="c-els-field c-els-field--checkbox u-els-margin-bottom">
                    <label className="c-els-field__label">
                        <input className="c-els-field__input" name="input-type-checkbox" type="checkbox" data-testid="enabled_checkbox"
                            checked={enabledChecked} onChange={onChangeCheckbox} />
                        <span className="c-els-field__label-text">
                            Enabled
                            <span className="c-els-field__switch"></span>
                        </span>
                    </label> 
                </div>
                <div className="c-els-field u-els-margin-bottom">
                    <label className="c-els-field__label">
                        <span className="c-els-field__label-text">{descriptionFieldName()}</span>
                        <input type="text" className="c-els-field__input" name="input-type-text" aria-invalid="true" aria-describedby="description"
                            defaultValue={descriptionText} onChange={event => onChangeDescription(event.currentTarget.value)}
                            spellCheck={false} autoFocus={true} />
                    </label>
                    <TextFieldInputError text={descriptionText} fieldName='description' hasBeenTouched={true} lengthLimit={descriptionCharacterLimit}
                        isLengthError={isDescriptionLengthError} setIsLengthError={setIsDescriptionLengthError}
                        isRegexError={isDescriptionRegexError} setIsRegexError={setIsDescriptionRegexError}
                        characterRegex={RegexPatterns.mediaDescriptionsRegex}></TextFieldInputError>
                </div>
                <div className="c-els-field--large u-els-margin-bottom">
                    <label className="c-els-field__label">
                        <span className="c-els-field__label-text">Comment text</span>
                        <textarea className="c-els-field__input" name="input-type-text" aria-invalid="true" aria-describedby="comment"
                            defaultValue={commentText} onChange={event => onChangeComment(event.currentTarget.value)} spellCheck={false} />
                    </label>
                    <TextFieldInputError text={commentText} fieldName='comment' hasBeenTouched={true} lengthLimit={commentCharacterLimit}
                        isLengthError={isCommentLengthError} setIsLengthError={setIsCommentLengthError}
                        isRegexError={isCommentRegexError} setIsRegexError={setIsCommentRegexError}
                        characterRegex={RegexPatterns.mediaCommentRegex}></TextFieldInputError>
                </div>
                <div className='u-els-margin-bottom'>File name: {currentSelection?.fileName}</div>
                <div className="o-els-flex-layout o-els-flex--space-between">
                    <div className='o-els-flex-layout__item--grow'></div>
                    <div className="o-els-flex-layout__item--right u-els-nowrap">
                        <button className="c-els-button" onClick={onClickClose}
                            onKeyDown={event => isOkDisabled() && onTabLastElement(event)} ref={cancelButtonRef}>Cancel</button>
                        <button className="c-els-button u-els-margin-left-1o2" disabled={isOkDisabled()} onClick={onClickOk}
                            onKeyDown={event => onTabLastElement(event)} ref={okButtonRef}>OK</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoEditModal;
