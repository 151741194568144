import React, { Fragment, useEffect, useState } from 'react';
import { ChecklistItem } from '../../../Models/ChecklistItem';
import CKEditorComponent from '../CKEditorComponent/CKEditorComponent';
import TextFieldInputError from '../TextFieldInputError/TextFieldInputError';

import './ChecklistItemForm.scss';

type ChecklistItemFormProps = {
    checklistItem: ChecklistItem,
    numberOfItems?: number,
    checklistItemTextHasBeenTouched: boolean,
    autoFocus?: boolean,
    shouldTriggerClear?: boolean,
    setChecklistItem: (checklistItem: ChecklistItem) => void,
    setChecklistItemTextHasBeenTouched: (hasBeenTouched: boolean) => void,
    setIsError: (isError: boolean) => void,
    setShouldTriggerClear?: (newValue: boolean) => void,
}

const ChecklistItemForm = ({ checklistItem, numberOfItems, checklistItemTextHasBeenTouched, autoFocus, shouldTriggerClear,
    setChecklistItem, setChecklistItemTextHasBeenTouched, setIsError, setShouldTriggerClear }: ChecklistItemFormProps) => {

    const [currentNumberString, setCurrentNumberString] = useState(checklistItem.index.toString());
    const [ isChecklistItemLengthError, setIsChecklistItemLengthError ] = useState(false);
    const [ isChecklistItemZeroError, setIsChecklistItemZeroError ] = useState(false);

    const checklistItemTextCharacterLimit = 6500;

    useEffect(() => {
        if (numberOfItems != null) {
            const newNumber = (numberOfItems + 1).toString();

            setCurrentNumberString((numberOfItems + 1).toString());
            onChangeOrderNumber(newNumber);
        }
    }, [numberOfItems]);

    useEffect(() => {
        setIsError(isChecklistItemZeroError || isChecklistItemLengthError);
    }, [isChecklistItemZeroError, isChecklistItemLengthError]);

    const onChangeOrderNumber = (orderNumber: string) => {
        setCurrentNumberString(orderNumber);

        setChecklistItem({
            ...checklistItem,
            index: (orderNumber.length > 0) ? Number.parseInt(orderNumber) : 1,
        } as ChecklistItem);
    };

    const onChangeChecklistItemText = (text: string) => {
        setChecklistItemTextHasBeenTouched(true);
        setChecklistItem({ ...checklistItem, text } as ChecklistItem);
    };

    return (
        <Fragment>
            <label className="c-els-field__label u-els-margin-bottom-2x">
                <span className='u-els-font-size-body-large'><small className='c-els-field__message--warning'>*</small>Number order:</span>
                <div className="u-els-display-flex o-els-flex-layout--middle">
                    <div className="c-els-field c-els-field--inline">
                        <label className="u-els-display-flex">
                            <span className="c-els-field__wrap">
                                <input type="number" className="c-els-field__input order-number-field" id="checklist-index-field" name="checklist-index-field"
                                    data-testid='checklist-index-field' min={1} value={currentNumberString}
                                    onChange={(event) => onChangeOrderNumber(event.currentTarget.value)} autoFocus={autoFocus} />
                            </span>
                        </label>
                    </div>
                </div>
            </label>
            <label className="c-els-field__label">
                <span className='u-els-font-size-body-large'><small className='c-els-field__message--warning'>*</small>Checklist item:</span>
                <span className="c-els-field__wrap">
                    <CKEditorComponent initData={checklistItem.text} editorplaceholder={'Add checklist item text here'} onChange={onChangeChecklistItemText}
                        shouldTriggerClear={shouldTriggerClear} setShouldTriggerClear={setShouldTriggerClear}></CKEditorComponent>
                    <TextFieldInputError text={checklistItem.text} hasBeenTouched={checklistItemTextHasBeenTouched}
                        isLengthError={isChecklistItemLengthError} setIsLengthError={setIsChecklistItemLengthError}
                        isZeroLengthError={isChecklistItemZeroError} setIsTextZeroError={setIsChecklistItemZeroError}
                        lengthLimit={checklistItemTextCharacterLimit} fieldName='checklist item text'></TextFieldInputError>
                </span>
            </label>
        </Fragment>
    );
};

export default ChecklistItemForm;