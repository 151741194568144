import axios from 'axios';
import React, { useContext, useReducer } from 'react';
import { Illustration } from '../../Models/Illustration';
import { MediaType, MediaViewModel } from '../../Models/MediaViewModel';
import { Video, VideoType } from '../../Models/Video';
import AlertContext from '../Alert/AlertContext';
import ConfigContext from '../Config/ConfigContext';
import SkillContext from './SkillContext';
import SkillReducer, { addIllustrationAction, addVideoAction, changeSelectionAction, clearSelectionAction, deleteIllustrationAction, deleteVideoAction, editIllustrationAction, editVideoAction, failIllustrationUploadAction, failVideoUploadAction, getSkillAction, getSkillNamesAction, moveItemAction, setHasBeenModifiedAction, setInfoEditAction, setLoadingAction, setSavingAction, setUnsavedChangesAction, setVersionCommentAction, setVideoPreviewAction, skillErrorAction, SkillReducerState, succeedIllustrationUploadAction, succeedVideoUploadAction } from './SkillReducer';
import path, { basename } from 'path-browserify';
import { nanoid } from 'nanoid';
import { SkillsContentInputModel } from '../../Models/InputModels/SkillsContentInputModel';
import { NotesInputModel } from '../../Models/InputModels/NotesInputModel';
import { ChecklistItem } from '../../Models/ChecklistItem';
import { ChecklistItemInputModel } from '../../Models/InputModels/ChecklistItemInputModel';
import { QuestionType, TestQuestion } from '../../Models/TestQuestion';
import { TestQuestionInputModel } from '../../Models/InputModels/TestQuestionInputModel';
import { GeneralInfoInputModel } from '../../Models/InputModels/GeneralInfoInputModel';
import { Category } from '../../Models/Category';
import { Specialty } from '../../Models/Specialty';
import AlphabeticSortSerivce from '../../Services/AlphabeticSortService';
import { SkillUpdateInputModel } from '../../Models/InputModels/SkillUpdateInputModel';
import { Subtitle } from '../../Models/Subtitle';
import { ToastMessage, ToastType } from '../Alert/AlertState';

// @ts-ignore
import DOMPurify from 'dompurify';

export enum UploadStatus {
    successful,
    failed,
    inProgress,
}

export enum ButtonType {
    closeButton,
    refreshButton
}

export enum EditableSkillsContentType {
    ExtendedText = 5,
    Supplies = 10,
    QuickSheet = 11
}

export interface PreviewState {
    previewIsOpen: boolean,
    previewPath?: string,
    previewCaptionsPath?: string,
    previewSubtitles?: Subtitle[],
    previewId?: string,
    previewTitle?: string,
}

const SkillState = (props: any) => {
    const initialState = {
        hasBeenModified: false,
        infoEditIsOpen: false,
        versionCommentIsOpen: false,
        unsavedChangesIsOpen: false,
        unsavedChangesRoute: '',
        loading: false,
        saving: false,
        uploading: false,
        previewState: {
            previewIsOpen: false,
        }
    } as SkillReducerState;

    const [state, dispatch] = useReducer(SkillReducer, initialState);

    const { config } = useContext(ConfigContext);
    const { setAlert, addToastMessage } = useContext(AlertContext);

    const _alphabeticSortService = new AlphabeticSortSerivce();

    const areValidFileExtensions = (files: File[], extensions: string[]) => {
        let valid = true;

        files.forEach(file => {
            const ext = path.extname(file.name).toLowerCase();
            if (!extensions.includes(ext)) {
                valid = false;
                return;
            }
        });

        return valid;
    };

    const areValidFileSizes = (files: File[], fileSizeLimitInMB: number) => {
        const fileSizeLimit = fileSizeLimitInMB * 1024 * 1024;

        let valid = true;

        files.forEach(file => {
            if (file.size >= fileSizeLimit) {
                valid = false;
                return;
            }
        });

        return valid;
    };

    const areValidCharacters = (files: File[]) => {
        const allowedFilenamePattern = /^[\w\s+\-.]+$/;

        let valid = true;

        files.forEach(file => {
            if (!file.name.match(allowedFilenamePattern)) {
                valid = false;
                return;
            }
        });

        return valid;
    };

    const getQuestionType = (question: TestQuestion) => {
        if (question.answerOptions?.length == 2) {
            if (question.answerOptions[0].text == 'True' && question.answerOptions[1].text == 'False') {
                return QuestionType.trueOrFalse;
            }

            return QuestionType.yesOrNo;
        }

        return QuestionType.multipleChoice;
    };

    const getSkill = async (skillKeyId: string) => {
        dispatch(setLoadingAction());

        try {
            axios.defaults.headers['Pragma'] = 'no-cache';
            const skillResponse = await axios.get(`${config?.SkillsContentServicesBaseUrl}/api/Skills/${skillKeyId}?includeInactive=True`);

            skillResponse.data.illustrations.forEach((illustration: Illustration) => {
                illustration.key = nanoid();
                illustration.uploadStatus = UploadStatus.successful;
            });
            skillResponse.data.videos.forEach((video: Video) => {
                video.key = nanoid();
                video.uploadStatus = UploadStatus.successful;
            });
            skillResponse.data.test.questions.forEach((item: TestQuestion) => {
                item.questionType = getQuestionType(item);
            });

            skillResponse.data.categories?.sort((a: Category, b: Category) => _alphabeticSortService.sort(a.itemData, b.itemData));
            skillResponse.data.specialties?.sort((a: Specialty, b: Specialty) => _alphabeticSortService.sort(a.itemData, b.itemData));
            skillResponse.data.keywords?.sort((a: string, b: string) => _alphabeticSortService.sort(a, b));

            await getSkillNames(skillKeyId);
            
            dispatch(getSkillAction(skillResponse.data));
        }
        catch (error) {
            setAlert('There was an error loading the skill.');
            dispatch(skillErrorAction());
        }
    };

    const saveSkillMedia = async (versionComment: string) => {
        const comment = encodeURIComponent(versionComment);

        let path = `${config?.SkillsContentServicesBaseUrl}/api/Skills/${state.skill.skillKeyId}/${state.mediaTypeModified}`;
        path = path.concat(`?versionX=${state.skill.versionX}&versionY=${state.skill.versionY}&versionZ=${state.skill.versionZ}`);
        path = path.concat(`&comments=${comment}`);

        const body = state.mediaTypeModified === MediaType.illustrations
            ? state.skill.illustrations.filter(illustration => illustration.uploadStatus === UploadStatus.successful)
            : state.skill.videos.filter(video => video.uploadStatus === UploadStatus.successful);

        await usePostEndpoint(path, body);
    };

    const saveSkillGeneralInfo = async (generalInfo: GeneralInfoInputModel, categories: Category[], specialties: Specialty[],
        keywords: string[], versionComment: string) => {

        const comment = encodeURIComponent(versionComment);

        let path = `${config?.SkillsContentServicesBaseUrl}/api/Skills/${state.skill.skillKeyId}`;
        path = path.concat(`?versionX=${state.skill.versionX}&versionY=${state.skill.versionY}&versionZ=${state.skill.versionZ}`);
        path = path.concat(`&comments=${comment}`);

        const body = {} as SkillUpdateInputModel;

        const skillChanges = state.skill.skillName !== generalInfo.skillName ||
                                state.skill.active !== generalInfo.active ||
                                state.skill.alertText !== generalInfo.alertText ||
                                state.skill.useAlert !== generalInfo.useAlert ||
                                state.skill.summary !== generalInfo.summary ||
                                state.skill.versionZ == 0;

        if (categories != state.skill.categories) {
            body.categories = categories;
        }

        if (specialties != state.skill.specialties) {
            body.specialties = specialties;
        }

        if (keywords != state.skill.keywords) {
            body.keywords = keywords;
        }

        if (skillChanges) {
            body.skillGeneralInfo = generalInfo;
        }

        await usePostEndpoint(path, body);
    };

    const saveSkillContent = async (contentInput: SkillsContentInputModel, contentType: number, versionComment: string) => {
        const comment = encodeURIComponent(versionComment);

        let path = `${config?.SkillsContentServicesBaseUrl}/api/Skills/${state.skill.skillKeyId}/${contentType}`;
        path = path.concat(`?versionX=${state.skill.versionX}&versionY=${state.skill.versionY}&versionZ=${state.skill.versionZ}`);
        path = path.concat(`&comments=${comment}`);

        const body = {
            itemData: DOMPurify.sanitize(contentInput.itemData),
        };
        
        await usePostEndpoint(path, body);
    };

    const saveNotes = async (notesInput: string, tabTitleInput: string, activeInput: boolean, versionComment: string) => {
        const body = {
            notesContent: DOMPurify.sanitize(notesInput),
            versionComment: versionComment,
            active: activeInput,
            notesTabTitle: tabTitleInput,
        } as NotesInputModel;

        const path = `${config?.SkillsContentServicesBaseUrl}/api/Skills/${state.skill.skillKeyId}/notes`;
        
        await usePostEndpoint(path, body);
    };

    const saveSkillTest = async (testQuestions: TestQuestion[], versionComment: string) => {
        const comment = encodeURIComponent(versionComment);

        let path = `${config?.SkillsContentServicesBaseUrl}/api/Skills/${state.skill.skillKeyId}/updateTest`;
        path = path.concat(`?versionX=${state.skill.versionX}&versionY=${state.skill.versionY}&versionZ=${state.skill.versionZ}`);
        path = path.concat(`&comments=${comment}`);

        const body = [] as TestQuestionInputModel[];

        testQuestions.forEach(question => {
            body.push({
                id: question.id ? Number.parseInt(question.id) : null,
                answerA: question.answerOptions[0].text,
                answerB: question.answerOptions[1].text,
                answerC: question.answerOptions[2] ? question.answerOptions[2].text : null,
                answerD: question.answerOptions[3] ? question.answerOptions[3].text : null,
                correctAnswer: question.correctAnswer,
                question: question.text,
                questionNumber: question.number,
                rationale: question.answerOptions[0].rationale,
            } as TestQuestionInputModel);
        });

        await usePostEndpoint(path, body);
    };

    const saveChecklist = async (checklistItems: ChecklistItem[], comment: string) => {
        const body: ChecklistItemInputModel[] = [];

        checklistItems.forEach((item: ChecklistItem) => {
            body.push({
                id: item.id,
                text: DOMPurify.sanitize(item.text),
                index: item.index,
            });
        });

        let path = `${config?.SkillsContentServicesBaseUrl}/api/Skills/${state.skill.skillKeyId}/updateChecklist`;
        path = path.concat(`?versionX=${state.skill.versionX}&versionY=${state.skill.versionY}&versionZ=${state.skill.versionZ}`);
        path = path.concat(`&comments=${comment}`);

        await usePostEndpoint(path, body);
    };
     
    const uploadFiles = (files: File[], mediaType: MediaType, extensions: string[]) => {
        const fileSizeLimitInMB = 25;

        if (!areValidCharacters(files)) {
            setAlert('File naming error.  Please only use alphanumeric characters or (+,-,_)');
            return;
        }

        if (!areValidFileSizes(files, fileSizeLimitInMB)) {
            setAlert(`You have included a file that is larger than the limit of ${fileSizeLimitInMB}MB.`);
            return;
        }

        if (!areValidFileExtensions(files, extensions)) {
            const extensionsString = extensions.join(', ');

            setAlert(`You have included an invalid file type. Valid file types for ${mediaType.toLocaleLowerCase()} are: ${extensionsString}`);
            return;
        }

        if (!window.confirm('By clicking OK, I certify that I have legal permission to upload the selected content.')) {
            return;
        }

        let newFiles = [] as Illustration[] | Video[];

        if (mediaType === MediaType.illustrations) {

            newFiles = files.map((file) => {
                return {
                    key: nanoid(),
                    imageFileName: file.name,
                    imageDescription: file.name.split('.')[0],
                    uploadStatus: UploadStatus.inProgress,
                    active: true,
                    isCustom: true,
                } as Illustration;
            });

            newFiles.forEach(i => dispatch(addIllustrationAction(i)));

        } else if (mediaType === MediaType.videos) {

            newFiles = files.map((file) => {
                return {
                    key: nanoid(),
                    fileName: file.name,
                    animationName: file.name.split('.')[0],
                    uploadStatus: UploadStatus.inProgress,
                    isActive: true,
                    mediaType: VideoType.custom,
                    isCustom: true,
                } as Video;
            });

            newFiles.forEach(v => dispatch(addVideoAction(v)));
        }

        const filesWithKey = files.map((file, index) => ({
            file,
            key: newFiles[index].key
        }));

        filesWithKey.forEach(async ({ file, key }) => {
            try {
                const path = mediaType === MediaType.illustrations ? 'uploadedIllustrations' : 'uploadedVideos';

                const data = new FormData();
                data.append('uploadedFile', file, file.name);

                const response = await axios.post(`${config?.SkillsContentServicesBaseUrl}/api/Skills/${state.skill.skillKeyId}/${path}`, data);

                if (mediaType === MediaType.illustrations) {
                    dispatch(succeedIllustrationUploadAction({
                        key,
                        fileName: basename(response.data.bucketKey),
                        signedImageUrl: response.data.signedUrl,
                    }));

                } else if (mediaType === MediaType.videos) {
                    dispatch(succeedVideoUploadAction({
                        key,
                        fileName: basename(response.data.bucketKey),
                        videoUrl: response.data.signedUrl,
                    }));
                }

            } catch (error) {
                setAlert(`There was an error uploading ${file.name}`);

                if (mediaType === MediaType.illustrations) {
                    dispatch(failIllustrationUploadAction(key));

                } else if (mediaType === MediaType.videos) {
                    dispatch(failVideoUploadAction(key));
                }
            }
        });
    };

    const editIllustration = (editedIllustration: Illustration) => {
        const newIllustration = {
            ...state.skill.illustrations.find(i => i.key === editedIllustration.key),
            ...editedIllustration,
        };

        dispatch(editIllustrationAction(newIllustration));
    };

    const deleteIllustration = (key: string) => {
        dispatch(deleteIllustrationAction(key));
    };

    const editVideo = (editedVideo: Video) => {
        const newVideo = {
            ...state.skill.videos.find(v => v.key === editedVideo.key),
            ...editedVideo,
        };

        dispatch(editVideoAction(newVideo));
    };

    const deleteVideo = (key: string) => {
        dispatch(deleteVideoAction(key));
    };

    const toggleActive = (mediaType: MediaType, key: string) => {
        const skill = state.skill;

        if (mediaType === MediaType.illustrations) {
            const illustration = skill.illustrations.find(i => i.key === key);
            if (!illustration) {
                return;
            }

            dispatch(editIllustrationAction({ ...illustration, active: !illustration.active }));
        }

        if (mediaType === MediaType.videos) {
            const video = skill.videos.find(v => v.key === key);
            if (!video) {
                return;
            }

            dispatch(editVideoAction({ ...video, isActive: !video.isActive }));
        }
    };

    const changeSelection = (newSelection: MediaViewModel) => {
        dispatch(changeSelectionAction(newSelection));
    };

    const clearSelection = () => {
        dispatch(clearSelectionAction());
    };

    const openInfoEdit = () => {
        dispatch(setInfoEditAction(true));
    };

    const openVersionComment = () => {
        dispatch(setVersionCommentAction(true));
    };

    const openUnsavedChanges = (route: string) => {
        dispatch(setUnsavedChangesAction({
            unsavedChangesIsOpen: true,
            unsavedChangesRoute: route,
        }));
    };

    const openVideoPreview = (previewPath: string, previewCaptionsPath?: string, previewSubtitles?: Subtitle[], previewId?: string, previewTitle?: string) => {
        dispatch(setVideoPreviewAction({
            previewPath: previewPath,
            previewCaptionsPath: previewCaptionsPath,
            previewSubtitles: previewSubtitles,
            previewTitle: previewTitle,
            previewId: previewId,
            previewIsOpen: true,
        }));
    };

    const closeInfoEdit = () => {
        dispatch(setInfoEditAction(false));
    };

    const closeVersionComment = () => {
        dispatch(setVersionCommentAction(false));
    };

    const closeUnsavedChanges = () => {
        dispatch(setUnsavedChangesAction({
            unsavedChangesIsOpen: false,
            unsavedChangesRoute: '',
        }));
    };

    const closeVideoPreview = () => {
        dispatch(setVideoPreviewAction({ previewIsOpen: false }));
    };

    const moveItem = (key: string, itemType: MediaType, direction: number) => {
        dispatch(moveItemAction({ key, itemType, direction }));
    };

    const usePostEndpoint = async <T,>(path: string, body: T) => {
        dispatch(setSavingAction(true));

        try {
            await axios.post(path, body);

            addToastMessage({ 
                messageOne: 'Your changes have been ',
                messageTwo: 'saved successfully.', 
                type: ToastType.success,
            } as ToastMessage);

            await getSkill(state.skill.skillKeyId);
        }
        catch (error: any) {
            if (error.response?.status === 409) {
                if (error.response?.data == 'Skill name must not match any other current skill.') {
                    try {
                        await getSkillNames(state.skill?.skillKeyId);

                        setAlert(error.response.data);
                    }
                    catch (skillNameError: any) {
                        setAlert(skillNameError.response?.data ?? skillNameError.message);
                    }
                }
                else {
                    setAlert('This skill has been modified in another location since opening. Refresh the page to continue. Please note, some of your changes may be lost.', ButtonType.refreshButton);
                }
            }
            else if (error.response?.data) {
                setAlert(error.response.data);
            }
            else {
                setAlert(error.message);
            }
        }
        finally {
            dispatch(setSavingAction(false));
        }

        return;
    };

    const setHasBeenModified = (hasBeenModified: boolean) => {
        dispatch(setHasBeenModifiedAction(hasBeenModified));
    };

    const getSkillNames = async (currentSkillKeyId: string) => {
        try {
            const skillNamesResponse = await axios.get(`${config?.SkillsContentServicesBaseUrl}/api/Skills/allSkillsNames?skillKeyIdToOmit=${currentSkillKeyId}`);

            dispatch(getSkillNamesAction(skillNamesResponse.data));
        }
        catch (error: any) {
            throw error.response?.data ?? error.message;
        }
    };

    return (
        <SkillContext.Provider
            value={{
                ...state,
                getSkill,
                saveSkillMedia,
                saveSkillGeneralInfo,
                saveSkillContent,
                saveSkillTest,
                saveNotes,
                uploadFiles,
                editIllustration,
                editVideo,
                saveChecklist,
                deleteIllustration,
                deleteVideo,
                toggleActive,
                changeSelection,
                clearSelection,
                openInfoEdit,
                openVersionComment,
                openUnsavedChanges,
                openVideoPreview,
                closeInfoEdit,
                closeVersionComment,
                closeUnsavedChanges,
                closeVideoPreview,
                moveItem,
                setHasBeenModified,
            }}>
            {props.children}
        </SkillContext.Provider>
    );
};

export default SkillState;