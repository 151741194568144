import React from 'react';
import { Colors } from '../Colors';

const ErrorIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 128" height="24" width="21" fill={Colors.hmdswarn} data-testid='error_icon'><path d="M55 26l39 76H16zM0 112h110L55 4zm50-30h10v10H50zm0-22l1.84 16h6L60 60V48H50z"/>
            <title>Upload error</title>
        </svg>
    );
};

export default ErrorIcon;