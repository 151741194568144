import React, { createRef, useContext, useState, KeyboardEvent } from 'react';
import TagsContext from '../../../../Context/Tags/TagsContext';
import { TagType } from '../../../../Context/Tags/TagsState';
import CloseModalIcon from '../../Icons/CloseModalIcon';
import AlertContext from '../../../../Context/Alert/AlertContext';
import { ToastMessage, ToastType } from '../../../../Context/Alert/AlertState';
import TextFieldInputError from '../../TextFieldInputError/TextFieldInputError';

import './CreateTagModal.scss';
import { RegexPatterns } from '../../../../Constants/Constants';

const CreateTagModal = () => {
    const { creatingTagType, allCategories, allSpecialties, isLoadingTags, latestKeywordList,
        addCustomCategory, addCustomSpecialty, addKeyword, closeCreateTag } = useContext(TagsContext);

    const { addToastMessage } = useContext(AlertContext);

    const [ tagText, setTagText ] = useState('');
    const [ formIsDirty, setFormIsDirty ] = useState(false);

    const [ isTagNameLengthError, setIsTagNameLengthError ] = useState(false);
    const [ isTagNameZeroLengthError, setIsTagNameZeroLengthError ] = useState(false);
    const [ isTagNameRegexError, setIsTagNameRegexError ] = useState(false);
    const [ isTagNameWhiteSpaceError, setIsTagNameWhiteSpaceError ] = useState(false);
    const [ duplicateTagError, setDuplicatTagError ] = useState('');

    const closeXRef = createRef<HTMLButtonElement>();
    const closeButtonRef = createRef<HTMLButtonElement>();

    const tagTextLengthLimit = 60;

    const updateTagText = (newText: string) => {
        if (isExistingTag(newText)) {
            setDuplicatTagError(`${creatingTagType} of this name already exists`);
        }
        else {
            setDuplicatTagError('');
        }

        setTagText(newText);
        setFormIsDirty(true);
    };

    const isExistingTag = (tagName: string) => {
        if (creatingTagType == TagType.category) {
            return allCategories?.some(x => x.itemData.trimStart().trimEnd() == tagName.trimStart().trimEnd());
        }

        if (creatingTagType == TagType.specialty) {
            return allSpecialties?.some(x => x.itemData.trimStart().trimEnd() == tagName.trimStart().trimEnd());
        }

        if (creatingTagType == TagType.keyword) {
            return latestKeywordList?.some(x => x.trimStart().trimEnd() == tagName.trimStart().trimEnd());
        }

        return false;
    };

    const onClickCreateTag = async () => {
        let displaySuccess = true;

        if (creatingTagType == TagType.category) {
            displaySuccess = await addCustomCategory(tagText.trimStart().trimEnd());
        }

        if (creatingTagType == TagType.specialty) {
            displaySuccess = await addCustomSpecialty(tagText.trimStart().trimEnd());
        }

        if (creatingTagType == TagType.keyword) {
            addKeyword(tagText.trimStart().trimEnd());
        }

        if (displaySuccess) {
            addToastMessage({
                messageOne: `New ${creatingTagType?.toLocaleLowerCase()} created!`,
                messageTwo: getToastMessageText(),
                type: ToastType.success,
            } as ToastMessage);
        }

        closeCreateTag();
    };

    const saveIsDisabled = () => {
        return isTagNameZeroLengthError || isTagNameLengthError || isTagNameRegexError || isTagNameWhiteSpaceError ||isExistingTag(tagText);
    };

    const onTabLastElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            closeXRef.current?.focus();
        }
    };

    const onShiftTabFirstElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            closeButtonRef.current?.focus();
        }
    };

    const getModalHeadingText = () => {
        return `Create a ${creatingTagType?.toLocaleLowerCase()}`; 
    };

    const getToastMessageText = () => {
        let pluralType = '';

        if (creatingTagType == TagType.category) {
            pluralType = 'categories';
        }
        else if (creatingTagType == TagType.specialty) {
            pluralType = 'specialties';
        }
        else {
            pluralType = 'keywords';
        }

        return `${tagText.trimStart().trimEnd()} has been added to the custom ${pluralType}.`;
    };

    const getRegexPattern = () => {
        if (creatingTagType == TagType.category || creatingTagType == TagType.specialty) {
            return RegexPatterns.categoriesSpecialtiesRegex;
        }
        else {
            return RegexPatterns.keywordsRegex;
        }
    };

    if (isLoadingTags) {
        return (
            <div className='c-els-modal c-els-modal--secondary u-els-padding-2x u-app-z-index--modal'>
                <div className='c-els-modal__window u-els-width-1o2 u-els-padding-2x modal-container'>
                    <div className="c-els-loader__wrapper">
                        <div className="c-els-loader__loading-bar">
                        </div>
                        <div className="c-els-loader__loading-bar">
                        </div>
                        <div className="c-els-loader__loading-bar">
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    else {
        return (
            <div className='c-els-modal c-els-modal--secondary u-els-padding-2x u-app-z-index--modal'>
                <div className='c-els-modal__window u-els-width-1o2 u-els-padding-2x modal-container'>
                    <button className='c-els-modal__close u-els-anchorize' onClick={closeCreateTag} onKeyDown={event => onShiftTabFirstElement(event)}
                        ref={closeXRef} data-testid='close_x'>
                        <CloseModalIcon />
                    </button>
                    <h3>{getModalHeadingText()}</h3>
                    <div className="c-els-field u-els-margin-top">
                        <label className="c-els-field__label">
                            <span className="c-els-field__label-text">{creatingTagType} name</span>
                            <span className="c-els-field__wrap">
                                <input type="text" id="sample-id" className="c-els-field__input" name="input-type-text" placeholder="Please enter a tag name"
                                    onChange={e => updateTagText(e.currentTarget.value)} autoComplete='off' autoFocus={true} data-testid='tag-input' />
                                <TextFieldInputError text={tagText} hasBeenTouched={formIsDirty} customError={duplicateTagError}
                                    isLengthError={isTagNameLengthError} setIsLengthError={setIsTagNameLengthError}
                                    isZeroLengthError={isTagNameZeroLengthError} setIsTextZeroError={setIsTagNameZeroLengthError}
                                    isRegexError={isTagNameRegexError} setIsRegexError={setIsTagNameRegexError} characterRegex={getRegexPattern()}
                                    lengthLimit={tagTextLengthLimit} fieldName={creatingTagType?.toLocaleLowerCase() ?? ''}
                                    isWhiteSpaceError={isTagNameWhiteSpaceError} setIsWhiteSpaceError={setIsTagNameWhiteSpaceError}></TextFieldInputError>
                            </span>
                        </label>
                    </div>
                    <div className='u-els-margin-top-2x'>
                        <button className='c-els-button u-els-margin-right' onClick={onClickCreateTag} disabled={saveIsDisabled()}>Create a tag</button>
                        <button className='c-els-button c-els-button--secondary' onClick={closeCreateTag} ref={closeButtonRef}
                            onKeyDown={event => onTabLastElement(event)}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }
};

export default CreateTagModal;