import React, { Fragment, useEffect } from 'react';

// @ts-ignore
import { Icon } from '@els/els-react--icon';

import './TextFieldInputError.scss';

type TextFieldLengthErrorProps = {
    text: string,
    fieldName: string,
    hasBeenTouched: boolean,
    lengthLimit: number,
    characterRegex?: RegExp,
    isLengthError: boolean,
    isZeroLengthError?: boolean,
    isRegexError?: boolean,
    useZeroLengthWarning?: boolean,
    customError?: string,
    isWhiteSpaceError?: boolean,
    setIsLengthError: (isLengthError: boolean) => void,
    setIsTextZeroError?: (isTextZeroError: boolean) => void,
    setIsRegexError?: (isRegexError: boolean) => void,
    setIsWhiteSpaceError?: (isWhiteSpaceError: boolean) => void,
}

const TextFieldInputError = ({ text, fieldName, hasBeenTouched, lengthLimit, characterRegex, isLengthError, isZeroLengthError, isRegexError, isWhiteSpaceError, useZeroLengthWarning = false,
    customError, setIsLengthError, setIsTextZeroError, setIsRegexError, setIsWhiteSpaceError }: TextFieldLengthErrorProps) => {

    useEffect(() => {
        setIsLengthError(text.length > lengthLimit);

        if (isZeroLengthError != undefined && setIsTextZeroError != undefined) {
            setIsTextZeroError(text.length == 0 && hasBeenTouched);
        }

        if (characterRegex != undefined && setIsRegexError != undefined)
        {
            setIsRegexError(text.length > 0 && !characterRegex.test(text));
        }

        if (isWhiteSpaceError != undefined && setIsWhiteSpaceError != undefined){
            setIsWhiteSpaceError(text.length > 0 && text.trim().length == 0);
        }

    }, [text]);

    const getInvalidCharacterList = () => {
        const characterList = new Set();

        if (characterRegex != null) {
            Array.from(text).forEach(character => {
                if (!characterRegex.test(character)) {
                    characterList.add(character);
                }
            });
        }

        return Array.from(characterList).join(' ');
    };

    const getZeroLengthWarningText = () => {
        return `Warning: ${ fieldName.charAt(0).toUpperCase()}${fieldName.slice(1) } will not appear if it is left blank`;
    };

    const isZeroLengthWarning = () => {
        return text.length == 0 && useZeroLengthWarning;
    };

    const showCustomError = () => {
        return customError && customError.length > 0;
    };

    const showErrorAlert = () => {
        return showCustomError() || isLengthError || isZeroLengthError || isRegexError || isWhiteSpaceError;
    };

    const showCharacterCount = () => {
        return isLengthError && !showCustomError();
    };

    const getErrorAlertText = () => {
        if (showCustomError()) return customError;

        if (isLengthError) return 'Reduce number of characters';

        if (isZeroLengthError) return `${ fieldName.charAt(0).toUpperCase()}${fieldName.slice(1) } cannot be blank`;

        if (isRegexError) return `Following included characters are not allowed on ${fieldName}: ${getInvalidCharacterList()}`;

        if(isWhiteSpaceError) return `${ fieldName.charAt(0).toUpperCase()}${fieldName.slice(1) } cannot be only whitespace.`;

        return '';
    };

    return (
        <Fragment>
            { showErrorAlert() &&
             <div className="o-els-flex-layout">
                 <div className="c-els-field__message c-els-field__message--error o-els-flex-layout--left u-els-margin-right-auto" id="commentWarning" role="alert">
                     <Icon
                         sprite="AlertSolidSquare"
                         textAlignment="bottom"
                         id="FormError"
                         isVisible={ true }
                         className="react-icon-fix"
                         a11y={{ name: 'Alert', description: 'Comment Error' }}>
                     </Icon>{ getErrorAlertText() }
                 </div>
                 { showCharacterCount() &&
                    <div className="c-els-field__message o-els-flex-layout--right">
                        { text?.length }/{ lengthLimit } characters
                    </div>
                 }
             </div>
            }
            { ( !showErrorAlert() && !isZeroLengthWarning() ) &&
            <div className='empty-error-field'></div>
            }
            { ( isZeroLengthWarning() && !showCustomError() ) &&
            <div className="u-els-display-flex">
                <div className="c-els-field__message c-els-field__message--error u-els-margin-right" id="tabTitleZerotWarning" role="alert">
                    <Icon
                        sprite="AlertOutlineCircle"
                        textAlignment="bottom"
                        id="FormError"
                        isVisible={ true }
                        className="react-icon-fix"
                        a11y={{ name: 'Alert',description: 'Comment Error'}}
                    ></Icon>{ getZeroLengthWarningText() }
                </div>
            </div>
            }
        </Fragment>
    );
};

export default TextFieldInputError;