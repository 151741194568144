import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import SkillContext from '../../../Context/Skill/SkillContext';
import CustomSkillCard from '../../Layout/CustomSkillCard/CustomSkillCard';
import TextFieldInputError from '../../Layout/TextFieldInputError/TextFieldInputError';
import { GeneralInfoInputModel } from '../../../Models/InputModels/GeneralInfoInputModel';
import TagsContext from '../../../Context/Tags/TagsContext';
import CategoriesSpecialtiesEditor from '../../Layout/CategoriesSpecialtiesEditor/CategoriesSpecialtiesEditor';
import KeywordsEditor from '../../Layout/KeywordsEditor/KeywordsEditor';
import VersionCommentField from '../../Layout/VersionCommentField/VersionCommentField';
import ManageTagsModal from '../../Layout/Modals/ManageTagsModal/ManageTagsModal';
import { Category } from '../../../Models/Category';
import { Specialty } from '../../../Models/Specialty';
import InfoTooltipIcon from '../../Layout/InfoTooltipIcon/InfoTooltipIcon';
import { RegexPatterns } from '../../../Constants/Constants';

interface SkillNameMessage {
    skillName: string,
}

const SkillGeneralPage = () => {

    const { skill, allSkillNames, hasBeenModified, setHasBeenModified, openUnsavedChanges, saveSkillGeneralInfo } = useContext(SkillContext);
    const { allCategories, allSpecialties, managingTagType, getAllTags, setLatestKeywordList } = useContext(TagsContext);

    const [ currentSkillName, setCurrentSkillName ] = useState(skill.skillName ?? '');
    const [ currentSkillSummary, setCurrentSkillSummary ] = useState(skill.summary ?? '');
    const [ currentSkillAlertText, setCurrentSkillAlertText ] = useState(skill.alertText ?? '');
    const [ currentSkillIsUseAlert, setCurrentSkillIsUseAlert ] = useState(skill.useAlert);
    const [ currentSkillIsActive, setCurrentSkillIsActive ] = useState(skill.active);
    const [ currentSkillKeywords, setCurrentSkillKeywords ] = useState(skill.keywords);
    const [ currentSkillCategories, setCurrentSkillCategories ] = useState(skill.categories);
    const [ currentSkillSpecialties, setCurrentSkillSpecialties ] = useState(skill.specialties);

    const [ customCategories, setCustomCategories ] = useState([] as Category[]);
    const [ customSpecialties, setCustomSpecialties ] = useState([] as Specialty[]);

    const [ commentText, setCommentText ] = useState('');
    const [ specialtyCategorySearchHasFocus, setSpecialtyCategorySearchHasFocus ] = useState(false);
    const [ keywordSearchHasFocus, setKeywordSearchHasFocus ] = useState(false);

    const [ isSkillNameLengthError, setIsSkillNameLengthError ] = useState(false);
    const [ isSkillNameZeroError, setIsSkillNameZeroError ] = useState(false);
    const [ isSkillSummaryLengthError, setIsSkillSummaryLengthError ] = useState(false);
    const [ isAlertTextLengthError, setIsAlertTextLengthError ] = useState(false);
    const [ isSkillNameWhiteSpaceError, setIsSkillNameWhiteSpaceError ] = useState(false);
    const [ isCommentError, setIsCommentError ] = useState(false);
    const [ showingToolTip, setShowingToolTip ] = useState(false);

    const [ isSkillNameRegexError, setIsSkillNameRegexError ] = useState(false);
    const [ isSkillSummaryRegexError, setIsSkillSummaryRegexError ] = useState(false);
    const [ isAlertTextRegexError, setIsAlertTextRegexError ] = useState(false);

    const [ duplicateSkillNameError, setDuplicateSkillNameError ] = useState('');

    const tagSearchRef = useRef<HTMLDivElement>(null);
    const tagSearchPopoutRef = useRef<HTMLDivElement>(null);
    const keywordSearchRef = useRef<HTMLDivElement>(null);
    const keywordSearchPopoutRef = useRef<HTMLDivElement>(null);
    const toolTipRef = useRef<HTMLDivElement>(null);

    const skillNameLengthLimit = 120;
    const skillSummaryLengthLimit = 1024;
    const skillAlertTextLimit = 1024;
    const commentCharacterLimit = 512;

    const activeTooltipMessage = 'If a skill is set to inactive status, it will not be displayed to your learners.  Be sure to change the status to Active when you are ready to display the skill to your learners.';

    useEffect(() => {
        if (!allCategories && !allSpecialties) {
            getAllTags();
        }

        setCustomCategories(allCategories?.filter(category => category.isCustom));
        setCustomSpecialties(allSpecialties?.filter(specailty => specailty.isCustom));
    }, [allCategories, allSpecialties]);

    useEffect(() => {
        setLatestKeywordList(skill.keywords);

        // This message is for use in the NursingSkills iFrame and can be deleted when NursingSkills is no longer in use
        const messageChannel = new MessageChannel();
        window.parent.postMessage({ skillName: skill.skillName } as SkillNameMessage, '*', [messageChannel.port2]);
    }, [skill]);

    useEffect(() => {
        updateHasBeenModified();
    }, [currentSkillName, currentSkillSummary, currentSkillAlertText, currentSkillIsUseAlert, currentSkillIsActive, commentText,
        currentSkillCategories, currentSkillSpecialties, currentSkillKeywords]);

    useEffect(() => {
        onChangeSkillName(currentSkillName);
    }, [allSkillNames]);

    const updateHasBeenModified = () => {
        setHasBeenModified(
            currentSkillName != skill.skillName ||
            hasNullableFieldBeenModified(currentSkillSummary, skill.summary) ||
            hasNullableFieldBeenModified(currentSkillAlertText, skill.alertText) ||
            currentSkillIsActive != skill.active ||
            currentSkillIsUseAlert != skill.useAlert ||
            currentSkillCategories != skill.categories ||
            currentSkillSpecialties != skill.specialties ||
            currentSkillKeywords != skill.keywords ||
            commentText.length > 0
        );
    };

    const hasNullableFieldBeenModified = (current: string, fromSkill: string) => {
        if (fromSkill == null) {
            return current.length > 0;
        }
        else {
            return current != fromSkill;
        }
    };

    const onChangeSkillName = (newSkillName: string) => {
        if (allSkillNames?.includes(newSkillName.trim()))
        {
            setDuplicateSkillNameError('This skill name is already in use on another skill');
        }
        else {
            setDuplicateSkillNameError('');
        }

        setCurrentSkillName(newSkillName);
    };

    const onChangeSkillSummary = (newSkillSummary: string) => {
        setCurrentSkillSummary(newSkillSummary);
    };

    const onChangeSkillAlertText = (newSkillAlertText: string) => {
        setCurrentSkillAlertText(newSkillAlertText);
    };

    const onChangeIsActive = (newIsActive: boolean) => {
        setCurrentSkillIsActive(newIsActive);
    };

    const onChangeIsUseAlert = (newIsUseAlert: boolean) => {
        setCurrentSkillIsUseAlert(newIsUseAlert);
    };

    const showSkillAlertLengthError = () => {
        return isAlertTextLengthError && currentSkillIsUseAlert;
    };

    const canEditKeywords = () => {
        return skill.skillId?.includes('ZZ');
    };

    const isSaveDisabled = () => {
        return (
            isSkillNameLengthError || isSkillNameZeroError || isSkillSummaryLengthError || isSkillNameWhiteSpaceError || isSkillNameRegexError || isSkillSummaryRegexError ||
            isAlertTextRegexError || showSkillAlertLengthError() || isCommentError || !hasBeenModified || duplicateSkillNameError.length > 0
        );
    };

    const isCancelDisabled = () => {
        return !hasBeenModified;
    };

    const onSaveClicked = () => {
        const input = {
            skillName: currentSkillName.replace(/\s+/g, ' ').trim(),
            summary: currentSkillSummary,
            alertText: currentSkillIsUseAlert ? currentSkillAlertText : skill.alertText,
            active: currentSkillIsActive,
            useAlert: currentSkillIsUseAlert,
        } as GeneralInfoInputModel;

        saveSkillGeneralInfo(input, currentSkillCategories, currentSkillSpecialties, currentSkillKeywords, commentText);
    };

    const onCancelClicked = () => {
        openUnsavedChanges('');
    };

    const onGlobalClick = (xValue: number, yValue: number) => {
        if (specialtyCategorySearchHasFocus) {
            checkGlobalClick(xValue, yValue, setSpecialtyCategorySearchHasFocus,
                tagSearchRef.current?.getBoundingClientRect().left ?? 0,
                tagSearchRef.current?.getBoundingClientRect().right ?? 0,
                tagSearchRef.current?.getBoundingClientRect().top ?? 0,
                tagSearchPopoutRef.current?.getBoundingClientRect().bottom ?? 0);
        }

        if (keywordSearchHasFocus) {
            checkGlobalClick(xValue, yValue, setKeywordSearchHasFocus,
                keywordSearchRef.current?.getBoundingClientRect().left ?? 0,
                keywordSearchRef.current?.getBoundingClientRect().right ?? 0,
                keywordSearchRef.current?.getBoundingClientRect().top ?? 0,
                keywordSearchPopoutRef.current?.getBoundingClientRect().bottom ?? 0);
        }

        if (showingToolTip) {
            checkGlobalClick(xValue, yValue, setShowingToolTip,
                toolTipRef?.current?.getBoundingClientRect().left ?? 0,
                toolTipRef?.current?.getBoundingClientRect().right ?? 0,
                toolTipRef?.current?.getBoundingClientRect().top ?? 0,
                toolTipRef?.current?.getBoundingClientRect().bottom ?? 0);
        }
    };

    const checkGlobalClick = (xValue: number, yValue: number, clickOutsideFunction: (value: boolean) => void,
        leftX: number, rightX: number, topY: number, bottomY: number) => {

        const isWithinArea = xValue >= leftX && xValue <= rightX && yValue >= topY && yValue <= bottomY;

        if (!isWithinArea) {
            clickOutsideFunction(false);
        }
    };

    return (
        <Fragment>
            {managingTagType != undefined &&
            <ManageTagsModal skillKeyId={skill.skillKeyId} customCategories={customCategories} customSpecialties={customSpecialties}
                skillCategories={currentSkillCategories} skillSpecialties={currentSkillSpecialties}></ManageTagsModal>
            }
            <div className="u-els-margin-top-1x u-els-padding-left-2x u-els-padding-right-2x scroll-container o-els-flex-layout__item--grow"
                data-testid='general_page' onClick={e => onGlobalClick(e.clientX, e.clientY)}>
                <span className="u-els-font-size-intro">General</span>
                <CustomSkillCard></CustomSkillCard>
                <div>
                    <label className="c-els-field__label">
                        <span className="c-els-field__label-text u-els-font-size-body-large">Skill name:</span>
                        <input className="c-els-field__input" autoComplete='off' data-testid='skill-name-input'
                            value={currentSkillName} onChange={e => onChangeSkillName(e.currentTarget.value)}></input>
                        <TextFieldInputError text={currentSkillName} hasBeenTouched={true}
                            isLengthError={isSkillNameLengthError} setIsLengthError={setIsSkillNameLengthError}
                            isZeroLengthError={isSkillNameZeroError} setIsTextZeroError={setIsSkillNameZeroError}
                            isWhiteSpaceError={isSkillNameWhiteSpaceError} setIsWhiteSpaceError={setIsSkillNameWhiteSpaceError}
                            isRegexError={isSkillNameRegexError} setIsRegexError={setIsSkillNameRegexError} characterRegex={RegexPatterns.skillNameRegex}
                            customError={duplicateSkillNameError} lengthLimit={skillNameLengthLimit} fieldName='skill name'></TextFieldInputError>
                    </label>
                </div>
                <div className='u-els-margin-top-1o2'>
                    <label className="c-els-field__label">
                        <span className="c-els-field__label-text u-els-font-size-body-large">Skill summary:</span>
                        <input className="c-els-field__input" autoComplete='off' data-testid='skill-summary-input'
                            value={currentSkillSummary} onChange={e => onChangeSkillSummary(e.currentTarget.value)}></input>
                        <TextFieldInputError text={currentSkillSummary} hasBeenTouched={true}
                            isLengthError={isSkillSummaryLengthError} setIsLengthError={setIsSkillSummaryLengthError}
                            isRegexError={isSkillSummaryRegexError} setIsRegexError={setIsSkillSummaryRegexError} characterRegex={RegexPatterns.skillSummaryRegex}
                            lengthLimit={skillSummaryLengthLimit} fieldName='skill summary'></TextFieldInputError>
                    </label>
                </div>
                <div className="u-els-display-flex c-els-field__label-text u-els-font-size-body-large u-els-margin-top-1o2">
                    <div className='u-els-margin-right-1o2'>Active:</div>
                    <InfoTooltipIcon tooltipText={activeTooltipMessage}></InfoTooltipIcon>
                </div>
                <div className="u-els-margin-top-1o2">Active skills are available for users to view.</div>
                <fieldset className="u-els-display-flex u-els-margin-top" id="correct-answer">
                    <div className="c-els-field c-els-field--radio">
                        <label className="c-els-field__label u-els-margin-right-2x">
                            <input type="radio" id="active-yes" className="c-els-field__input" name="active" data-testid='active-yes'
                                checked={currentSkillIsActive} onChange={() => onChangeIsActive(true)} />
                            <span className="c-els-field__label-text">
                                <span className="c-els-field__switch"></span>Yes
                            </span>
                        </label>
                    </div>
                    <div className="c-els-field c-els-field--radio">
                        <label className="c-els-field__label u-els-margin-right-2x">
                            <input type="radio" id="active-no" className="c-els-field__input" name="active" data-testid='active-no'
                                checked={!currentSkillIsActive} onChange={() => onChangeIsActive(false)} />
                            <span className="c-els-field__label-text">
                                <span className="c-els-field__switch"></span>No
                            </span>
                        </label>
                    </div>
                </fieldset>
                <div className="c-els-field__label-text u-els-font-size-body-large u-els-margin-top-2x">Include alert:</div>
                <div className="u-els-margin-top-1o2">Alerts will be included above the text content for the skills.</div>
                <fieldset className="u-els-display-flex u-els-margin-top" id="correct-answer">
                    <div className="c-els-field c-els-field--radio">
                        <label className="c-els-field__label u-els-margin-right-2x">
                            <input type="radio" id="alert-yes" className="c-els-field__input" name="alert" data-testid='alert-yes'
                                checked={currentSkillIsUseAlert} onChange={() => onChangeIsUseAlert(true)} />
                            <span className="c-els-field__label-text">
                                <span className="c-els-field__switch"></span>Yes
                            </span>
                        </label>
                    </div>
                    <div className="c-els-field c-els-field--radio">
                        <label className="c-els-field__label u-els-margin-right-2x">
                            <input type="radio" id="alert-no" className="c-els-field__input" name="alert" data-testid='alert-no'
                                checked={!currentSkillIsUseAlert} onChange={() => onChangeIsUseAlert(false)} />
                            <span className="c-els-field__label-text">
                                <span className="c-els-field__switch"></span>No
                            </span>
                        </label>
                    </div>
                </fieldset>
                <div className='u-els-margin-top-2x'>
                    <label className="c-els-field__label">
                        <span className="c-els-field__label-text u-els-font-size-body-large">Alert:</span>
                        <input className="c-els-field__input" disabled={!currentSkillIsUseAlert} autoComplete='off' data-testid='alert-input'
                            value={currentSkillAlertText} onChange={e => onChangeSkillAlertText(e.currentTarget.value)}></input>
                        <TextFieldInputError text={currentSkillAlertText} hasBeenTouched={currentSkillIsUseAlert}
                            isLengthError={showSkillAlertLengthError()} setIsLengthError={setIsAlertTextLengthError}
                            isRegexError={isAlertTextRegexError} setIsRegexError={setIsAlertTextRegexError} characterRegex={RegexPatterns.alertTextRegex}
                            useZeroLengthWarning={currentSkillIsUseAlert}
                            lengthLimit={skillAlertTextLimit} fieldName='alert text'></TextFieldInputError>
                    </label>
                </div>
                <CategoriesSpecialtiesEditor tagSearchRef={tagSearchRef} tagSearchPopoutRef={tagSearchPopoutRef}
                    currentSkillCategories={currentSkillCategories} currentSkillSpecialties={currentSkillSpecialties}
                    customCategories={customCategories} customSpecialties={customSpecialties}
                    setCurrentSkillCategories={setCurrentSkillCategories} setCurrentSkillSpecialties={setCurrentSkillSpecialties}
                    specialtyCategorySearchHasFocus={specialtyCategorySearchHasFocus}
                    setSpecialtyCategorySearchHasFocus={setSpecialtyCategorySearchHasFocus}
                    showingToolTip={showingToolTip} setShowingToolTip={setShowingToolTip} toolTipRef={toolTipRef}></CategoriesSpecialtiesEditor>
                {canEditKeywords() &&
                <KeywordsEditor keywordSearchRef={keywordSearchRef} keywordSearchPopoutRef={keywordSearchPopoutRef}
                    currentSkillKeywords={currentSkillKeywords} setCurrentSkillKeywords={setCurrentSkillKeywords}
                    keywordSearchHasFocus={keywordSearchHasFocus} setKeywordSearchHasFocus={setKeywordSearchHasFocus}></KeywordsEditor>
                }
                <div className="u-els-margin-top-4x">
                    <VersionCommentField commentText={commentText} setCommentText={setCommentText} setIsCommentError={setIsCommentError}
                        commentCharacterLimit={commentCharacterLimit}></VersionCommentField>
                </div>
                <div className="u-els-margin-top-1x1o2 u-els-margin-bottom">
                    <button className="c-els-button" onClick={onSaveClicked} disabled={isSaveDisabled()}>Save</button>
                    <button className="c-els-button c-els-button--secondary u-els-margin-left" onClick={onCancelClicked} disabled={isCancelDisabled()}>Cancel</button>
                </div>
            </div>
        </Fragment>
    );

};

export default SkillGeneralPage;