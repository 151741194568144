import { UploadStatus } from '../Context/Skill/SkillState';
import { Subtitle } from './Subtitle';

export enum VideoType {
    custom = 0,
    animation = 1,
    videos = 2,
    twoPointFiveDeeAnimation = 4,
    threeDeeAnimation = 5,
    perryPotter = 6,
}

export interface Video {
    key: string,
    animationId: string,
    skillsMediaId: number,
    animationName: string,
    videoUrl: string,
    skillKeyId: string,
    mediaRefId: string,
    mediaType: VideoType,
    isCustom: boolean,
    fileName: string,
    isActive: boolean,
    itemOrder: number,
    commentText: string,
    closedCaptionsUrl: string,
    subtitles?: Subtitle[],
    sourceSkillId: string,
    uploadStatus?: UploadStatus,
}