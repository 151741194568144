import React, { useState, useContext, createRef, KeyboardEvent } from 'react';
import SkillContext from '../../../../Context/Skill/SkillContext';
import CloseModalIcon from '../../Icons/CloseModalIcon';
import TextFieldInputError from '../../TextFieldInputError/TextFieldInputError';
import { RegexPatterns } from '../../../../Constants/Constants';

const VersionCommentModal = () => {
    const { closeVersionComment, saveSkillMedia } = useContext(SkillContext);

    const [ isCommentLengthError, setIsCommentLengthError ] = useState(false);
    const [ isCommentZeroError, setIsCommentZeroError ] = useState(false);
    const [ isCommentRegexError, setIsCommentRegexError ] = useState(false);

    const [ versionCommentText, setVersionCommentText ] = useState('');

    const versionCommentCharacterLimit = 256;

    const messageText = 'The skill you are updating may be assigned to Learners.  By updating the selected skill, you will impact the assignments of those Learners.';

    const closeButtonRef = createRef<HTMLButtonElement>();
    const cancelButtonRef = createRef<HTMLButtonElement>();
    const saveButtonRef = createRef<HTMLButtonElement>();

    const isCommentError = () => {
        return isCommentLengthError || isCommentZeroError || isCommentRegexError;
    };

    const onClickClose = () => {
        closeVersionComment();
    };

    const onChangeComment = (text: string) => {
        setVersionCommentText(text);
    };

    const onClickSave = () => {
        saveSkillMedia(versionCommentText);
        closeVersionComment();
    };

    const onTabLastElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            closeButtonRef.current?.focus();
        }
    };

    const onShiftTabFirstElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            isCommentError() ? cancelButtonRef.current?.focus() : saveButtonRef.current?.focus();
        }
    };

    return (
        <div className='c-els-modal u-els-padding-2x u-app-z-index--modal'>
            <div className='c-els-modal__window o-els-flex-layout__item--grow u-els-padding-2x'>
                <button className='c-els-modal__close u-els-anchorize' onClick={onClickClose} onKeyDown={event => onShiftTabFirstElement(event)}
                    ref={closeButtonRef} data-testid='close_x'>
                    <CloseModalIcon />
                </button>
                <h3 className='u-els-margin-bottom'>Version comment</h3>
                <div className='u-els-margin-bottom u-els-bold'>{messageText}</div>
                <div className="c-els-field--large u-els-margin-bottom">
                    <label className="c-els-field__label">
                        <span className="c-els-field__label-text">Version comment text</span>
                        <textarea className="c-els-field__input" name="input-type-text" aria-invalid="true" aria-describedby="versionComment"
                            defaultValue={versionCommentText} onChange={event => onChangeComment(event.currentTarget.value)}
                            spellCheck={false} autoFocus={true} />
                    </label>
                    <TextFieldInputError text={versionCommentText} hasBeenTouched={true} characterRegex={RegexPatterns.versionCommentRegex}
                        isLengthError={isCommentLengthError} setIsLengthError={setIsCommentLengthError}
                        isZeroLengthError={isCommentZeroError} setIsTextZeroError={setIsCommentZeroError}
                        isRegexError={isCommentRegexError} setIsRegexError={setIsCommentRegexError}
                        lengthLimit={versionCommentCharacterLimit} fieldName='version comment'></TextFieldInputError>
                </div>
                <div className="o-els-flex-layout o-els-flex--space-between">
                    <div className='o-els-flex-layout__item--grow'></div>
                    <div className="o-els-flex-layout__item--right u-els-nowrap">
                        <button className="c-els-button" onClick={onClickClose}
                            onKeyDown={event => isCommentError() && onTabLastElement(event)} ref={cancelButtonRef}>Cancel</button>
                        <button className="c-els-button u-els-margin-left-1o2" disabled={isCommentError()} onClick={onClickSave}
                            onKeyDown={event => onTabLastElement(event)} ref={saveButtonRef}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VersionCommentModal;