import React, { useContext, useEffect, KeyboardEvent, createRef, Fragment, MouseEvent } from 'react';
import SkillContext from '../../../Context/Skill/SkillContext';
import { UploadStatus } from '../../../Context/Skill/SkillState';
import { MediaViewModel } from '../../../Models/MediaViewModel';
import CheckmarkIcon from '../Icons/CheckmarkIcon';
import ClockIcon from '../Icons/ClockIcon';
import ErrorIcon from '../Icons/ErrorIcon';
import InfoTooltipIcon from '../InfoTooltipIcon/InfoTooltipIcon';

import './MediaList.scss';
import { Subtitle } from '../../../Models/Subtitle';

type ImageListProps = {
    mediaFiles: MediaViewModel[],
}

const MediaList = ({mediaFiles: files}: ImageListProps) => {
    const { changeSelection, toggleActive, openInfoEdit, moveItem, openVideoPreview, skill, currentSelection } = useContext(SkillContext);

    const bottomElement = createRef<HTMLDivElement>();

    const mediaSourceTooltipText = 'Files added by Elsevier cannot be edited or deleted, only de-activated. Custom files uploaded by the user may be edited or deleted.';

    const getFileNameDisplayText = (file: MediaViewModel) => {
        return file.uploadStatus === UploadStatus.inProgress ? 'Uploading...' : file.fileName;
    };

    const getMediaSourceText = (file: MediaViewModel) => {
        return file.isCustom ? 'Custom' : 'Elsevier';
    };

    const onCheckboxClicked = (file: MediaViewModel) => {
        toggleActive(file.mediaType, file.key);
    };
    
    const onFileRowClicked = (file: MediaViewModel) => {
        changeSelection(file);
    };

    const onFocuedRowKeyDown = (event: KeyboardEvent, file: MediaViewModel) => {
        if (event.key === 'Enter') {
            changeSelection(file);
            file.isCustom && openInfoEdit();
        }
    };

    const displayUpArrow = (file: MediaViewModel) => {
        return (files.length === 1 || files.indexOf(file) === 0);
    };

    const displayDownArrow = (file: MediaViewModel) => {
        return (files.length === 1 || files.indexOf(file) === files.length-1);
    };

    const onClickUpArrow = (file: MediaViewModel) => {
        moveItem(file.key, file.mediaType, -1);
    };

    const onClickDownArrow = (file: MediaViewModel) => {
        moveItem(file.key, file.mediaType, 1);
    };

    const onPreviewAnimationClick = (filePath: string, event: MouseEvent, captionsPath?: string, subtitles?: Subtitle[], videoId?: string, videoName?: string) => {
        event.preventDefault();
        openVideoPreview(filePath, captionsPath, subtitles, videoId, videoName);
    };

    useEffect(() => {
        const selection = files.find(file => file.key === currentSelection?.key);
        selection && changeSelection(selection);
    }, [skill]);

    useEffect(() => {
        files.find(file => file.uploadStatus === UploadStatus.inProgress) && bottomElement.current?.scrollIntoView({ behavior: 'smooth' });
    }, [files.length]);

    return (
        <Fragment>
            <table className='c-els-table u-els-padding-left-2x u-els-padding-right-2x'>
                <thead className="c-els-table__head">
                    <tr className='c-els-table__row c-els-table__row--head'>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Active
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Status
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            File name
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-els-nowrap u-els-padding-right-none media-list__provider-header u-app-z-index--2'>
                            <div className='u-els-padding-right-1o4'>Media provider</div>
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-els-padding-left-none u-app-z-index--2'>
                            <InfoTooltipIcon tooltipText={mediaSourceTooltipText} />
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Link
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Description
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Comment text
                        </th>
                        <th scope='col' className='c-els-table__cell c-els-table__cell--header u-app-z-index--2'>
                            Order
                        </th>
                    </tr>
                </thead>
                <tbody className='c-els-table__body'>
                    {files.map(file => (
                        <tr className={`c-els-table__row ${file.key === currentSelection?.key ? 'c-els-table__row--active' : ''}`}
                            key={file.key} onClick={() => onFileRowClicked(file)} tabIndex={0} onKeyDown={event => onFocuedRowKeyDown(event, file)}>
                            <td className='c-els-table__cell'>
                                <div className="c-els-field c-els-field--checkbox">
                                    <label className="c-els-field__label">
                                        <input className="c-els-field__input u-app-z-index--2"
                                            name="input-type-checkbox" type="checkbox" aria-label='Make file active'
                                            checked={file.isActive} data-testid={file.key + '_check'}
                                            onChange={() => onCheckboxClicked(file)} />
                                        <span className="c-els-field__label-text">
                                            <span className="c-els-field__switch"></span>
                                        </span>
                                    </label>
                                </div>
                            </td>
                            <td className='c-els-table__cell'>
                                { file.uploadStatus === UploadStatus.successful && <CheckmarkIcon />}
                                { file.uploadStatus === UploadStatus.inProgress && <ClockIcon />}
                                { file.uploadStatus === UploadStatus.failed && <ErrorIcon />}
                            </td>
                            <td className='c-els-table__cell media-list__filename-cell'>{getFileNameDisplayText(file)}</td>
                            <td className='c-els-table__cell'>{getMediaSourceText(file)}</td>
                            <td className='c-els-table__cell'></td>
                            <td className='c-els-table__cell'>
                                { !file.canPreviewInUploader && <a href={file.previewUrl} target='_blank' rel='noreferrer'>Preview</a>}
                                { file.canPreviewInUploader && <a href='#' onClick={(event) => onPreviewAnimationClick(file.previewUrl, event, file.closedCaptionsUrl, file.subtitles, file.animationId, file.description)}>Preview</a> }
                            </td>
                            <td className='c-els-table__cell media-list__description-cell'>{file.description}</td>
                            <td className='c-els-table__cell media-list__comment-cell'>{file.commentText}</td>
                            <td className='c-els-table__cell'>
                                <button className="c-els-button c-els-button--x-small u-els-display-block c-els-button--secondary u-els-margin-bottom-1o4" disabled={displayUpArrow(file)}
                                    data-testid={file.key + '_up'} aria-label='Move file up' onClick={() => onClickUpArrow(file)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" height="14" width="14"><path d="M65 10.6L12.6 63l7.2 7L60 29.8V114h10V29.8L110.2 70l7.2-7z"/></svg>
                                </button>
                                <button className="c-els-button c-els-button--x-small u-els-display-block c-els-button--secondary" disabled={displayDownArrow(file)}
                                    data-testid={file.key + '_down'} aria-label='Move file down' onClick={() => onClickDownArrow(file)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" height="14" width="14"><path d="M60 14v84.2L19.8 58l-7.2 7L65 117.4 117.4 65l-7.2-7L70 98.2V14z"/></svg>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div ref={bottomElement}></div>
        </Fragment>
    );
};

export default MediaList;
