import React, { createRef, KeyboardEvent, useState } from 'react';
import { ChecklistItem } from '../../../../Models/ChecklistItem';
import ChecklistItemForm from '../../ChecklistItemForm/ChecklistItemForm';
import CloseModalIcon from '../../Icons/CloseModalIcon';

import './EditChecklistItemModal.scss';

type EditChecklistItemProps = {
    checklistItem: ChecklistItem,
    setChecklistItem: (checklistItem: ChecklistItem) => void,
    setShowModal: (showModal: boolean) => void,
    saveChecklistItem: (checklistItem: ChecklistItem, isEdit: boolean) => void,
}

const EditChecklistItemModal = ({ checklistItem, setChecklistItem, setShowModal, saveChecklistItem }: EditChecklistItemProps) => {

    const [ checklistItemTextHasBeenTouched, setChecklistItemTextHasBeenTouched ] = useState(false);
    const [ isError, setIsError ] = useState(false);
    
    const closeXRef = createRef<HTMLButtonElement>();
    const closeButtonRef = createRef<HTMLButtonElement>();
    
    const onCloseClicked = () => {
        setShowModal(false);
    };

    const onSaveClicked = () => {
        saveChecklistItem(checklistItem, true);
        setShowModal(false);
    };

    const isSaveDisabled = () => {
        return isError;
    };

    const onTabLastElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            closeXRef.current?.focus();
        }
    };

    const onShiftTabFirstElement = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            closeButtonRef.current?.focus();
        }
    };

    return (
        <div className='c-els-modal c-els-modal--primary u-els-padding-2x u-app-z-index--modal'>
            <div className='c-els-modal__window u-els-width-1o1 u-els-padding-2x u-els-display-flex o-els-flex-layout--column modal-window'>
                <div className='u-els-margin-bottom u-els-padding-top u-els-padding-right u-els-padding-left'>
                    <button className='c-els-modal__close u-els-anchorize' onClick={onCloseClicked} onKeyDown={event => onShiftTabFirstElement(event)}
                        ref={closeXRef} data-testid='close_x'>
                        <CloseModalIcon />
                    </button>
                    <div className="u-els-font-size-intro">Edit checklist item</div>
                </div>
                <div className='u-els-padding-left u-els-padding-right modal-scroll-container'>
                    <ChecklistItemForm checklistItem={checklistItem} setChecklistItem={setChecklistItem}
                        setIsError={setIsError} checklistItemTextHasBeenTouched={checklistItemTextHasBeenTouched} autoFocus={true}
                        setChecklistItemTextHasBeenTouched={setChecklistItemTextHasBeenTouched}></ChecklistItemForm>
                </div>
                <div className='u-els-margin-top-1x u-els-padding-bottom u-els-padding-left u-els-padding-right'>
                    <button className='c-els-button u-els-margin-right' onClick={onSaveClicked} disabled={isSaveDisabled()}>Save checklist item</button>
                    <button className='c-els-button c-els-button--secondary' onClick={onCloseClicked} ref={closeButtonRef}
                        onKeyDown={event => onTabLastElement(event)}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default EditChecklistItemModal;