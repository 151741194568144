import { createAction, createReducer } from '@reduxjs/toolkit';
import { Illustration } from '../../Models/Illustration';
import { MediaType, MediaViewModel } from '../../Models/MediaViewModel';
import { Skill } from '../../Models/Skill';
import { ChecklistItem } from '../../Models/ChecklistItem';
import { Video, VideoType } from '../../Models/Video';
import { PreviewState, UploadStatus } from './SkillState';

const applyByKey = <T extends { key: string }>(items: T[], key: string, customFunction: (item: T) => T) => {
    return items.map(item => {
        if (item.key !== key) {
            return item;
        }
        return customFunction(item);
    });
};

export enum ActionType {
    getSkill = 'GET_SKILL',
    getSkillNames = 'GET_SKILL_NAMES',
    addIllustration = 'ADD_ILLUSTRATION',
    editIllustration = 'EDIT_ILLUSTRATION',
    addVideo = 'ADD_VIDEO',
    deleteIllustration = 'DELETE_ILLUSTRATION',
    editVideo = 'EDIT_VIDEO',
    deleteVideo = 'DELETE_VIDEO',
    changeSelection = 'CHANGE_SELECTION',
    clearSelection = 'CLEAR_SELECTION',
    setInfoEdit = 'SET_INFO_EDIT',
    setVersionComment = 'SET_VERSION_COMMENT',
    setUnsavedChanges = 'SET_UNSAVED_CHANGES',
    setConfirmModal = 'SET_CONFIRM_MODAL',
    setVideoPreview = 'SET_VIDEO_PREVIEW',
    setLoading = 'SET_LOADING',
    setSaving = 'SET_SAVING',
    succeedIllustrationUpload = 'SUCCEED_ILLUSTRATION_UPLOAD',
    succeedVideoUpload = 'SUCCEED_VIDEO_UPLOAD',
    failIllustrationUpload = 'FAIL_ILLUSTRATION_UPLOAD',
    failVideoUpload = 'FAIL_VIDEO_UPLOAD',
    skillError = 'SKILL_ERROR',
    moveItem = 'MOVE_ITEM',
    setHasBeenModified = 'SET_HAS_BEEN_MODIFIED',
}

export const getSkillAction = createAction<Skill>(ActionType.getSkill);
export const getSkillNamesAction = createAction<string[]>(ActionType.getSkillNames);
export const addIllustrationAction = createAction<Illustration>(ActionType.addIllustration);
export const editIllustrationAction = createAction<Illustration>(ActionType.editIllustration);
export const deleteIllustrationAction = createAction<string>(ActionType.deleteIllustration);
export const addVideoAction = createAction<Video>(ActionType.addVideo);
export const editVideoAction = createAction<Video>(ActionType.editVideo);
export const deleteVideoAction = createAction<string>(ActionType.deleteVideo);
export const changeSelectionAction = createAction<MediaViewModel>(ActionType.changeSelection);
export const clearSelectionAction = createAction(ActionType.clearSelection);
export const setInfoEditAction = createAction<boolean>(ActionType.setInfoEdit);
export const setVersionCommentAction = createAction<boolean>(ActionType.setVersionComment);
export const setUnsavedChangesAction = createAction<{unsavedChangesIsOpen: boolean, unsavedChangesRoute: string}>(ActionType.setUnsavedChanges);
export const setVideoPreviewAction = createAction<PreviewState>(ActionType.setVideoPreview);
export const setLoadingAction = createAction(ActionType.setLoading);
export const setSavingAction = createAction<boolean>(ActionType.setSaving);
export const succeedIllustrationUploadAction = createAction<{ key: string, signedImageUrl: string, fileName: string }>(ActionType.succeedIllustrationUpload);
export const succeedVideoUploadAction = createAction<{ key: string, videoUrl: string, fileName: string }>(ActionType.succeedVideoUpload);
export const failIllustrationUploadAction = createAction<string>(ActionType.failIllustrationUpload);
export const failVideoUploadAction = createAction<string>(ActionType.failVideoUpload);
export const skillErrorAction = createAction(ActionType.skillError);
export const moveItemAction = createAction<{ key: string, itemType: MediaType, direction: number }>(ActionType.moveItem);
export const setHasBeenModifiedAction = createAction<boolean>(ActionType.setHasBeenModified);

export type SkillReducerState = {
    skill: Skill,
    allSkillNames: string[],
    currentSelection?: MediaViewModel,
    currentChecklistItem?: ChecklistItem,
    hasBeenModified: boolean,
    mediaTypeModified?: MediaType,
    infoEditIsOpen: boolean,
    confirmModalIsOpen: boolean,
    versionCommentIsOpen: boolean,
    unsavedChangesIsOpen: boolean,
    unsavedChangesRoute: string,
    loading: boolean,
    saving: boolean,
    uploading: boolean,
    versionComment: string,
    previewState: PreviewState,
    confirmModalHeading: string,
    confirmModalMessage: string,
    confirmModalFunction: (...args: any[]) => void,
}

const isUploading = (skill: Skill, key: string) => {
    return !!(skill.illustrations.find(i => i.uploadStatus === UploadStatus.inProgress && i.key !== key)
        || skill.videos.find(v => v.uploadStatus === UploadStatus.inProgress && v.key !== key));
};

const SkillReducer = createReducer({} as SkillReducerState, (builder) => {
    builder
        .addCase(getSkillAction, (state, { payload }) => {
            return { ...state, skill: payload, loading: false, hasBeenModified: false, mediaTypeModified: undefined };
        })
        .addCase(getSkillNamesAction, (state, { payload }) => {
            return { ...state, allSkillNames: payload };
        })
        .addCase(addIllustrationAction, (state, { payload }) => {
            const { skill } = state;
            return { ...state, hasBeenModified: true, mediaTypeModified: MediaType.illustrations, skill: { ...skill, illustrations: skill.illustrations.concat(payload) }, uploading: true };
        })
        .addCase(editIllustrationAction, (state, { payload }) => {
            const { skill } = state;
            return {
                ...state, hasBeenModified: true, mediaTypeModified: MediaType.illustrations, skill: {
                    ...skill,
                    illustrations: applyByKey(skill.illustrations, payload.key, () => payload)
                }
            };
        })
        .addCase(deleteIllustrationAction, (state, { payload }) => {
            const { skill } = state;
            return {
                ...state,
                hasBeenModified: true,
                mediaTypeModified: MediaType.illustrations,
                skill: {
                    ...skill,
                    illustrations: skill.illustrations.filter(illustration => illustration.key !== payload)
                },
                currentSelection: undefined,
            };
        })
        .addCase(addVideoAction, (state, { payload }) => {
            const { skill } = state;
            return { ...state, hasBeenModified: true, mediaTypeModified: MediaType.videos, skill: { ...skill, videos: skill.videos.concat(payload) }, uploading: true };
        })
        .addCase(editVideoAction, (state, { payload }) => {
            const { skill } = state;
            return {
                ...state, hasBeenModified: true, mediaTypeModified: MediaType.videos, skill: {
                    ...skill,
                    videos: applyByKey(skill.videos, payload.key, () => payload) }, currentSelection: undefined };
        })
        .addCase(deleteVideoAction, (state, { payload }) => {
            const { skill } = state;
            return {
                ...state,
                hasBeenModified: true,
                mediaTypeModified: MediaType.videos,
                skill: { ...skill, videos: skill.videos.filter(video => video.key !== payload) },
                currentSelection: undefined,
            };
        })
        .addCase(changeSelectionAction, (state, { payload }) => {
            return { ...state, currentSelection: payload };
        })
        .addCase(clearSelectionAction, (state) => {
            return { ...state, currentSelection: undefined };
        })
        .addCase(setInfoEditAction, (state, { payload }) => {
            return { ...state, infoEditIsOpen: payload };
        })
        .addCase(setVersionCommentAction, (state, { payload }) => {
            return { ...state, versionCommentIsOpen: payload };
        })
        .addCase(setUnsavedChangesAction, (state, { payload }) => {
            return { ...state, unsavedChangesIsOpen: payload.unsavedChangesIsOpen, unsavedChangesRoute: payload.unsavedChangesRoute };
        })
        .addCase(setVideoPreviewAction, (state, { payload }) => {
            return { ...state, previewState: payload };
        })
        .addCase(skillErrorAction, (state) => {
            return { ...state, loading: false };
        })
        .addCase(setLoadingAction, (state) => {
            return { ...state, loading: true };
        })
        .addCase(setSavingAction, (state, { payload }) => {
            return { ...state, saving: payload };
        })
        .addCase(succeedIllustrationUploadAction, (state, { payload }) => {
            const { skill } = state;
            return {
                ...state,
                skill: {
                    ...skill, illustrations: applyByKey(skill.illustrations, payload.key, i => {
                        return {
                            ...i,
                            imageFileName: payload.fileName,
                            largeImageFileName: payload.fileName,
                            signedImageUrl: payload.signedImageUrl,
                            uploadStatus: UploadStatus.successful,
                        };
                    })
                },
                uploading: isUploading(skill, payload.key)
            };
        })
        .addCase(succeedVideoUploadAction, (state, { payload }) => {
            const { skill } = state;
            return {
                ...state,
                skill: {
                    ...skill, videos: applyByKey(skill.videos, payload.key, v => {
                        return {
                            ...v,
                            uploadStatus: UploadStatus.successful,
                            videoUrl: payload.videoUrl,
                            fileName: payload.fileName,
                            mediaType: VideoType.custom,
                        };
                    })
                },
                uploading: isUploading(skill, payload.key)
            };
        })
        .addCase(failIllustrationUploadAction, (state, { payload }) => {
            const { skill } = state;
            return {
                ...state,
                skill: {
                    ...skill, illustrations: applyByKey(skill.illustrations, payload, i => {
                        return {
                            ...i,
                            uploadStatus: UploadStatus.failed,
                        };
                    })
                },
                uploading: isUploading(skill, payload)
            };
        })
        .addCase(failVideoUploadAction, (state, { payload }) => {
            const { skill } = state;
            return {
                ...state,
                skill: {
                    ...skill, videos: applyByKey(skill.videos, payload, v => {
                        return {
                            ...v,
                            uploadStatus: UploadStatus.failed,
                        };
                    })
                },
                uploading: isUploading(skill, payload)
            };
        })
        .addCase(moveItemAction, (state, { payload: { key, itemType, direction } }) => {
            const { skill } = state;
            const selectedIndex = skill[itemType].findIndex((x: Illustration | Video) => x.key == key) ?? 0;
            const newList = [...skill[itemType]];
            newList[selectedIndex + direction] = skill[itemType][selectedIndex];
            newList[selectedIndex] = skill[itemType][selectedIndex + direction];
            const newSkill = { ...skill, [itemType]: newList };
            return {
                ...state,
                hasBeenModified: true,
                mediaTypeModified: itemType,
                skill: newSkill
            };
        })
        .addCase(setHasBeenModifiedAction, (state, { payload }) => {
            return {
                ...state,
                hasBeenModified: payload,
            };
        });
});

export default SkillReducer;