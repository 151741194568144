import React, { DragEvent, Fragment, useContext, useEffect } from 'react';
import SkillContext from '../../../Context/Skill/SkillContext';
import MediaList from '../../Layout/MediaList/MediaList';
import { MediaType, MediaViewModel } from '../../../Models/MediaViewModel';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import UploaderFooter from '../../Layout/UploaderFooter/UploaderFooter';
import { UploadStatus } from '../../../Context/Skill/SkillState';

const SkillIllustrationsPage = () => {
    const { skill, clearSelection, uploadFiles } = useContext(SkillContext);

    const allowedIllustrationExtensions = ['.png', '.jpg'];

    const onDropHandler = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer?.files && uploadFiles(Array.from(event.dataTransfer.files), MediaType.illustrations, allowedIllustrationExtensions);
    };
    
    const onDragOverHandler = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    useEffect(() => {
        clearSelection();
    }, []);

    if(skill) {
        const viewModels = skill.illustrations?.map(illustration => {
            return {
                key: illustration.key,
                isActive: illustration.active,
                commentText: illustration.commentText,
                description: illustration.imageDescription,
                fileName: illustration.imageFileName,
                itemOrder: illustration.itemOrder,
                previewUrl: illustration.signedImageUrl,
                mediaType: MediaType.illustrations,
                uploadStatus: illustration.uploadStatus ?? UploadStatus.successful,
                isCustom: illustration.isCustom || illustration.isCustomLargeImage,
                canPreviewInUploader: false,
            } as MediaViewModel;
        });
        
        return (
            <Fragment>
                <div className="scroll-container o-els-flex-layout__item--grow u-els-margin-top-1x"
                    data-testid='illustrations_page'
                    onDrop={onDropHandler}
                    onDragOver={onDragOverHandler}>
                    <MediaList mediaFiles={viewModels}></MediaList>
                </div>
                <UploaderFooter allowedExtensions={allowedIllustrationExtensions} mediaType={MediaType.illustrations} />
            </Fragment>
        );
    }

    return (
        <ErrorPage />
    );
    
};

export default SkillIllustrationsPage;
