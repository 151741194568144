import React, { useEffect } from 'react';

export const Logout = () => {

    useEffect(() => {
        window.close();
    }, []);

    return (
        <div>
            Bye!
        </div>
    );
};
