import React from 'react';
import { Colors } from '../Colors';

const ClockIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 128" height="24" width="19" fill={Colors.hmdsinfo} data-testid='clock_icon'><path d="M55 36H45v32.75l25.39 15.52 5.22-8.54L55 63.14V36m22.85 56.85c-7.44 7.44-17.33 11.54-27.85 11.54-21.72 0-39.39-17.67-39.39-39.39S28.28 25.61 50 25.61 89.39 43.28 89.39 65c0 10.52-4.1 20.41-11.54 27.85zM49.99 16C22.98 16 1 37.98 1 65s21.98 49 49 49c13.09 0 25.39-5.1 34.65-14.35C93.9 90.39 99 78.09 99 65c0-27.02-21.98-49-49.01-49"/>
            <title>Uploading...</title>
        </svg>
    );
};

export default ClockIcon;