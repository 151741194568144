import { createAction, createReducer } from '@reduxjs/toolkit';
import { ButtonType } from '../Skill/SkillState';
import { ToastMessage } from './AlertState';

export enum ActionType {
    setAlert = 'SET_ALERT',
    clearAlert = 'CLEAR_ALERT',
    addToastMessage = 'ADD_TOAST_MESSAGE',
    clearToastMessage = 'CLEAR_TOAST_MESSAGE',
}

export const setAlertAction = createAction<{message: string, buttonType: ButtonType}>(ActionType.setAlert);
export const clearAlertAction = createAction(ActionType.clearAlert);
export const addToastMessageAction = createAction<ToastMessage>(ActionType.addToastMessage);
export const clearToastMessageAction = createAction<number>(ActionType.clearToastMessage);

export type AlertReducerState = {
    alertMessage?: string,
    buttonType?: ButtonType,
    toastMessages: ToastMessage[],
    nextToastId: number,
}

const AlertReducer = createReducer({} as AlertReducerState, (builder) => {
    builder
        .addCase(setAlertAction, (state, {payload: {message, buttonType}}) => {
            return { ...state, alertMessage: message, buttonType: buttonType };
        })
        .addCase(clearAlertAction, (state) => {
            return { ...state, alertMessage: undefined };
        })
        .addCase(addToastMessageAction, (state, {payload: toastMessage}) => {
            return { ...state, toastMessages: [ ...state.toastMessages, { ...toastMessage, uniqueId: state.nextToastId } ], nextToastId: state.nextToastId + 1, };
        })
        .addCase(clearToastMessageAction, (state, {payload: removeIndex}) => {
            return { ...state, toastMessages: state.toastMessages.filter((toast, index) => index != removeIndex) };
        });
});

export default AlertReducer;